.view_poster{
	&.similar_poster{
		padding-top: 20px;
		border-top: 1px solid #eaeaea;
		.block_title{
			font-size: 24px;
			margin-bottom: 22px;
		}
		.view_content{
			margin-right: -26px;
			.col{
				width: 25%;
				padding-right: 26px;
			}
			.date{
				border: none;
				font-size: 11px;
				color: $color_grey;
				text-transform: uppercase;
				padding: 0;
				margin-bottom: 8px;
			}
		}
	}
	.view_content{
		margin-right: -22px;
		font-size: 0;
	}
	.col{
		width: 20%;
		display: inline-block;
		font-size: 14px;
		vertical-align: top;
		padding-right: 22px;
		margin-bottom: 38px;
	}
	.image{
		margin-bottom: 8px;
		height: 218px;
		overflow: hidden;
	}
	.date{
		font-size: 11px;
		text-transform: uppercase;
		border-radius: 2px;
		border: 1px solid #bac5d6;
		padding: 0 9px;
		display: table;
		margin: 0 auto;
		padding-top: 3px;
		padding-bottom: 1px;
		margin-bottom: 5px;
	}
	.place{
		color: $color_grey;
		font-size: 12px;
		margin-bottom: 5px;
		text-align: center;
	}
	.title{
		font-size: 18px;
		text-align: center;
		a{
			text-decoration: none;
			transition: .3s;
			&:hover{
				color: $color_green2;
				text-decoration: underline;
			}
		}
	}
	.link_image{
		margin-top: -17px;
		img{
			display: block;
			margin: 0 auto;
			margin-bottom: 27px;
		}
	}
	.view_title{
		font-size: 24px;
		margin-bottom: 20px;
		text-align: center;
		margin-right: 22px;
		&.line{
			position: relative;
			&::after{
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				position: absolute;
				top: 50%;
				left: 0;
				background: #dfdfdf;
			}
			span{
				display: inline-block;
				padding: 0 12px;
				background: #fff;
				position: relative;
				z-index: 2;
			}
		}
	}
}
/*----------------------------------------------------------------------------
									NODE POSTER
----------------------------------------------------------------------------*/
.node_poster{
	.node_title{
		font-size: 24px;
		font-weight: 400;
		margin: 0 0 20px;
		line-height: 1;
		padding-top: 2px;
	}
	.node_image{
		float: left;
		margin-right: 25px;
		img{
			display: block;
			margin-bottom: 20px;
		}
	}
	.image_wrapper{
		margin-bottom: 5px;
	}
	.info_wrapper{
		overflow: hidden;
		.poster_item{
			margin-bottom: 22px;
			&.date{
				margin-bottom: 28px;
			}
			.label{
				margin-bottom: 6px;
				color: $color_grey;
			}
			.value{
				font-size: 16px;
				a{
					color: $color_green2;
				}
			}
		}
	}
	.description{
		text-align: justify;
		font-size: 16px;
		font-weight: 300;
		padding-bottom: 10px;
		margin-bottom: 50px;
	}
	.link_image{
		padding-top: 27px;
		border-top: 1px solid #eaeaea;
		margin-bottom: 27px;
		img{
			display: block;
			margin: 0 auto;
		}
	}
}
.page_node_poster{
	.vip_notice{
		padding-top: 25px;
		border-top: 1px solid #eaeaea;
		.block_content{
			font-size: 0;
			margin:0 -24px 0 0 !important;
			.col{
				margin: 0;
				padding-right: 24px;
				width: 33.3%;
			}
		}
	}
}
.page_poster{
	.top_inner {
		.block_content{
			margin-bottom: 24px;
		}
	}
}