.block_views_news_1{
	padding-bottom: 40px;
	margin-bottom: 25px;
	border-bottom: 1px solid #eaeaea;
 	.col_group_1{
 		margin: 0 -25px 25px 0;
 		font-size: 0;
 		.col{
 			position: relative;
 			display: inline-block;
 			vertical-align: top;
 			font-size: 14px;
 			width: 50%;
 			padding-right: 25px;
 		}
 		.image_wrapper{
 			position: relative;
 		}
 		.title_wrap{
 			position: absolute;
 			bottom: 0;
 			width: 100%;
 			left: 0;
 			z-index: 10;
 			color: #fff;
 			height: 155px;
 			background-image: linear-gradient(180deg, rgba(0, 37, 50, 0) 0%, rgba(1, 19, 35, 0.67) 40%, rgba(1, 18, 34, 0.75) 100%);
 			padding: 65px 25px 0;
 			a{
 				color: #fff;
 			}
 		}
 		.col_info{
 			display: table;	
 			margin: 0 auto 12px;
 			font-size: 0;
 			.category{
 				display: inline-block;
 				vertical-align: top;
 				font-size: 14px;
 				padding-right: 24px;
 				position: relative;
 				a{
 					text-decoration: none;
 					&:hover{
 						text-decoration: underline;
 					}
 				}
 				&::after{
 					content: '';
 					display: block;
 					width: 7px;
 					height: 7px;
 					border-radius: 50%;
 					background: rgba(255,255,255,.4);
 					position: absolute;
 					top: 50%;
 					margin-top: -3.5px;
 					right: 8px;
 				}

 			}
 			.date{ 
 				display: inline-block;
 				vertical-align: top;
 				font-size: 14px;
 				padding-right: 24px;
 				position: relative;
 				&::after{
 					content: '';
 					display: block;
 					width: 7px;
 					height: 7px;
 					border-radius: 50%;
 					background: rgba(255,255,255,.4);
 					position: absolute;
 					top: 50%;
 					margin-top: -3.5px;
 					right: 8px;
 				}

 			}
 			.count_view{
 				display: inline-block;
 				vertical-align: top;
 				font-size: 14px;
				padding-left: 20px;
				background: url(images/eye_icon2.svg) no-repeat  scroll 0 4px transparent;
 			}
 		}
 		.title{
 			text-align: center;
 			height: 45px;
 			overflow: hidden;
 			a{
 				font-size: 18px;
 				text-decoration: none;
 				font-weight: 700;
 				&:hover{
					text-decoration: underline;
 				}
 			}
 		}
 	}
 	.col_group_2{
 		margin-right: -25px;
 		font-size: 0;
 		.col{
 			font-size: 14px;
 			width: 25%;
 			display: inline-block;
 			vertical-align: top;
 			padding-right: 25px;
 		}
 		.image{
 			margin-bottom: 8px;
 		}
 		.title{
 			margin-bottom: 10px;
 			a{
 				font-size: 16px;
 				text-decoration: none;
 				line-height: 1.35;
 				transition: .3s;
 				&:hover{
 					color: $color_green2;
 					text-decoration: underline;
 				}
 			}
 		}
 		.col_info{
 			color: $color_grey;
 			font-size: 0;
 			.date{
 				font-size: 12px;
 				display: inline-block;
 				vertical-align: top;

 			}
 			.count_view{
 				font-size: 12px;
 				display: inline-block;
 				vertical-align: top;
				margin-left: 10px;
				padding-left: 20px;
				background: url(images/eye_icon.svg) no-repeat  scroll 0 3px transparent;
 			}
 		}
 	}
}
.view_news{
	img{
		display: block;
		border-radius: 2px;
	}
}
.block_views_news_2{
	.block_title{
		font-size: 24px;
		margin-bottom: 22px;
	}
	.col{
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid #eaeaea;
		&:last-child{
			margin-bottom: 0;
			border-bottom: none;
		}
		&::after{
			content: '';
			display: table;
			clear: both;
		}
	}
	.date{
		font-size: 12px;
		float: left;
		width: 5.5%;
		padding-right: 1%;
		color: $color_grey;
		padding-top: 36px;
	}
	.image{
		float: left;
		margin-right: 15px;
	}
	.title_wrapper{
		overflow: hidden;
	}
	.title{
		margin-bottom: 10px;
		a{
			font-size: 18px;
			font-weight: 700;
			text-decoration: none;
			transition: .3s;
			line-height: 1.45;
			&:hover{
				text-decoration: underline;
				color: $color_green2;
			}
		}
	}
}
.top_inner{
	.info_wrapper{
		padding-top: 10px;
		font-size: 0;
	}
	.node_field{
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		color: $color_grey;
		margin-right: 22px;
	}
	.reviews_count{
		padding-left: 17px;
		background: url(images/reviews_count.svg) no-repeat  scroll 0 center transparent;
	}
	.count_view{
		padding-left: 19px;
		background: url(images/eye_icon.svg) no-repeat scroll 0 3px transparent;
	}
}
/*----------------------------------------------------------------------------
									NODE NEWS
----------------------------------------------------------------------------*/
.node_news{
	.node_image{
		margin-bottom: 10px;
	}
	strong{
		font-size: 18px;
	}
	.description{
		font-size: 16px;
		font-weight: 300;
		text-align: justify;
	}
	.image_social{
		position: relative;
		margin-bottom: 20px;
		&:after{
			content: '';
			display: block;
			width: 103px;
			height: 17px;
			background: url(images/watermark.png) no-repeat  scroll 0 0 transparent;
			position: absolute;
			bottom: 10px;
			right: 11px;
		}
		img{
			display: block;
		}
		&:hover{
			.addthis_inline_share_toolbox_aqao{
				opacity: 1;
				height: auto;
				overflow: visible;
			}
		}
		.at-resp-share-element .at-share-btn{
			margin: 0 10px 0 0 !important;
		}
		.addthis_inline_share_toolbox_aqao{
			opacity: 0;
			height: 0;
			overflow: hidden;
			position: absolute;
			bottom: 10px;
			left: 10px;
			transition: .3s;
		}
	}
	.source_link{
		font-size: 14px;
		font-weight: 300;
		margin-bottom: 15px;
		text-align: right;
		padding-bottom: 20px;
	    border-bottom: 1px solid #eaeaea;
	}
	.facebook_comments{
		margin-bottom: 15px;
	}
}
.other_news{
	margin-bottom: 30px;
	.block_title{
		font-size: 24px;
		margin-bottom: 20px;
	}
	.col{
		margin-bottom: 28px;
		&::after{
			content: '';
			display: table;
			clear: both;
		}
	}
	.image{
		float: left;
		margin-right: 15px;
		img{
			border-radius: 2px;
			display: block;
		}
	}
	.description_wrap{
		overflow: hidden;
	}
	.info_wrap{
		font-size: 12px;
		color: $color_grey;
		margin-bottom: 10px;
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.category{
			float: left;
			a{
				text-decoration: none;
				color: $color_grey;
				transition: .3s;
				&:hover{
					color: $color_green2;
					text-decoration: underline;
				}
			}
		}
		.date{
			float: left;
			margin-left: 25px;
			position: relative;
			&::before{
				content: '';
				display: block;
				width: 7px;
				height: 7px;
				background: #bec7d1;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				margin-top: -3.5px;
				left: -15px;
			}
		}
	}
	.title{
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 10px;
		a{
			text-decoration: none;
			transition: .3s;
			&:hover{
				color: $color_green2;
				text-decoration: underline;
			}
		}
	}
	.description{
		font-size: 14px;
	}
	.load_more{
		display: table;
		margin: 0 auto;
		padding-left: 29px;
		background: url(images/load_more.png) no-repeat  scroll 0 center transparent;
		font-size: 16px;
		font-weight: 300;
		transition: .3s;
		cursor: pointer;
		&:hover{
			text-decoration: underline;
			color: $color_green2;
		}
	}
}
.wrapper .block_news_top .title a{
	line-height: 1.25;
	margin-bottom: 5px;
}
.wrapper .block_news_top .date{
	margin-bottom: 5px;
}
.wrapper .block_news_top .col{
	padding-bottom: 16px;
	margin-bottom: 15px;
}
.wrapper .block_news_top .image{
	padding-top: 21px;
}
.page_news .block_news_top .block_title{
	margin-bottom: 20px;
	margin-top: -4px;
}
.page_journal{
	.left_sidebar{
		.block_news_city{
			.col{
				&:last-child{
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}
	}
	.page_navigation{
		padding-bottom: 0;
	}
}
.node_socials{
	width: 16.8%;
	padding-right: 2%;
	float: left;
	position: relative;
	min-height: 1px;
	.at-label{
		display: none !important;
	}
}
.category_link{
	margin-bottom: 27px;
	a{
		color: $color_green2;
	}
}
.addthis_inline_share_toolbox_6wf7{
	&.fixed{
		position: fixed;
		top: 0;
		left: 50%;
		margin-left: -585px;
		z-index: 50;
		&.bottom{
			position: absolute;
			top:auto;
			bottom: 0;
			left: 0;
			margin-left: 0;
		}
	}
	&::after{
		content: '';
		display: table;
		clear: both;
	}
	a{
		float: left;
		clear: both;
		margin-bottom: 30px !important;
	}
}
.node_content{
	overflow: hidden;
}
.video_wrap {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
	margin-bottom: 15px;
}
.video_wrap iframe,.video_wrap object,.video_wrap embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	border: none;
}