.right_sidebar {
    float: left;
    width: 315px;
    margin-left: -315px;
    position: relative;
    padding-left: 25px;
    border-left: 1px solid #eaeaea;
}
.block_news_top{
	.block_title{
		font-size: 24px;
		margin-bottom: 19px;
	}
	.col{
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #eaeaea;
		&:last-child{
			padding-bottom: 0;
			border-bottom: none;
		}
	}
	.image{
		float: left;
		padding-top: 15px;
		img{
			border-radius: 50%;
			margin-right: 10px;
		}
	}
	.title_wrapper{
		overflow: hidden;
	}
	.date{
		font-size: 12px;
		margin-bottom: 10px;
		color: $color_grey;
	}
	.title{
		a{
			text-decoration: none;
			line-height: 1.45;
			display: block;
			font-size: 16px;
			transition: .3s;
			font-weight: 300;
			&:hover{
				text-decoration: underline;
				color: $color_green2;
			}
		}
	}
	.count_view{
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
		margin-left: 10px;
		color: $color_grey;
		padding-left: 20px;
		background: url(images/eye_icon.svg) no-repeat  scroll 0 3px transparent;
		float: right;
	}
}
.right_sidebar{
	.link_img{
		padding-top: 15px;
		margin-bottom: 30px;
	}
}
.link_img{
	img{
		display: block;
	}
}
.fb-page{
	margin-bottom: 30px;
}
.vip_notice{
	margin-bottom: 30px;
	.block_title{
		font-size: 24px;
		margin-bottom: 20px;
	}
	.col{
		.page_company &{
			margin-bottom: 25px;
		}
	}
	.image{
		img{
			border-radius: 2px;
			display: block;
			margin-bottom: 8px;
		}
	}
	.title{
		a{
			text-decoration: none;
			transition: .3s;
			font-size: 16px;
			font-weight: 300;
			display: block;
			margin-bottom: 3px;
			&:hover{
				color: $color_green2;
				text-decoration: underline;
			}
		}
	}
	.price{
		font-size: 16px;
		font-weight: 300;
		color: $color_green2;
	}
}
.page_company{
	.block_weather{
		padding-bottom: 25px;
		margin-bottom: 25px;
		border-bottom: 1px solid #eaeaea;
	}
}