.footer {
	width: 100%;
	margin: -308px auto 0;
	height: 308px;
	position: relative;
	clear: both;
	border-top: 1px solid #eaeaea;
	&>.footer_content:first-child{
		padding-top: 13px;
	}
	.footer_content{
		max-width: 1170px;
		margin:0 auto;
	}
	.footer_menu{
		margin-bottom: 13px;
		ul{
			font-size: 0;
			li{
				display: inline-block;
				width: 10.6%;
				vertical-align: bottom;
				text-align: center;
				&.logo{
					width: 15%;
				}
			}
		}
		a{
			text-decoration: none;
			font-size: 18px;
			transition: .3s;
			&.active,
			&:hover{
				color: $color_green;
				text-decoration: underline;
			}
			&.footer_logo{
				width: 120px;
				height: 42px;
				text-indent: -9999px;
				display: block;
				background: url(images/footer_logo.svg) no-repeat  scroll 0 0 transparent;
				margin: 0 auto;
			}
		}
	}
	.social_container{
		position: relative;
		margin-bottom: 16px;
		&::after{
			content: '';
			display: block;
			width: 100%;
			background: #eaeaea;
			height: 1px;
			position: absolute;
			top: 50%;
			left: 0;
		}
		.social_links{
			font-size: 0;
			display: table;
			margin: 0 auto;
			position: relative;
			z-index: 1;
			.social{
				display: inline-block;
				vertical-align: top;
				margin-right: 10px;
				&:last-child{
					margin-right: 0;
				}
				a{
					display: block;
					width: 32px;
					height: 32px;
					border-radius: 50%;
					&.social_fb{
						background: url(images/fb_icon.svg) no-repeat  scroll 0 0 transparent;
						background-size: cover !important;
						&:hover{
							background: url(images/fb_icon_hover.svg) no-repeat  scroll 0 0 transparent;
						}
					}
					&.social_tel{
						background: url(images/tel_icon.svg) no-repeat  scroll 0 0 transparent;
						background-size: cover !important;
						&:hover{
							background: url(images/tel_icon_hover.svg) no-repeat  scroll 0 0 transparent;
						}
					}
					&.social_tw{
						background: url(images/tw_icon.svg) no-repeat  scroll 0 0 transparent;
						background-size: cover !important;
						&:hover{
							background: url(images/tw_icon_hover.svg) no-repeat  scroll 0 0 transparent;
						}
					}
				}
			}
		}
	}
	.footer_text{
		text-align: center;
		font-weight: 300;
		line-height: 1.45;
		p{
			margin-bottom: 19px;
		}
	}
	.footer_info{
		border-top: 1px solid #eaeaea;
		background: #fbfcfe;
		padding: 15px 0;
		.copyright{
			float: left;
			padding-top: 3px;
			font-weight: 300;
		}
		#gl_devel{
			float: right;
		}
		.info_text{
			display: table;
			margin: 0 auto;
			padding-top: 3px;
			font-weight: 300;
			a{
				color: $color_green2;
			}
		}
	}
	.login_form{
		position: fixed;
		width: 320px;
		margin: -222px 0 0 -160px;
		left: 50%;
		top: 50%;
		z-index: 100;
		background: #fff;
		padding: 22px 30px 25px;
		display: none;
		.block_title{
			font-size: 24px;
			margin-bottom: 18px;
		}
		.close_icon{
			position: absolute;
			top: 10px;
			right: 10px;
			width: 24px;
			height: 24px;
			background: url(images/close_icon.png) no-repeat  scroll 0 0 transparent;
			text-indent: -9999px;
			cursor: pointer;
			&:hover{
				opacity: 0.7;
			}
		}
		.form_text{
			height: 48px;
			margin-bottom: 19px;
		}
		.form_actions{
			margin-bottom: 20px;
			&::after{
				content: '';
				display: table;
				clear: both;
			}
			.forgot_pass{
				float: left;
				text-decoration: none;
				color: $color_grey;
				&:hover{
					text-decoration: underline;
				}
			}
			.form_submit{
				float: right;
				height: 48px;
				color: #fff;
				border-radius: 2px;
				color: #fff;
				background: $color_green;
				padding: 0 25px;
				border: none;
				font-size: 24px;
			}
		}
		.bot_wrap{
			text-align: center;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid #e0e0e0;
			.title{
				font-size: 18px;
				margin-bottom: 6px;
			}
			.register_link{
				color: $color_green2;
			}
		}
		.socials_title{
			font-size: 18px;
			margin-bottom: 10px;
			text-align: center;
		}
		.social_links{
			font-size: 0;
			display: table;
			margin: 0 auto;
			position: relative;
			z-index: 1;
			.social{
				display: inline-block;
				vertical-align: top;
				margin-right: 10px;
				&:last-child{
					margin-right: 0;
				}
				a{
					display: block;
					width: 32px;
					height: 32px;
					border-radius: 50%;
					&.social_fb{
						background: url(images/fb_icon_hover.svg) no-repeat  scroll 0 0 transparent;
						background-size: cover !important;
						&:hover{
							background: url(images/fb_icon.svg) no-repeat  scroll 0 0 transparent;
						}
					}
					&.social_tel{
						background: url(images/tel_icon_hover.svg) no-repeat  scroll 0 0 transparent;
						background-size: cover !important;
						&:hover{
							background: url(images/tel_icon.svg) no-repeat  scroll 0 0 transparent;
						}
					}
					&.social_tw{
						background: url(images/tw_icon_hover.svg) no-repeat  scroll 0 0 transparent;
						background-size: cover !important;
						&:hover{
							background: url(images/tw_icon.svg) no-repeat  scroll 0 0 transparent;
						}
					}
				}
			}
		}
	}
}
