/*----------------------------------------------------------------------------
									VARIABLES
----------------------------------------------------------------------------*/
@import '_variables';
.view_company{
	.view_header{
		font-size: 0;
		margin-bottom: 45px;
		.views_field:not(.add_company){
			font-size: 14px;
			display: inline-block;
			vertical-align: top;
			margin-right: 80px;
			.label{
				font-size: 11px;
				text-transform: uppercase;	
				margin-bottom: 5px;
				color: $color_grey;
			}
			.value{
				font-size: 24px;
			}
		}
	}
	.view_content{
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.col_left{
			float: left;
		}
		.col_right{
			float: right;
		}
		.row{
			margin-bottom: 35px;
		}
		.main_title{
			a{
				text-decoration: none;
				font-weight: 900;
				color: #32ab00;
				font-size: 18px;
				display: block;
				padding-left: 40px;
				margin-bottom: 20px;
				line-height: 25px;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		@for $i from 1 through 17 {
		  .main_title_#{$i} {
		    a{
		    	background: url('images/company_icon_'+$i+'.svg') no-repeat  scroll 0 center transparent;
		    }
		  }
		}
		ul{
			padding-left: 40px;
			li{
				margin-bottom: 15px;
				a{
					text-decoration: none;
					font-size: 16px;
					font-weight: 300;
					transition: .3s;
					&:hover{
						color: $color_green2;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.add_company{
	font-size: 14px;
	display: inline-block;
	vertical-align: top;
	line-height: 32px;
	background: url(images/add_company.png) no-repeat  scroll 22px center #fff241;
	border-radius: 1px;
	border: 2px solid #ffdd09;
	padding: 0 25px 0 50px;
	cursor: pointer;
	transition: .3s;
	&:hover{
		opacity: 0.7;
	}
}
.view_company_category{
	.category_menu{
		padding-bottom: 15px;
		margin-bottom: 25px;
		border-bottom: 1px solid #eaeaea;
		font-size: 0;
		.menu_group{
			font-size: 16px;
			display: inline-block;
			vertical-align: top;
			padding-right: 35px;
			width: 33.3%;
			&:last-child{
				padding-right: 0;
			}
		}
		li{
			margin-bottom: 15px;
			&:last-child{
				margin-bottom: 0;
			}
			a{
				text-decoration: none;
				font-weight: 300;
				transition: .3s;
				&:hover{
					color: $color_green2;
					text-decoration: underline;
				}
				span{
					margin-left: 12px;
					font-size: 12px;
					color: $color_grey;
				}
			}
		}
		.add_company{
			float: right;
			margin-top: 15px;
		}
	}
	.view_content{
		.views_title{
			font-size: 24px;
			margin-bottom: 25px;
		}
	}
	.col{
		padding: 18px;
		margin-bottom: 1px;
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		&.vip{
			background: url(images/vip_bg.png) repeat  scroll 0 0 transparent;
			.btns_wrapper{
				position: relative;
				.btn{
					border-color: #f5a623;
					color: #f5a623;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					white-space: nowrap;
					top: auto;
				}
			}
		}
		&.buisness{
			background: url(images/buisness_bg.png) repeat  scroll 0 0 transparent;
		}
		&.base{
			background: url(images/base_bg.png) repeat  scroll 0 0 transparent;
			.btns_wrapper{
				.btn{
					border-color: #87909c;
					color: #87909c;
				}
			}
		}
		.images{
			float: left;
			width: 210px;
			height: 135px;
			margin-right: 12px;
			display: table;
			background: #fff;
			border: 1px solid rgba(151, 151, 151, 0.2);
			a{
				display: table-cell;
				vertical-align: middle;
				img{
					display: block;
					margin: 0 auto;
				}
			}
		}
		.info_wrapper{
			float: left;
		}
		.award{
			img{
				display: block;
				margin: 0 auto;
			}
		}
		.btns_wrapper{
			width: 130px;
			float: right;
			position: relative;
			min-height: 78px;
			height: 100%;
			.btn{
				border: 1px solid #4a90e2;
				border-radius: 2px;
				display: inline-block;
				text-transform: uppercase;
				font-weight: 900;
				font-size: 11px;
				color: #4a90e2;
				padding: 7px 0 5px;
				width: 100%;
				text-align: center;
				background: #fff;
				position: absolute;
				top: 50%;
				margin-top: -14px;
				right: 0;
			}
		}
		.title{
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 20px;
			a{
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		.phone{
			font-size: 16px;
			font-weight: 300;
			padding-left: 23px;
			background: url(images/phone_icon_ad.png) no-repeat  scroll 0 center transparent;
			margin-bottom: 15px;
			margin-left: 10px;
		}
		.address{
			font-size: 16px;
			font-weight: 300;
			padding-left: 23px;
			background: url(images/address_icon_ad.png) no-repeat  scroll 0 5px transparent;
			margin-left: 10px;
		}
	}
	.col:not(.vip):not(.buisness){
		.info_wrapper{
			width: 76%;
			.title{
				float: left;
				width: 63%;
				padding-right: 2.6%;
			}
			.address_wrap{
				overflow: hidden;
			}
		}
	}
	.link_image{
		text-align: center;
		margin: 20px 0;
		img{
			display: block;
			margin: 0 auto;
		}
	}
	.page_navigation{
		border: none;
	}
}
/*----------------------------------------------------------------------------
									NODE COMPANY
----------------------------------------------------------------------------*/
.map_canvas {
    height: 385px;
    margin-bottom: 27px;
    margin-top: 11px;
    clear: both;
}
.node_company{
	.image{
		float: left;
		margin-right: 22px;
		display: table;
		height: 179px;
		width: 270px;
		border: 1px solid rgba(151, 151, 151, 0.2);
		.field_content{
			display: table-cell;
			vertical-align: middle;
		}
		img{
			display: block;
			margin: 0 auto;
		}
	}
	.description_wrapper{
		overflow: hidden;
	}
	.company_name{
		font-size: 24px;
		float: left;
	}
	.count_view{
		font-weight: 300;
		padding-left: 20px;
		background: url(images/eye_icon.svg) no-repeat  scroll 0 11px transparent;
		float: right;
		line-height: 32px;
	}
	.btns_wrapper{
		width: 130px;
		float: right;
		position: relative;
		min-height: 78px;
		height: 100%;
		.btn{
			border: 1px solid #4a90e2;
			border-radius: 2px;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 900;
			font-size: 11px;
			color: #4a90e2;
			padding: 7px 0 5px;
			width: 100%;
			text-align: center;
			background: #fff;
			position: absolute;
			top: 50%;
			margin-top: -14px;
			right: 0;
		}
	}
	.btns_wrapper.vip{
		position: relative;
		float: right;
		width: 130px;
		margin-top: 36px;
		margin-right: 9px;
		img{
			display: block;
			margin: 0 auto;
		}
		.btn{
			border-color: #f5a623;
			color: #f5a623;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			white-space: nowrap;
			top: auto;
		}
	}
	.descrtiption{
		display: block;
		padding-top: 20px;
		font-size: 16px;
		font-weight: 300;
		float: left;
		clear: both;
		p{
			margin-bottom: 10px;
		}
	}
}
.page_node_company{
	.vip_notice{
		border-top: 1px solid #dfdfdf;
		padding-top: 25px;
	}
}