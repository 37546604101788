html {
	height: 100%;
}
body {
	font: 500 14px 'museosanscyr', Arial, sans-serif;
	width: 100%;
	height: 100%;
	line-height: 1.3125;
	color: $color_main;

}
.wrapper {
	width: 100%;
	margin: 0 auto;
	min-height: 100%;
	height: auto !important;
	height: 100%;
	// &.front{
	// 	background: red;
	// }
}
.middle {
	width: 100%;
	padding: 0 0 308px;
	position: relative;
	clear: both;
	.sidebar_left &,
	.sidebar_right &{
		max-width: 1170px;
		margin: 0 auto;
	}
	.bottom_region &{
		padding-bottom: 20px;
	}
	&::after {
		display: table;
		clear: both;
		content: '';
	}
}
.bottom_region{
	.region_bottom{
		padding-bottom: 308px;
	}
}
.middle_content{
	max-width: 1170px;
	margin: 0 auto;
	.sidebar_right &{
		padding-right: 338px;
	}
	.sidebar_left &{
		padding-left: 340px;
	}
}
.container {
	width: 100%;
	float: left;
	overflow: hidden;
}
.top_inner{
	padding-top: 24px;
	.page_news_node &{
		padding-top: 0;
	}
	.block_content{
		max-width: 1170px;
		margin: 0 auto;
		border-bottom: 1px solid #eaeaea;
		margin-bottom: 29px;
		padding-bottom: 17px;
	}
	.inner_wrapper{
		float: left;
		max-width: 60%;
		.page_news_node &{
			max-width: 850px;
		}
		.breadcrumbs{
			color: $color_grey;
			text-transform: uppercase;
			ul{
				font-size: 0;
				li{
					display: inline-block;
					vertical-align: top;
					font-size: 11px;
				}
			}
			a{
				color: $color_grey;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
			.separator{
				padding: 0 13px;
			}
		}
		.page_title{
			font-size: 36px;
			margin: 0;
			line-height: 1.2;
			padding-top: 6px;
			font-weight: 400;
		}
	}
	.link_image{
		float: right;
		margin-top: -3px;
		img{
			display: block;
		}
	}
}
/*----------------------------------------------------------------------------
									ERROR PAGE
----------------------------------------------------------------------------*/
.error_wrapper{
	.error_content{
		text-align: center;
		margin-bottom: 40px;
		.error_title{
			font-size: 36px;
			padding-top: 40px;
			padding-bottom: 250px;
			background: url(images/error_bg.png) no-repeat  scroll center bottom transparent;
			margin-bottom: 38px;
		}
		.error_text{
			font-size: 16px;
			font-weight: 300;
			a{
				color: $color_green2;
			}
		}
	}
	.link_image{
		img{
			display: block;
			margin: 0 auto 40px;
		}
	}
}
.page_register,
.error_wrapper{
	.vip_notice{
		padding-top: 25px;
		border-top: 1px solid #eaeaea;
		.block_content{
			font-size: 0;
			margin:0 -24px 0 0 !important;
			.col{
				margin: 0;
				padding-right: 24px;
				width: 25%;
			}
		}
	}
}
/*----------------------------------------------------------------------------
									PAGER
----------------------------------------------------------------------------*/
.page_navigation{
	padding: 30px 0;
	border-top: 1px solid #eaeaea;
	ul{
		display: table;
		margin: 0 auto;
		font-size: 0;
		li{
			display: inline-block;
			vertical-align: middle;
			font-size: 18px;
			margin: 0 12px;
			a{
				text-decoration: none;
				transition: 0.3s;
				&:hover{
					color: $color_green2;
				}
			}
			&.page_arrow{
				a{
					display: block;
					text-indent: -9999px;
					width: 7px;
					height: 29px;
					transition: 0.3s;
				}
				&.arrow_prev{
					a{
						background: url(images/page_prev.png) no-repeat  scroll 0 center transparent;
						&:hover{
							background: url(images/page_prev2.png) no-repeat  scroll 0 center transparent;
						}
					}
				}
				&.arrow_next{
					a{
						background: url(images/page_next.png) no-repeat  scroll 0 center transparent;
						&:hover{
							background: url(images/page_next2.png) no-repeat  scroll 0 center transparent;
						}
					}
				}
			}
			&.current_page{
				a{
					font-size: 24px;
					color: $color_green2;
					font-weight: 700;
				}
			}
		}
	}
}
/*----------------------------------------------------------------------------
									LOADER
----------------------------------------------------------------------------*/
.ajax-progress {
	background: rgba(255,255,255,.8);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-indent: -9999px;
	margin: 0;
	padding: 0;
	z-index: 1000;
}
.ajax-progress .throbber{
    background: none;
    border-radius: 6px;
    position: fixed;
    left: 50%;
    top: 50%;
    width:50px;
    height:50px;
    text-indent: -9999px;
    margin: 0;
    padding: 0 !important;
    border: 8px solid rgba(0,0,0,.3);
    border-left: 8px solid #000;
    border-radius: 100%;
    -webkit-animation: rotate 0.7s linear infinite;
    -moz-animation: rotate 0.7s linear infinite;
    animation: rotate 0.7s linear infinite;
    margin-top: -25px;
    margin-left: -25px;
}
@-webkit-keyframes rotate{
	0%{
		-webkit-transform:rotate(0deg);
	}
	100%{
		-webkit-transform:rotate(360deg);
	}
}

@-webkit-keyframes fadeOut{
	0%{
		-webkit-transform:scale(0);
	}
	50%{
		-webkit-transform:scale(1);opacity: 0.1;
	}
	100%{
		-webkit-transform:scale(.1);opacity: 0.8;
	}
}
@-webkit-keyframes strechdelay{
	0%,50%,100%{
		-webkit-transform:scaleY(.4);
	}
	20%{
		-webkit-transform:scaleY(1);
	}
}
@-moz-keyframes rotate{
	0%{
		-moz-transform:rotate(0deg);
	}
	100%{
		-moz-transform:rotate(360deg);
	}
}

@-moz-keyframes fadeOut{
	0%{
		-moz-transform:scale(0);
	}
	50%{
		-moz-transform:scale(1);opacity: 0.1;
	}
	100%{
		-moz-transform:scale(.1);opacity: 0.8;
	}
}
@-moz-keyframes strechdelay{
	0%,50%,100%{
		-moz-transform:scaleY(.4);
	}
	20%{
		-moz-transform:scaleY(1);
	}
}
@keyframes rotate{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}

@keyframes fadeOut{
	0%{
		transform:scale(0);
	}
	50%{
		transform:scale(1);opacity: 0.1;
	}
	100%{
		transform:scale(.1);opacity: 0.8;
	}
}
@keyframes strechdelay{
	0%,50%,100%{
		transform:scaleY(.4);
	}
	20%{
		transform:scaleY(1);
	}
}
/*----------------------------------------------------------------------------
									BASIC STYLES
----------------------------------------------------------------------------*/
.field-type-tex-with-summary,
.field-type-text-long{
	.content_left_img{
		margin: 5px 20px 5px 0;
	}
	.content_right_img{
		margin: 5px 0 5px 20px;
	}
}
.grippie {
	display: none !important; 
}
.webform-confirmation + .links{
	display: none !important;
}
ul.menu, ul {
	li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
}
*, :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
input{
	vertical-align: middle;
	box-sizing: border-box;
	&.form_submit {
		cursor: pointer;
		transition: 0.3s;
		&:hover{
			opacity: 0.8;
		}
	}
	&.form_text{
	    transition: 0.3s;
	    width: 100%;
	    padding: 0 10px;
	    &:focus{
	    	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(9,169,39, .6) !important;
	    }
	}
}
.form_text{
	height: 32px;
	border-radius: 2px;
	border: 1px solid #bec7d1;
	background-color: #ffffff;
	width: 100%;
}
textarea{
	box-sizing: border-box;
	transition: 0.3s;
    &:focus{
    	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(9,169,39, .6) !important;
    }
}
select.error,
input.error,
textarea.error,
input[type="text"].error,
input[type="password"].error{
	border:1px solid red !important;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
select::-ms-expand {
  display: none;
}
.form-item, .form-actions {
	margin: 0;
}
a {
	color: $color_main;
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}
img{
	max-width: 100%;
	height: auto;
	border: none;
}
* {
	margin: 0;
	padding: 0;
	outline: none;
}
p {
	margin: 0 0 18px;
}
.popup_fon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    display: none;
    z-index: 99;
}
#gl_devel {
    color: $color_main;
    font: 12px 'cuprumregular';
    text-transform: uppercase;
    z-index: 25;
    overflow: hidden;
    float: left;
    position: relative;
    margin-top: 5px;
}

#gl_devel img {
    margin-right: 2px;
    display: inline-block;
    vertical-align: top;
}

#gl_devel a {
    color: $color_main;
    text-decoration: none;
    display: inline-block;
}

#gl_devel .mask_devel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    clip: rect(0px, 30px, 20px, 0px);
    -webkit-animation: glyanec 5s cubic-bezier(0, -3, 1, 1) infinite;
    animation: glyanec 5s cubic-bezier(0, -3, 1, 1) infinite;
}

#gl_devel .mask_devel a,
#gl_devel .mask_devel {
    color: #fff;
}

#gl_devel > span > img {
    -webkit-animation: glyanec_img 5s infinite;
    animation: glyanec_img 5s infinite;
}

@-webkit-keyframes glyanec {
    0% {
        clip: rect(0px, 15px, 20px, 0px);
    }

    100% {
        clip: rect(0px, 200px, 20px, 200px);
    }
}

@keyframes glyanec {
    0% {
        clip: rect(0px, 15px, 20px, 0px);
    }

    100% {
        clip: rect(0px, 200px, 20px, 200px);
    }
}

@-webkit-keyframes glyanec_img {
    0% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }

    20% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }

    40% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }

    60% {
        -webkit-transform: rotate3d(0, 10, 0, 180deg);
        transform: rotate3d(0, 10, 0, 180deg);
    }

    80% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }
}

@keyframes glyanec_img {
    0% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }

    20% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }

    40% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }

    60% {
        -webkit-transform: rotate3d(0, 10, 0, 180deg);
        transform: rotate3d(0, 10, 0, 180deg);
    }

    80% {
        -webkit-transform: rotate3d(10, 0, 0, 0deg);
        transform: rotate3d(10, 0, 0, 0deg);
    }
}

#gl_devel a:hover {
    text-decoration: underline;
}

body.ie_browser #gl_devel a,
body.ie_browser #gl_devel span {
    font-size: 8px;
}

#gl_devel a:hover {
    text-decoration: underline;
}
.clearfix{
	&::after{
		content: '';
		display: table;
		clear: both;
	}
}