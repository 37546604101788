.register_form{
	.form_item:not(.rules) {
		font-size: 0;
	    margin-bottom: 9px;
	    label { 
	        display: inline-block;
	        vertical-align: middle;
	        text-align: right;
	        width: 38.8%;
	        margin-right: 0.8%;
	        font-weight: 300;
	        font-size: 16px;
	        line-height: 20px;
	    }
	    input {
	    	font-size: 16px;
	        outline: none;
	        background: none;
	        width: 210px;
	        font-weight: 300;
	        height: 32px;
	        border-radius: 2px;
	        padding: 0 10px;
	        border: 1px solid #bec7d1;
	    }
	    .description{
	    	display: inline-block;
	    	font-size: 12px;
	    	line-height: 32px;
	    	padding-left: 10px;
	    	color: $color_grey;
	    	vertical-align: middle;
	    }
	    .user_photo_load {
	        display: inline-block;
	        vertical-align: middle;
	        width: 100px;
	        height: 100px;
	        border-radius: 100%;
	        overflow: hidden;
	        margin-right: 17px;
	        &+ div {
			    display: inline-block;
			    vertical-align: middle;
			    position: relative;
			    height: 32px !important;
			    input{
			    	position: absolute;
			    	width: 100%;
			    	height: 100%;
			    	right: 0;
			    	top: 0;
			    }
			}
	    }
		.jInputFile-fakeButton {
			font-size: 16px;
		    position: relative;
		    top: auto;
		    left: auto;
		    bottom: auto;
		    right: auto;
		    margin: 0 0 10px;
		    outline: none;
		    background: none;
		    border: 1px solid #00b335;
		    border-radius: 2px;
		    height: 32px;
		    line-height: 32px;
		    padding: 0 25px;
		    width: auto;
		    display: inline-block;
		    vertical-align: middle;
		    color: #09a927;
		    cursor: pointer;
		    -webkit-transition: .3s;
		    transition: .3s;
		}
		.jInputFile-activeBrowseButton{
			display: none !important;
		}
	    &.phone{
	    	&::after{
	    		content: '';
	    		display: table;
	    		clear: both;
	    	}
	    	label{
	    		float: left;
	    	}
		    .input_wrapper{
		    	overflow: hidden;
		    }
		    .description{
		    	line-height: 16px;
		    	padding-left: 0;
		    	padding-top: 8px;
		    	margin-bottom: 15px;
		    }
	    }
	}
	.rules{
		padding-top: 15px;
		padding-left: 39.6%;
		margin-bottom: 20px;
		input{
			display: none;
			&:checked{
				&+label{
					background: url(images/check2.png) no-repeat  scroll 0 0 transparent;
				}
			}
		}
		label{
			padding-left: 25px;
			background: url(images/check.png) no-repeat  scroll 0 0px transparent;
			font-weight: 300;
			font-size: 16px;
			cursor: pointer;
		}
	}
	.g-recaptcha{
		padding-left: 39.6%;
		margin-bottom: 18px;
	}
	.form_actions{
		padding-left: 39.6%;
		margin-bottom: 40px;
		.form_submit{
			border: none;
			background: #32ab2b;
			height: 48px;
			width: 300px;
			color: #fff;
			border-radius: 2px;
			font-size: 24px;
		}
	}
}
.form_description{
	font-size: 16px;
	font-weight: 300;
	text-align: center;
	margin-bottom: 20px;
}
.commercial_wrapper{
	.main_title{
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 31px;
	}
	.commercial_block{
		font-size: 0;
		margin-bottom: 10px;
		.col{
			font-size: 14px;
			padding-right: 115px;
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding-left: 32px;
			&_1{
				background: url(images/commerce_icon_1.svg) no-repeat  scroll 0 0 transparent;
			}
			&_2{
				background: url(images/commerce_icon_2.svg) no-repeat  scroll 0 0 transparent;
			}
		}
		.title{
			font-size: 18px;
			margin-bottom: 12px;
		}
		a{
			font-size: 16px;
			font-weight: 300;
			color: $color_green2;
			display: block;
			margin-bottom: 10px;
		}
	}
	.commerce_description{
		border: 1px solid #e6e7e9;
		padding: 23px 40px 3px 75px;
		background: url(images/info_icon.svg) no-repeat  scroll 20px 22px #fbfcfe;
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 25px;
	}
	.form_title{
		text-align: center;
		font-size: 24px;
		margin-bottom: 25px;
	}
	.form_item{
		font-size: 0;
		margin-bottom: 10px;
		label{
			font-size: 16px;
			font-weight: 300;
			display: inline-block;
			vertical-align: middle;
			width: 32.3%;
			text-align: right;
			padding-right: 1%;
		}
		input{
			font-size: 16px;
			display: inline-block;
			vertical-align: middle;
			height: 32px;
			width: 210px;
			font-weight: 300;
		}
		textarea{
			width: 500px;
			height: 100px;
			resize: none;
		    border: 1px solid #bec7d1;
		    display: inline-block;
		    vertical-align: top;
		    font-size: 16px;
		    padding: 10px;
		    font-weight: 300;
		}
	}
	.form_textarea{
		margin-bottom: 20px;
		label{
			vertical-align: top;
		}
	}
	form{
		.description{
			font-size: 12px;
			color: $color_grey;
			display: inline-block;
			vertical-align: middle;
		}
	}
	.jInputFile-fakeButton {
		font-size: 16px;
	    position: relative;
	    top: auto;
	    left: auto;
	    bottom: auto;
	    right: auto;
	    margin: 0 0 10px;
	    outline: none;
	    background: none;
	    border: 1px solid #00b335;
	    border-radius: 2px;
	    height: 32px;
	    line-height: 32px;
	    padding: 0 25px;
	    width: auto;
	    display: inline-block;
	    vertical-align: middle;
	    color: #09a927;
	    cursor: pointer;
	    -webkit-transition: .3s;
	    transition: .3s;
	}
	.jInputFile-activeBrowseButton{
		display: none !important;
	}
	.file_load{
		margin-bottom: 20px;
		margin-left: 32.3%;
		&>div{
			position: relative;
			height: 32px !important;
			input{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				right: 0;
				z-index: 80;
			}
			&:first-child{
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			}
		}
	}
	.g-recaptcha{
		margin-left: 32.3%;
		margin-bottom: 18px;
	}
	.form_actions{
		padding-left: 32.3%;
		margin-bottom: 28px;
		.form_submit{
			border: none;
			background: #32ab2b;
			height: 48px;
			padding: 0 29px;
			color: #fff;
			border-radius: 2px;
			font-size: 24px;
		}
	}
	&+.vip_notice{
		padding-top: 25px;
		border-top: 1px solid #eaeaea;
	}
}
.page_node_notice,
.page_notice,
.page_add_notice,
.page_weather{
	.counter_wrap{
		float: right;
		font-size: 0;
		padding-top: 16px;
		.item{
			font-size: 24px;
			font-weight: 900;
			display: inline-block;
			vertical-align: top;
			margin-left: 41px;
		}
		.label{
			font-size: 11px;
			color: $color_grey;
			text-transform: uppercase;
			font-weight: 400;
		}
	}
	.right_sidebar .link_img{
		padding-top: 0;
	}
	.vip_notice{
		padding-top: 25px;
		border-top: 1px solid #eaeaea;
	}
}
.weather_wrapper{
	.menu{
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #eaeaea;
		ul{
			display: flex;
			justify-content: space-between;
			a{
				font-size: 16px;
				font-weight: 300;
				transition: .3s;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
					color: $color_green2;
				}
			}
		}
	}
	.day_wrapper{
		font-size: 0;
		padding-bottom: 9px;
		margin-bottom: 20px;
		border-bottom: 1px solid #eaeaea;
		.title{
			font-size: 24px;
			margin-bottom: 20px;
		}
		.weather_icon{
			display: inline-block;
			margin-right: 20px;
			vertical-align: top;
		}
		.day_content{
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			font-weight: 300;
		}
		.temperature{
			font-size: 24px;
			color: #32ab00;
			font-weight: 900;
			margin-bottom: 10px;
			padding-top: 11px;
		}
		.description{
			p{
				margin-bottom: 15px;
			}
		}
	}
	.week_wrapper{
		font-size: 0;
		margin-right: -60px;
		margin-bottom: 20px;
		.col{
			font-size: 16px;
			display: inline-block;
			vertical-align: top;
			padding-right: 85px;
			width: 16.6%;
		}
		.day{
			font-weight: 700;
			margin-bottom: 15px;
		}
		.date{
			font-weight: 300;
			margin-bottom: 20px;
		}
		.weather_icon{
			margin-bottom: 10px;
		}
		.temperature{
			font-size: 18px;
			font-weight: 700;
		}
	}
}
.user_photo_load + div * ,
.user_photo_load + div,
input[type="file"]{
	cursor: pointer;
}
.page_weather{
	.top_inner .block_content{
		margin-bottom: 22px;
	}
}
.page_commercial{
	.top_inner .block_content{
		margin-bottom: 23px;
	}
}
