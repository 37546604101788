/*----------------------------------------------------------------------------
									FONTS
----------------------------------------------------------------------------*/
@import 'includes/_fonts';
/*----------------------------------------------------------------------------
									VARIABLES
----------------------------------------------------------------------------*/
@import 'includes/_variables';
/*----------------------------------------------------------------------------
									BASE
----------------------------------------------------------------------------*/
@import 'includes/_base';
/*----------------------------------------------------------------------------
									HEADER
----------------------------------------------------------------------------*/
@import 'includes/_header';
/*----------------------------------------------------------------------------
                                    NEWS
----------------------------------------------------------------------------*/
@import 'includes/_news';
/*----------------------------------------------------------------------------
									COMPANY
----------------------------------------------------------------------------*/
@import 'includes/company';
/*----------------------------------------------------------------------------
									FRONT
----------------------------------------------------------------------------*/
@import 'includes/_front';
/*----------------------------------------------------------------------------
									POSTER
----------------------------------------------------------------------------*/
@import 'includes/_poster';
/*----------------------------------------------------------------------------
									POSTER
----------------------------------------------------------------------------*/
@import 'includes/_notice';
/*----------------------------------------------------------------------------
									OTHER PAGES
----------------------------------------------------------------------------*/
@import 'includes/_other_pages';
.page_user{	
	.top_inner {
		.block_content{
			padding-bottom: 0;		
			border-bottom: 0;
			margin-bottom: 14px;
			.inner_wrapper {	
				.page_title{
					display: inline-block;
					vertical-align: middle;
				}
				.user_logout {	
					display: inline-block;
					vertical-align: middle;
					margin: 0 0 0 14px;
					 a{
						font-size: 12px;
						line-height: 16px;
						color: $color_grey;
					}
				}
			}
		}
	} 
	.block_user_menu {
		clear: both;
		width: 100%;
		border-bottom: 1px solid #d0d6df;
		margin: 0 0 30px;		
		.block_content{
			max-width: 1170px;
			margin: auto;		
			ul{
				width: 100%;
				list-style: none;
				background: rgba(255,255,255,1);
				background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(243,243,243,1)));
				background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
				background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
				background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
				background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3', GradientType=0 );
				li{
					display: inline-block;
					vertical-align: bottom;
					height: 100%;
					width: 14.32%;
					font-size: 0px;
					margin-left: -4px;
					&:first-child{
						margin-left: 0;
					}
					a{
						display: block;
						height: 48px;
						cursor: pointer;
						line-height: 48px;
						font-size: 16px;
						text-align: center;
						text-decoration: none;
						padding: 0 17px;
						font-weight: 700;
						color: #4a515b;
						// border:1px solid transparent;
						border: 1px solid #ccd2da;
						border-bottom: 0;
						transition: background .3s;
						&:hover{
							background:#fff;
							// border-color: #ccd2da;
							// border-bottom: 0;
						}
						&:last-child{
							// border-right: 0;
						}
						&.first {
							// height: 54px;
							// line-height: 54px;
							// border:1px solid #ccd2da;
							// border-bottom: 0;
						}
						&.active{
							background:#fff;
							position: relative;
							height: 54px;
							line-height: 54px;
							border:1px solid #ccd2da;
							border-bottom: 0;
							&::after{
								content: '';
								position: absolute;
								bottom: -2px;
								left: 0;
								height: 4px;
								background:#fff;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.view_user{
		.title_block{
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 23px;
		}
	}
	.view_user_personal{
		.top_wrap,
		.bottom_wrap{
			padding: 0 0 31px;
			margin: 0 0 30px;
			border-bottom: 1px solid #eaeaea;
			overflow: hidden;
		}
		.user_photo{
			float: left;
			width: 200px;
			height: 200px;
			border-radius: 100%;
			margin: 0px 33px 0 0;
			img{
				display: block;
				margin: auto;
			}
		}
		.user_info_wrap{
			overflow: hidden;
			color: #3b4047;
			.group_wrap{
				width: 48%;
				padding: 0 30px 0 0;
				display: inline-block;
				vertical-align: top;
				.item{
					margin: 0px 0 23px;
					font-size: 16px;
					line-height: 22px;
					&.field_phone_number{
						margin-top: 40px!important;
					}					
					&:last-child{
						margin: 0 auto;
					}
					.label{
						font-weight: 300;
					}
					.field_item{
						font-weight: 700;
						a{
							text-decoration: none;
						}
					}
				}
			}
		}
		.bottom_wrap{
			.group_wrap{
				display: inline-block;
				vertical-align: top;
				width: 36%;
				padding-right: 30px;
				&:nth-child(3n+3){
					width: 27%;
				}
				.item{
					margin: 0 0 44px;
					&:last-child{
						margin-bottom: 0;
					}
					.label{
						font-size: 18px;
						line-height: 24px;
						position: relative;
						padding: 0 0 5px;
						margin: 0 0 11px;
						&::after{
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 1px;
							width: 74px;
							background:#eaeaea;
						}
					}
					.field_item{
						.count{
							font-size: 24px;
							line-height: 30px;
							font-weight: 900;
							margin: 0 0 3px;
						}
						.link{
							a{
								line-height: 20px;
								cursor: pointer;
								color:$color_green3;
							}
						}
					}
				}
			}
		}
	}
	.user_edit_webform{
		overflow: hidden;
		padding-bottom: 33px;
		margin-bottom: 30px;
		border-bottom: 1px solid #eaeaea;
		.form_item{
			margin-bottom: 9px;
			&.title_form{
				input{
					width: 497px;
				}
			}
			label{
				display: inline-block;
				vertical-align: middle;
				text-align: right;
				width: 44%;
				margin-right: 9px;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
			}
			input{
				outline: none;
				background:none;
				width: 210px;
				font-weight: 300;
				height: 32px;
				border-radius: 2px;
				padding: 0 10px;
				border: 1px solid #bec7d1;
			}
			&.file_load{
				margin: 20px 0;
				position: relative;
			}
			.user_photo_load{
				display: inline-block;
				vertical-align: middle;
				width: 100px;
				height: 100px;
				border-radius: 100%;
				// overflow: hidden;
				margin-right: 17px;
				position: relative;
				& + div{
					display: inline-block;
					vertical-align: middle;
					position: relative;
				}
				.delete{
					position: absolute;
					top: 15px;
					left: 121px;
					z-index: 10;
					font-size: 12px;
					color: $color_grey;
				}
			}
			.jInputFile-customFile{
				margin-left: 0;
			}
			.jInputFile-activeBrowseButton{
				display: none !important;
			}
			.jInputFile-fakeButton{
				position: relative;
				top: auto;
				left: auto;
				bottom: auto;
				right: auto;
				margin: 0 0 10px;
				outline: none;
				background:none;
				border:1px solid #00b335;
				border-radius: 2px;
				height: 32px;
				line-height: 32px;
				padding: 0 25px;
				width: auto;
				display: inline-block;
				vertical-align: middle;
				color:$color_green;
				cursor: pointer;
				transition: .3s;
				&:hover{
					opacity: .7;
				}
			}
			.jInputFile-fileName{
				position: static;
				margin: 0 auto;
				height: auto;
				font-size: 12px;
				line-height: 16px;
				text-decoration: underline;
				color:$color_grey;
				margin-bottom: 10px;
			}
		}
		.form_actions{
			margin-left: 9px;
			margin-top: 19px;
			input{
				outline: none;
				margin-left: 45%;
				border: none;
				background: #32ab2b;
				height: 48px;
				width: 210px;
				color:#fff;
				border-radius: 2px;
				font-size: 24px;
			}
		}
	}
	.view_company_category{
		margin-bottom: 30px;
		.views_title{
			display: inline-block;
			vertical-align: middle;
			width: 60%;
		}
		.category_menu{
			border-bottom: 0;
			padding-bottom: 0;
			.add_company{
				margin-top: 0;
			}
		}
		.col {
			.title{
				margin-bottom: 11px;
			}
			.phone{
				margin-bottom: 6px;
			}
			.address{
				background-position: 2px 5px;
			}
			&.col_image{
				.btns_wrapper{
					min-height: 135px;
				}
			}
			&.vip {				
				.btns_wrapper {
					.btn{
						bottom: 14px;
					}
					.edit_btn{
						top: auto;
						margin-top: 0;
						bottom: -9px;
					}
				}
			}
		}
		.edit_btn{
			float: right;
			width: 130px;
			text-align: left;
			clear: both;
			position: absolute;
			top: 50%;
			margin-top: 20px;
			a{
				padding-left: 37px;
				background: url(images/edit_icon.png) no-repeat  scroll center left 18px transparent;
				font-weight: 300;
				display: block;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	.view_user_score{
		.bal_title{
			font-size: 24px;
			margin-bottom: 6px;
		}
		.top_wrap{
			padding-bottom: 29px;
			// overflow: hidden;
			position: relative;
			margin-bottom: 20px;
			border-bottom: 1px solid #eaeaea;
			&::after{
				content: '';
				clear: both;
				display: table;
			}
			.balance{
				width: 185px;
				float: left;
				padding-right: 30px;
			}
			
			.count{
				color: #32ab00;
				font-size: 24px;
				line-height: 30px;
				font-weight: 900;
			}
			.rigth_wrap{
				overflow: hidden;
				float: right;
				.form_item{
					display: inline-block;
					vertical-align: top;
					margin-right: 21px;
					font-size: 16px;
					label{
						font-weight: 300;
						display: block;
						margin-bottom: 6px;
					}
					input{
						font-weight: 300;
						border: none;
						outline: none;
						height: 32px;
						font-size: 14px;
						background:none;
						border:1px solid #bdc6cf;
						border-radius: 2px;
						width: 118px;
						padding: 0 10px;
					}
					select {
					  width: 240px;
					  height: 32px;
					  outline: none;
					  border: none;
					  font-weight: 300;
					  border-radius: 0px;
					  padding: 0 30px 0 10px;
					  border:1px solid #bdc6cf;
					  -webkit-appearance: none;
					  background:url(images/select.png) no-repeat center right 9px,
					  				url(images/select_bg.png) repeat-x center;
					  line-height: 1em;
					  /* for FF */
					  -moz-appearance: none;
					  text-indent: 0.01px; 
					  text-overflow: '';
					  /* for IE */
					  -ms-appearance: none;
					  appearance: none!important;
					  &::-ms-expand {
						  display: none;
						}
					}
				}
				.form_actions{
					display: inline-block;
					vertical-align: bottom;
					input{
						border: none;
						outline: none;
						background:none;
						height: 32px;
						border-radius: 2px;
						border:1px solid #00b335;
						padding: 0 27px;
						font-size: 15px;
						color:#00b335;
						line-height: 30px;
						transition: .3s;
						&:hover{
							opacity: .7;
						}
					}
				}
			}
		}
		.bottom_wrap{
			.bal_title{
				margin-bottom: 10px;
			}
		}
		table{
			width: 100%;
			border: none;
			text-align: left;
			font-weight: 300;
			margin-bottom: 25px;
			th{
				color: #89919e;
				font-size: 11px;
				line-height: 20px;
				text-transform: uppercase;
				font-weight: 300;
				height: 38px;
			}
			tbody{
				tr{
					height: 48px;
					// box-shadow: inset 0 -1px 0 #e6e7e9, inset 0 1px 0 #e6e7e9;
					border-bottom: 1px solid #e5e6e8;
					transition: .15s;
					&:nth-child(odd) {
						background:#fbfcfe;
					}
					&:first-child{
						border-top: 1px solid #e5e6e8;
					}
					&:not(:first-child):hover{
						background:#f1f4f9;
					}
					td{
						font-size: 16px;
						line-height: 20px;
						padding-right: 15px;
						&:first-child{
							padding-left: 20px;
						}
					}
				}
			}
		}
	}
	.view_user_messages{
		.top_wrap{
			overflow: hidden;
			margin: 0 0 25px;
		}
		.bal_title{
			font-size: 24px;
			margin: 0 20px 0px 0;
			float: left;			
		}
		.view_header_btn{
			float: right;
			height: 32px;
			line-height: 30px;
			border-radius: 2px;
			padding: 0 29px;
			cursor: pointer;
			color:$color_green4;
			border:1px solid $color_green4;
			transition: .3s;
			&:hover{
				opacity: .7;
			}
		}
		.messages_wrap{
			position: relative;
			border-bottom: 1px solid #e6e7e9;
			padding-bottom: 30px;
			margin-bottom: 30px;
			&::after{
				content: '';
				clear: both;
				display: table;
			}
			.left_wrap{
				float: left;
				width: 270px;
				margin-right: -1px;
				.item_row{
					border: 1px solid #e6e7e9;
					border-bottom: 0;
					padding: 12px 14px 14px 14px;
					background: #fbfcfe;
					cursor: pointer;
					transition: .3s;
					&:hover{
						background:#fff;
					}
					&:last-child{						
						border-bottom: 1px solid #e6e7e9;
					}
					&.new:not(.active){
						position: relative;
						&::after{
							content: '';
							position: absolute;
							top: 50%;
							left: -5.5px;
							width: 11px;
							height: 11px;
							background: #00b319;
							border-radius: 100%;
							margin-top: -5.5px;
						}
					}
					&.active{
						background:#fff;
						border:none;
						border-top: 1px solid #e6e7e9;
					}
					.user_photo{
						width: 45px;
						height: 45px;
						border-radius: 100%;
						overflow: hidden;
						float: left;
						margin-right: 13px;
					}
					.user_info_wrap{
						overflow: hidden;
						padding: 4px 0 0 0;						
						.user_name{
							font-size: 16px;
							line-height: 20px;
							margin-bottom: 3px;
						}
						.date{
							color: #89919e;
							font-size: 12px;
							line-height: 16px;
						}
					}
				}
			}
			.messages_block{
				overflow: hidden;
				.messages_block_inner{
					padding: 0 36px 0 19px;
					margin-bottom: 19px;
					height: 365px;
					.messages_row{
						max-width: 90.15%;
						float: left;
						font-weight: 300;
						border: 1px solid #e6e7e9;
						padding: 14px 17px 21px 19px;
						margin-bottom: 11px;
						&:nth-child(even) {
							float: right;
						}
						.name{
							font-size: 16px;
							line-height: 20px;
							float: left;
							margin-right: 20px;
							margin-bottom: 15px;
						}
						.date{
							float: right;
							font-size: 12px;
							line-height: 16px;
							margin-bottom: 15px;
							font-weight: 400;
							padding-right: 8px;
							color:$color_grey;
						}
						.messages_text{
							clear: both;
							line-height: 20px;
						}
					}
				}
				.messages_block_bottom{
					border: 1px solid #e6e7e9;
					background: #fbfcfe;
					padding: 15px;
					form{
						position: relative;
						textarea{
							width: 100%;
							height: 83px;
							resize: none;
							font-size: 16px;
							font-weight: 300;
							padding: 7px 45px 7px 10px;
							border:1px solid #e3e8ed;
						}
						.form_actions{
							input{
								border: none;
								outline: none;
								background: url(images/send.png) no-repeat  scroll 0 0 transparent;
								width: 20px;
								height: 18px;
								text-indent: -9999px;
								overflow: hidden;
								position: absolute;
								bottom: 20px;
								right: 11px;
								transition: .3s;
							}
						}
					}
				}
			}
		}
	}
	.view_user_announcements,
	.view_user_favorites {
		.title_block{
			margin-bottom: 5px;
		}
		.add_announcements{
			float: right;
			a{
				display: block;
				text-decoration: none;
				cursor: pointer;
				background:#32ab2b;
				color:#fff;
				padding: 0 19px;
				border-radius: 2px;
				height: 32px;
				line-height: 32px;
				transition: .3s;
				&:hover{
					opacity: .7;
				}
			}
		}
	}
	.view_user_announcements {
		.top_wrap{
			overflow: hidden;
			margin-bottom: 8px;
			.title_block{
				float: left;
				width: 70%;
			}
		}
	}
	.views_favorites,
	.views_announcements{
		margin-bottom: 20px;
		clear: both;
		.top_wrap{
			position: relative;
			overflow: visible;
			margin-bottom: 23px;
			&::after{
				content: '';
				clear: both;
				display: table;
			}
		}
		.views_row{
			padding: 20px;
			overflow: hidden;
			margin-bottom: 3px;
			background:url(images/base_bg.png) repeat scroll 0 0 transparent;
			.image_wrap{
				float: left;
				width: 126px;
				// height: 86px;
				position: relative;
				margin-right: 15px;
				img{
					display: block;
					margin: auto;
				}
				.vip{
					position: absolute;
					top: 0;
					font-size: 12px;
					line-height: 19px;
					height: 19px;
					padding: 0 7px;
					z-index: 1;
					left: -5px;
					text-transform: uppercase;
					color:#fff;
					background:#d42e30;
					&::after{
						content: '';
						position: absolute;
						left: 0;
						bottom: -5px;						
						border: 2.5px solid transparent;	
						border-top: 2.5px solid #911b1d; 
						border-right: 2.5px solid #911b1d;
					}
				}
			}
			.center_wrap{
				overflow: hidden;
				float: left;
				width: 212px;
				margin-right: 29px;
				.date{
					font-size: 12px;
					line-height: 16px;
					color:$color_grey;
					margin-bottom: 8px;
				}
				.title{
					a{
						display: block;
						text-decoration: none;
						font-size: 18px;
						font-weight: 700;
						line-height: 24px;
						transition: .3s;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
			.right_wrap{
				float: right;
				margin-left: 30px;
				white-space: nowrap;
				.price{
					color:$color_green3;
					font-size: 24px;
					margin-bottom: 10px;
				}
				.stat{
					font-size: 12px;
					line-height: 16px;
					color:$color_grey;
					padding-left: 20px;
					margin-left: 2px;
					background: url(images/stat.png) no-repeat  scroll center left 0 transparent;
					margin-bottom: 11px;
				}
				.favorites_btn {					
					a{
						background: url(images/star.png) no-repeat  scroll center transparent;
						-webkit-background-size: contain;
						background-size: contain;
						width: 16px;
						display: block;
						height: 16px;
						text-indent: -9999px;
						overflow: hidden;
						cursor: pointer;
					}
					&.active a{
						background: url(images/star_active.png) no-repeat  scroll 0 0 transparent;
						-webkit-background-size: contain;
						background-size: contain;
					}
				}
			}
			.body_wrap{
				overflow: hidden;
				font-weight: 300;
				padding-top: 5px;
			}
		}
		.bottom_wrap{
			clear: both;
			margin: 0 -20px;
			overflow: hidden;
			border-top: 1px solid #e5e6e8;
			border-left: 1px solid #e5e6e8;
			border-right: 1px solid #e5e6e8;
			background: url(images/bg_bottom.png) repeat-x scroll 0 0 transparent;
			background-size: contain;
			.field_item{	
				float: left;
				&.edit{
					float: right;
					a{
						color:$color_green3;
						border-left: 1px solid #e5e6e8;
						border-right: 0;
					}
				}		
				a{
					font-size: 12px;
					height: 35px;
					line-height: 35px;
					padding: 0 14px;
					text-decoration: none;
					display: block;
					border-right: 1px solid #e5e6e8;
					transition: .3s;
					&:hover{
						color:#fff;
						border-color:$color_green5;
						background:$color_green5;
					}
				}
			}
		}
	}
	.views_announcements{
		.views_row{
			padding-bottom: 0;
			border-bottom: 1px solid #e6e7e9;
		}		
	}
}
.page_user.page_user_announcements .block_user_menu .block_content ul li:first-child a{
	border-right: 0;
}
.page_user_messages{
	.container{
		overflow: visible;
	}
}
.block_support{
	border-radius: 2px;
	border: 1px solid #e6e7e9;
	background: #fbfcfe;
	padding: 12px 20px 19px;
	margin: 0 0 28px;
	.block_title{
		font-size: 24px;
		margin-bottom: 15px;
	}
	.view_content{
		padding: 0 0 0 80px;
		background: url(images/support.png) no-repeat  scroll 0 0 transparent;
		.user_name{
			font-size: 16px;
			margin-bottom: 10px;
			font-weight: 300;
			line-height: 1;
		}
		a{
			color: #89919e;
			text-decoration: none;
			font-size: 12px;
			line-height: 16px;
		}
	}
}
.block_weather{
	margin-bottom: 20px;
	overflow: hidden;
	.block_title{
		font-size: 24px;
		margin: 0 0 20px;
	}
	.view_content{
		.field_item{
			width: 25%;
			float: left;
			text-align: center;
			padding: 0 15px 13px 0;
			&:last-child{
				float: right;
				padding-right: 0;
			}
			.label{
				font-size: 16px;
				line-height: 20px;
				font-weight: 300;
				margin-bottom: 5px;
			}
			.icon{
				height: 32px;
				line-height: 32px;
				text-align: center;
				margin: 0 0 5px;
				img{
					display:inline;
					vertical-align: middle;
				}
			}
			.temperature{				
				font-size: 18px;
				line-height: 24px;
				margin: 0 0 13px;
				text-align: center;
			}
			.wind{
				font-size: 12px;
				color:$color_grey;
				text-align: center;
			}
		}
	}
}
.middle_content{	
	.vip_notice {
		.block_content{
			margin: 0 -12px;
		}
		.col{
			display: inline-block;
			vertical-align: top;
			width: 260px;
			margin: 0 11px;
			.image{
				height: 145px;
				overflow: hidden;
				border-radius: 2px;
				margin-bottom: 5px;
				img{
					max-width: none;
					height: 100%;
					display: block;
					margin: auto;
				}
			}
		}
	}
}
.page_user_score .block_user_menu{
	margin-bottom: 20px;
}
/*----------------------------------------------------------------------------
									LEFT SIDEBAR
----------------------------------------------------------------------------*/
@import 'includes/_left_sidebar';
/*----------------------------------------------------------------------------
									RIGHT SIDEBAR
----------------------------------------------------------------------------*/
@import 'includes/_right_sidebar';
/*----------------------------------------------------------------------------
									FOOTER
----------------------------------------------------------------------------*/
@import 'includes/_footer';