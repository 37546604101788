.front{
	.middle{
		padding-top: 23px;
	}
	.block_views_news_1{
		width: 100%;
		padding: 0;
		margin-bottom: 30px;
		border-bottom: none;
		padding-top: 2px;
		.col_group_1{
			margin: 0;
			.col{
				width: 100%;
				padding-right: 0;
				img{
					display: block;
				}
				.title_wrap{
					background: linear-gradient(180deg, rgba(0, 37, 50, 0) 0%, rgba(1, 18, 34, 0.52) 100%);
					padding-left: 45px;
					padding-right: 45px;
				}
			}
			.image_wrapper{
				margin-bottom: 3px;
			}
		}
	}
	.other_news{
		.description{
			font-weight: 300;
		}
	}
	.block_news_large{
		margin-bottom: 25px;
		img{
			display: block;
		}
		.image{
			margin-bottom: 18px;
		}
		.info_wrap{
			font-size: 12px;
			color: $color_grey;
			margin-bottom: 10px;
			&::after{
				content: '';
				display: table;
				clear: both;
			}
			.category{
				float: left;
				a{
					text-decoration: none;
					color: $color_grey;
					transition: .3s;
					&:hover{
						color: $color_green2;
						text-decoration: underline;
					}
				}
			}
			.date{
				float: left;
				margin-left: 25px;
				position: relative;
				&::before{
					content: '';
					display: block;
					width: 7px;
					height: 7px;
					background: #bec7d1;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					margin-top: -3.5px;
					left: -15px;
				}
			}
		}
		.title{
			a{
				text-decoration: none;
				font-size: 24px;
				font-weight: 700;
				transition: .3s;
				line-height: 1.2;
				display: block;
				margin-bottom: 15px;
				&:hover{
					text-decoration: underline;
					color: $color_green2;
				}
			}
		}
		.description{
			font-weight: 300;
		}
	}
	.vip_notice{
		.col{
			margin-bottom: 21px;
		}
		 .image img{
		 	margin-bottom: 5px;
		 }
		.title{
			a{
				margin-bottom: 0;
			}
		}
	}
}
.block_journal{
	padding-top: 21px;
	border-top: 1px solid #eaeaea;
	.block_title{
		font-size: 24px;
		margin-bottom: 20px;
		text-align: center;
	}
	.block_content{
		font-size: 0;
		margin-right: -25px;
		.col_left{
			font-size: 14px;
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding-right: 23px;
		}
		.col_right{
			font-size: 14px;
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding-right: 23px;
		}
		.row{
			margin-bottom: 24px;
		}
		.title{
			a{
				text-decoration: none;
				font-size: 16px;
				font-weight: 300;
				transition: .3s;
				display: block;
				margin-bottom: 7px;
				&:hover{
					color: $color_green2;
					text-decoration: underline;
				}
			}
		}
		.date{
			font-size: 12px;
			color: $color_grey;
		}
	}
}
.block_title.line{
	position: relative;
	text-align: center;
	margin-bottom: 22px;
	&::after{
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		top: 50%;
		left: 0;
		background: #eaeaea;
		position: absolute;
	}
	span{
		font-size: 36px;
		padding: 0 25px;
		background: #fff;
		position: relative;
		z-index: 1;
	}
}
.block_accident{
	margin-bottom: 26px;
	.block_content{
		max-width: 1170px;
		margin: 0 auto;
	}
	.view_content{
		margin-right: -23px;
		font-size: 0;
		.col{
			font-size: 14px;
			display: inline-block;
			vertical-align: top;
			width: 25%;
			padding-right: 23px;
			.col_wrap{
				border: 1px solid #eaeaea;
				border-radius: 2px;
				overflow: hidden;
				padding: 0 19px 13px;
			}
		}
		.image{
			border-bottom: 1px solid #eaeaea;
			margin-bottom: 15px;
			margin-right: -19px;
			margin-left: -19px;
			img{
				display: block;
			}
		}
		.title{
			font-size: 16px;
			font-weight: 300;
			a{
				text-decoration: none;
				transition: .3s;
				&:hover{
					text-decoration: underline;
					color: $color_green2;
				}
			}
		}
		.count_view{
			font-size: 12px;
			padding-left: 20px;
			background: url(images/eye_icon.svg) no-repeat scroll 0 3px transparent;
			color: $color_grey;
			margin-bottom: 9px;
		}
	}
}
.block_photos{
	margin-bottom: 30px;
	.block_content{
		max-width: 1170px;
		margin: 0 auto;
	}
	.view_content{
		margin-right: -30px;
		font-size: 0;
		.col{
			font-size: 14px;
			display: inline-block;
			vertical-align: top;
			width: 33.3%;
			padding-right: 30px;
		}
	}
	.col_wrap{
		position: relative;
		transition: .3s;
		&::after{
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: .3s;
			background: linear-gradient(180deg, rgba(0, 36, 49, 0.08) 0%, rgba(1, 18, 34, 0.40) 100%);
		}
		&:hover{
			.count_view{
				opacity: 1;
			}
			&::after{
				// background: linear-gradient(180deg, rgba(0, 36, 49, 0.2) 0%, rgba(1, 18, 34, 0.67) 100%);
				background: rgba(1, 18, 34, 0.57);
			}
		}
	}
	.image{
		img{
			display: block;
			border-radius: 2px;
		}
	}
	.title_wrap{
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		z-index: 10;
		color: #fff;
		height: 155px;
		padding: 65px 25px 0;
		z-index: 1;
		a{
			color: #fff;
		}
	}
	.col_info{
		display: table;	
		margin: 0 auto 12px;
		font-size: 0;
		.category{
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			padding-right: 24px;
			position: relative;
			a{
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
			&::after{
				content: '';
				display: block;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: rgba(255,255,255,.4);
				position: absolute;
				top: 50%;
				margin-top: -3.5px;
				right: 8px;
			}

		}
		.date{ 
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			padding-right: 24px;
			position: relative;
			&::after{
				content: '';
				display: block;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: rgba(255,255,255,.4);
				position: absolute;
				top: 50%;
				margin-top: -3.5px;
				right: 8px;
			}

		}
	}
	.title{
		text-align: center;
		height: 45px;
		overflow: hidden;
		a{
			font-size: 18px;
			text-decoration: none;
			font-weight: 700;
			&:hover{
			text-decoration: underline;
			}
		}
	}
	.count_view{
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		padding-left: 20px;
		background: url(images/eye_icon2.svg) no-repeat  scroll 0 4px transparent;
		position: absolute;
		top: 17px;
		right: 17px;
		z-index: 1;
		color: #fff;
		opacity: 0;
		transition: .3s;
	}
}
.block_poster{
	margin-bottom: 20px;
	.block_content{
		max-width: 1170px;
		margin: 0 auto;
	}
	.view_content{
		margin: 0 -13px;
	}
	.col{
		margin: 0 13px;
	}
	button{
		width: 36px;
		height: 36px;
		text-indent: -9999px;
		border: 1px solid $color_grey;
		position: absolute;
		background-color: #fff;
		border-radius: 50%;
		top: 110px;
		z-index: 1;
		transition: .3s;
		&.slick-prev{
			left: -54px;
			background: url(images/slider_prev.png) no-repeat  scroll 13px center #fff;
			&:hover{
				background: url(images/slider_prev2.png) no-repeat  scroll 13px center #fff;
				border: 1px solid #09a927;
			}
		}
		&.slick-next{
			right: -54px;
			background: url(images/slider_next.png) no-repeat  scroll 15px center #fff;
			&:hover{
				background: url(images/slider_next2.png) no-repeat  scroll 15px center #fff;
				border: 1px solid #09a927;
			}
		}
	}
	.image{
		margin-bottom: 12px;
	}
	.date{
		font-size: 11px;
		text-transform: uppercase;
		border-radius: 2px;
		border: 1px solid #bac5d6;
		padding: 0 9px;
		display: table;
		margin: 0 auto;
		padding-top: 3px;
		padding-bottom: 1px;
		margin-bottom: 13px;
	}
	.place{
		color: $color_grey;
		font-size: 12px;
		margin-bottom: 7px;
		text-align: center;
	}
	.title{
		font-size: 18px;
		text-align: center;
		a{
			text-decoration: none;
			transition: .3s;
			&:hover{
				color: $color_green2;
				text-decoration: underline;
			}
		}
	}
}
.block_catalog_company{
	background: #fbfcfe;
	border-top: 1px solid #e7e8e9;
	padding-top: 34px;
	.block_content{
		max-width: 1170px;
		margin: 0 auto;
	}
	.block_catalog{
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.link_image{
			float: right;
			margin-left: 60px;
			margin-right: 8px;
		}
		.block_container{
			overflow: hidden;
		}
		.block_top{
			margin-bottom: 27px;
			&::after{
				content: '';
				display: table;
				clear: both;
			}
			.block_title{
				float: left;
				font-size: 24px;
				line-height: 32px;
				margin-right: 30px;
			}
			.add_company{
				float: left;
				border: none;
			    background: url(images/plus.png) no-repeat scroll 13px center $color_green;
				color: #fff;
			}
		}
		.block_menu{
			font-size: 0;
		}
		.menu_wrap{	
			font-size: 16px;
			display: inline-block;
			margin-right: 55px;
			vertical-align: top;
			&:last-child{
				margin-right: 0;
			}
			.item{
				margin-bottom: 21px;
			}
			a{
				display: block;
				padding-left: 26px;
				text-decoration: none;
				span{
					font-size: 12px;
					color: $color_grey;
					padding-left: 10px;
				}
				&:hover{
					color: $color_green2;
					text-decoration: underline;
				}
			}
			@for $i from 1 through 17 {
				.item_#{$i} {
					a{
						background: url('images/category_ic_'+$i+'.svg') no-repeat  scroll 0 center transparent;
					}
				}
			}
			  .item_1{
			  	a{
			  		&:hover{
						background: url('images/company_icon_1.svg') no-repeat  scroll 0 center transparent;
						background-size: 20px;
			  		}
			  	}
			  }
			.item_2{
				a{
					&:hover{
						background: url('images/company_icon_2.svg') no-repeat  scroll 0 center transparent;
						background-size: 18px;
					}
				}
			}
			.item_3{
				a{
					&:hover{
						background: url('images/company_icon_3.svg') no-repeat  scroll 0 center transparent;
						background-size: 19px;
					}
				}
			}
			.item_4{
				a{
					&:hover{
						background: url('images/company_icon_4.svg') no-repeat  scroll 0 center transparent;
						background-size: 20px;
					}
				}
			}
			.item_5{
				a{
					&:hover{
						background: url('images/company_icon_5.svg') no-repeat  scroll 0 center transparent;
						background-size: 19px;
					}
				}
			}
			.item_6{
				a{
					&:hover{
						background: url('images/company_icon_6.svg') no-repeat  scroll 0 center transparent;
						background-size: 19px;
					}
				}
			}
			.item_7{
				a{
					&:hover{
						background: url('images/company_icon_7.svg') no-repeat  scroll 0 center transparent;
						background-size: 20px;
					}
				}
			}
			.item_8{
				a{
					&:hover{
						background: url('images/company_icon_8.svg') no-repeat  scroll 0 center transparent;
						background-size: 19px;
					}
				}
			}
			.item_9{
				a{
					&:hover{
						background: url('images/company_icon_9.svg') no-repeat  scroll 0 center transparent;
						background-size: 15px;
					}
				}
			}
			.item_10{
				a{
					&:hover{
						background: url('images/company_icon_11.svg') no-repeat  scroll 0 center transparent;
						background-size: 19px;
					}
				}
			}
			.item_11{
				a{
					&:hover{
						background: url('images/company_icon_12.svg') no-repeat  scroll 0 center transparent;
						background-size: 16px;
					}
				}
			}
			.item_12{
				a{
					&:hover{
						background: url('images/company_icon_13.svg') no-repeat  scroll 0 center transparent;
						background-size: 15px;
					}
				}
			}
			.item_13{
				a{
					&:hover{
						background: url('images/company_icon_14.svg') no-repeat  scroll 0 center transparent;
						background-size: 18px;
					}
				}
			}
			.item_14{
				a{
					&:hover{
						background: url('images/company_icon_15.svg') no-repeat  scroll 0 center transparent;
						background-size: 20px;
					}
				}
			}
			.item_15{
				a{
					&:hover{
						background: url('images/company_icon_16.svg') no-repeat  scroll 0 center transparent;
						background-size: 19px;
					}
				}
			}
			.item_16{
				a{
					&:hover{
						background: url('images/company_icon_10.svg') no-repeat  scroll 0 center transparent;
						background-size: 18px;
					}
				}
			}
			.item_17{
				a{
					&:hover{
						background: url('images/company_icon_17.svg') no-repeat  scroll 0 center transparent;
						background-size: 20px;
					}
				}
			}
		}
	}
	.block_company{
		padding-top: 12px;
		margin-bottom: 35px;
		.block_title{
			font-size: 24px;
			margin-bottom: 24px;
		}
		.view_content{
			margin-right: -23px;
			font-size: 0;
		}
		.col{
			width: 16.6%;
			padding-right: 20px;
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			font-weight: 300;
		}
		.image{
			width: 100%;
			height: 83px;
			background: #fff;
			display: table;
			border: 1px solid #e6e8e9;
			border-radius: 2px;
			margin-bottom: 10px;
			a{
				display: table-cell;
				vertical-align: middle;
				img{
					display: block;
					margin: 0 auto;
				}
			}
		}
		.title{
			text-align: center;
			line-height: 1.25;
			a{
				text-decoration: none;
				transition: .3s;
				&:hover{
					text-decoration: underline;
					color: $color_green2;
				}
			}
		}
	}
}
.block_catalog_company .block_catalog .menu_wrap .item_15 a{
	background-position: 3px center;
}