.page_add_notice .top_inner .block_content{
	margin-bottom: 22px;
}
.add_notice_wrapper{
	.description{
		font-size: 16px;
		font-weight: 300;
		padding-bottom: 26px;
		margin-bottom: 20px;
		border-bottom: 1px solid #eaeaea;
		span{
			color: #c30016;
		}
	}
	.group_item{
		font-size: 0;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #eaeaea;
		&+.group_item{
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
		.group_label{
			display: inline-block;
			vertical-align: top;
			font-size: 18px;
			font-weight: 700;
			width: 22.5%;
			padding-right: 1%;
		}
		.group_content{
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			padding-top: 5px;
			font-weight: 300;
		}
		.form_item{
			font-size: 0;
			margin-bottom: 10px;
			&.title_item{
				input{
					width: 497px;
				}
			}
			label{
				width: 145px;
				display: inline-block;
				vertical-align: middle;
				padding-right: 10px;
				text-align: right;
				font-size: 16px;
				font-weight: 300;
			}
			input{
				font-size: 16px;
				display: inline-block;
				vertical-align: middle;
				height: 32px;
				width: 210px;
				font-weight: 300;
			}
			select{
				font-size: 16px;
				appearance:none;
				height: 32px;
				background: url(images/select.png) no-repeat  scroll right 10px center,
							linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 100%);
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), inset 0 2px 1px rgba(255, 255, 255, 0.3);
				border: 1px solid #bec7d1;
				width: 210px;
				padding-left: 11px;
				padding-right: 15px;
				cursor: pointer;
			}
			textarea{
				width: 495px;
				height: 100px;
				resize: none;
			    border: 1px solid #bec7d1;
			    display: inline-block;
			    vertical-align: top;
			    font-size: 16px;
			    padding: 10px;
			    font-weight: 300;
			}
		}
	}
	.price_item{
		input{
			width: 86px !important;
			border-right: none;
		}
		select{
			width: 62px !important;
			vertical-align: middle;
			display: inline-block;
		}
	}
	.jInputFile-fakeButton {
		position: relative;
		width: 302px;
		height: 145px;
		background: url(images/upload_photo.png) no-repeat  scroll center 42px,
					url(images/upload_bg.png) repeat  scroll 0 0;
					cursor: pointer;
		padding-top: 90px !important;
	}
	.jInputFile-activeBrowseButton{
		display: none !important;
	}
	.file_load{
		margin-left: 145px;
		&>div{
			height: 145px !important;
			position: relative;
			font-size: 16px;
			text-align: center;
			&:first-child{
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			}
			input{
				width: 100% !important;
				position: absolute;
				top: 0;
				left: 0;
				height: 100% !important;
				margin-left: 0 !important;
				cursor: pointer;
			}
		}
	}
	.g-recaptcha{
		margin-left: 145px;
		margin-bottom: 15px;
	}
	.form_actions{
		padding-left: 145px;
		margin-bottom: 28px;
		.form_submit{
			border: none;
			background: #32ab2b;
			height: 48px;
			width: 302px;
			text-align: center;
			color: #fff;
			border-radius: 2px;
			font-size: 24px;
		}
	}
}
.view_notice{
	.link_image{
		img{
			margin: 25px auto 20px;
			display: block;
		}
	}
	.view_header{
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.block_search{
			margin-bottom: 30px;
			form{
				font-size: 0;
				*{
					display: inline-block;
					font-size: 16px;
					font-weight: 300;
					vertical-align: top;
					height: 48px;
				}
				.form_text{
					width: 68.5%;
					border-radius: 2px 0 0 2px;
					border-right: none;
					box-shadow: none !important;
				}
				select{
					font-size: 16px;
					appearance:none;
					background: url(images/select.png) no-repeat  scroll right 10px center #fff;
					border: 1px solid #bec7d1;
					width: 210px;
					padding-left: 11px;
					padding-right: 15px;
					cursor: pointer;
					border-right: none;
				}
				.form_submit{
					width: 50px;
					background: url(images/search_icon2.png) no-repeat  scroll center transparent;
					border: 1px solid #bec7d1;
					text-indent: -9999px;
					transition: .3s;
					border-radius: 0 2px 2px 0;
					&:hover{
						opacity: 0.6;
					}
				}
			}
		}
		.category_wrapper{
			font-size: 0;
			margin-right: -90px;
			position: relative;
			.item{
				display: inline-block;
				vertical-align: top;
				width: 33.3%;
				padding-right: 90px;
				font-weight: 300;
				font-size: 0;
				margin-bottom: 25px;
				.image{
					display: inline-block;
					vertical-align: middle;
					margin-right: 8px;
					width: 20px;
					img{
						display: block;
						margin: 0 auto;
					}
				}
				a{
					display: inline-block;
					vertical-align: middle;
					font-size: 16px;
					text-decoration: none;
					transition: .3s;
					&:hover{
						color: $color_green2;
						text-decoration: underline;
					}
				}
				span{
					font-size: 12px;
					font-weight: 400;
					color: $color_grey;
					padding-left: 11px;
					position: relative;
					top: 5px;
				}
			}
		}
		.add_notice{
			line-height: 32px;
		    border-radius: 2px;
		    padding: 0 15px 0 35px;
		    background: url(images/plus.png) no-repeat scroll 13px center #09a927;
		    color: #fff;
		    cursor: pointer;
		    transition: .3s;
		    margin-bottom: 14px;
		    display: inline-block;
		    font-size: 14px;
		    float: right;
		    margin-top: -50px;
		    position: relative;
		    z-index: 2;
		    cursor: pointer;
		    transition: .3s;
		    &:hover{
		    	opacity: .8;
		    }
		}
	}
	.view_content{
		.view_title{
			font-size: 24px;
			padding: 23px 0 11px;
		}
		.views_row{
			padding: 20px;
			overflow: hidden;
			background:url(images/base_bg.png) repeat scroll 0 0 transparent;
			margin-bottom: 1px;
			&.vip{
				background: #f6e8dd;
			}
			&.selected{
				background: #f3f2dc;
			}
			.image_wrap{
				float: left;
				width: 126px;
				position: relative;
				margin-right: 15px;
				img{
					display: block;
					margin: auto;
				}
				.vip{
					position: absolute;
					top: 0;
					font-size: 12px;
					line-height: 19px;
					height: 19px;
					padding: 0 7px;
					z-index: 1;
					left: -5px;
					text-transform: uppercase;
					color:#fff;
					background:#d42e30;
					&::after{
						content: '';
						position: absolute;
						left: 0;
						bottom: -5px;						
						border: 2.5px solid transparent;	
						border-top: 2.5px solid #911b1d; 
						border-right: 2.5px solid #911b1d;
					}
				}
			}
			.center_wrap{
				overflow: hidden;
				float: left;
				width: 212px;
				margin-right: 29px;
				.date{
					font-size: 12px;
					line-height: 16px;
					color:$color_grey;
					margin-bottom: 8px;
				}
				.title{
					a{
						display: block;
						text-decoration: none;
						font-size: 18px;
						font-weight: 700;
						line-height: 24px;
						transition: .3s;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
			.right_wrap{
				float: right;
				margin-left: 30px;
				white-space: nowrap;
				.price{
					color:$color_green2;
					font-size: 24px;
					margin-bottom: 10px;
				}
				.stat{
					font-size: 12px;
					line-height: 16px;
					color:$color_grey;
					padding-left: 20px;
					margin-left: 2px;
					background: url(images/stat.png) no-repeat  scroll center left 0 transparent;
					margin-bottom: 11px;
				}
				.favorites_btn {					
					a{
						background: url(images/star.png) no-repeat  scroll center transparent;
						-webkit-background-size: contain;
						background-size: contain;
						width: 16px;
						display: block;
						height: 16px;
						text-indent: -9999px;
						overflow: hidden;
						cursor: pointer;
					}
					&.active a{
						background: url(images/star_active.png) no-repeat  scroll 0 0 transparent;
						-webkit-background-size: contain;
						background-size: contain;
					}
				}
			}
			.body_wrap{
				overflow: hidden;
				font-weight: 300;
				padding-top: 5px;
			}
		}
	}
	.page_navigation{
		border-top: none;
		padding-bottom: 10px;
	}
}
.view_notice_category{
	.view_header{
		.category_wrapper{
			.item{
				margin-bottom: 13px;
			}
		}
	}
	.view_top{
		margin-bottom: 10px;
		&::after{
			content: '';
			display: table;
			clear: both;
		}
	}
	.view_title{
		float: left;
		max-width: 70%;
	}
	.add_notice{
		line-height: 32px;
	    border-radius: 2px;
	    padding: 0 15px 0 35px;
	    background: url(images/plus.png) no-repeat scroll 13px center #09a927;
	    color: #fff;
	    cursor: pointer;
	    transition: .3s;
	    margin-bottom: 14px;
	    display: inline-block;
	    font-size: 14px;
	    float: right;
	    position: relative;
	    float: right;
	    z-index: 2;
	    cursor: pointer;
	    transition: .3s;
	    margin-top: 10px;
	    &:hover{
	    	opacity: .8;
	    }
	}
}
.node_notice{
	.node_title{
		font-size: 24px;
		font-weight: 400;
		line-height: 1;
		margin-top: 0;
		margin-bottom: 20px;
	}
	.image_wrapper{
		margin-bottom: 20px;
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.image{
			float: left;
			margin-right: 25px;
			img{
				display: block;
			}
		}
		.info_wrapper{
			overflow: hidden;
		}
		.date{
			font-size: 16px;
			font-weight: 300;
			margin-bottom: 15px;
		}
		.count_view{
			padding-left: 20px;
			background: url(images/eye_icon.svg) no-repeat  scroll 0 3px transparent;
			margin-bottom: 18px;
		}
		.add_favorites{
			margin-bottom: 20px;
			a{
				display: block;
				padding-left: 35px;
				background: url(images/fav_icon.png) no-repeat  scroll 0 center transparent;
				line-height: 24px;
				color: $color_green2;
			}
			&.active{
				a{
					background: url(images/fav_icon2.png) no-repeat  scroll 0 center transparent;
				}
			}
		}
		.price{
			color: #00b319;
			font-size: 36px;
			font-weight: 900;
			padding-bottom: 19px;
			margin-bottom: 15px;
			border-bottom: 1px solid #eaeaea;
		}
	}
	.author_wrapper{
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.label{
			font-size: 12px;
			margin-bottom: 10px;
			color: $color_grey;
		}
		.photo{
			float: left;
			margin-right: 12px;
			img{
				border-radius: 50%;
			}
		}
		.user_content{
			overflow: hidden;
			padding-top: 4px;
			.name{
				font-size: 16px;
				font-weight: 300;
				margin-bottom: 4px;
			}
		}
		.write_btn{
			margin-bottom: 11px;
			a{
				text-decoration: none;
				color: $color_green2;
				border: 1px solid #00b131;
				border-radius: 2px;
				line-height: 32px;
				display: inline-block;
				width: 100%;
				text-align: center;
				transition: .3s;
				&:hover{
					background: $color_green2;
					color: #fff;
				}
			}
		}
		.all_notice{
			text-align: right;
			a{
				color: $color_green2;
			}
		}
	}
	.description{
		font-size: 16px;
		font-weight: 300;
		line-height: 22px;
		text-align: justify;
	}
	.social_links{
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.at-resp-share-element .at-share-btn{
			float: left;
			margin-right: 20px;
			clear: none;
			margin-bottom: 20px !important;
			background-color: transparent !important;
			span{
				color: $color_main !important;
				font: 300 16px/2.3 'museosanscyr' !important;
			}
			.at-icon-wrapper{
				width: 22px !important;
				background: url(images/social_1.svg) no-repeat  scroll 0 center transparent !important;
				svg{
					display: none !important;
				}
			}
			&:nth-child(2){
				.at-icon-wrapper{
					background: url(images/social_5.svg) no-repeat  scroll 0 center transparent !important;
				}
			}
			&:nth-child(3){
				.at-icon-wrapper{
					background: url(images/social_6.svg) no-repeat  scroll 0 center transparent !important;
				}
			}
			&:nth-child(4){
				.at-icon-wrapper{
					background: url(images/social_2.svg) no-repeat  scroll 0 center transparent !important;
				}
			}
			&:nth-child(5){
				.at-icon-wrapper{
					background: url(images/social_3.svg) no-repeat  scroll 0 center transparent !important;
				}
			}
			&:nth-child(6){
				.at-icon-wrapper{
					background: url(images/social_4.svg) no-repeat  scroll 0 center transparent !important;
				}
			}
		}
	}
	.view_notice{
		padding-top: 10px;
		border-top: 1px solid #eaeaea;
	}
	.link_image{
		margin: 30px 0;
		img{
			display: block;
			margin: 0 auto;
		}
	}
}