.left_sidebar {
    float: left;
    width: 320px;
    margin-left: -100%;
    position: relative;
    padding-right: 25px;
    border-right: 1px solid #eaeaea;
    .vip_notice{
    	.col{
    		margin-bottom: 22px;
    	}
    }
} 
.block_news_city{
	margin-bottom: 30px;
	.block_title{
		font-size: 24px;
		margin-bottom: 18px;
	}
	.col{
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid #eaeaea;
		&.hot{
			.title{
				a{
					font-weight: 700;
				}
			}
		}
		&.last{
			margin-bottom: 0;
			border-bottom: none;
		}
		&::after{
			content: '';
			display: table;
			clear: both;
		}
	}
	.date{
		width: 12.3%;
		padding-right: 1%;
		float: left;
		font-size: 12px;
		color: $color_grey;
		padding-top: 2px;
		.page_journal &{
			width: 100%;
			padding-top: 5px;
		}
	}
	.title{
		overflow: hidden;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.25;
		a{
			text-decoration: none;
			transition: 0.3s;
			&:hover{
				text-decoration: underline;
				color: $color_green2;
			}
		}
		span{
			font-size: 12px;
			font-weight: 400;
			color: #89929e;
			display: inline-block;
			margin-left: 8px;
			padding: 1px 4px 0 4px;
			border-radius: 2px;
			border: 1px solid #89929e;
			&.selected{
				color: $color_green2;
				border-color: $color_green2;
				padding: 1px 2px 0 1px;
				line-height: 0.9;
			}
			&.important{
				color: #fa8a80;
				border-color: #fa8a80;
			}
		}
	}
	.old_date{
		font-size: 18px;
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid #eaeaea;
		padding-top: 7px;
	}
	.view_more{
		text-align: right;
		border-bottom: 1px solid #eaeaea;
		padding-bottom: 15px;
		a{
			display: inline-block;
			padding-right: 17px;
			color: $color_green2;
			padding-right: 17px;
			background: url(images/all_news.png) no-repeat  scroll right center transparent;
			font-size: 16px;
			font-weight: 300;
		}
	}
}
.block_horoscope{
	margin-bottom: 30px;
	border-bottom: 1px solid #eaeaea;
	.block_title{
		font-size: 24px;
	    margin: 0 0 20px;
	}
	.block_content{
		font-size: 0;
		.col{
			display: inline-block;
			vertical-align: top;
			width: 25%;
			margin-bottom: 20px;
			a{
				display: block;
				text-indent: -9999px;
				height: 36px;
				transition: .3s;
			}
		}
		@for $i from 1 through 17 {
		  .col_#{$i} {
		    a{
		    	background: url('images/horoscope_'+$i+'.svg') no-repeat  scroll 0 center transparent;
		    	&:hover{
		    		background: url('images/horoscope_'+$i+'_2.svg') no-repeat  scroll 0 center transparent;
		    	}
		    }
		  }
		}
	}
}
.block_horoscope .block_content .col_8 a:hover {
    background: url(images/horoscope_8_2.png) no-repeat scroll 0 center transparent !important; 
}