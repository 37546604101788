@charset "UTF-8";
/*----------------------------------------------------------------------------
									FONTS
----------------------------------------------------------------------------*/
@font-face {
  font-family: 'cuprumregular';
  src: url("fonts/cuprum-regular-webfont-webfont.eot");
  src: url("fonts/cuprum-regular-webfont-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/cuprum-regular-webfont-webfont.woff") format("woff"), url("fonts/cuprum-regular-webfont-webfont.ttf") format("truetype"), url("fonts/cuprum-regular-webfont-webfont.svg#_.regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'museosanscyr';
  src: url("fonts/MuseoSansCyrl300.eot");
  src: local("☺"), url("fonts/MuseoSansCyrl300.woff") format("woff"), url("fonts/MuseoSansCyrl300.ttf") format("truetype"), url("fonts/MuseoSansCyrl300.svg") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'museosanscyr';
  src: url("fonts/MuseoSansCyrl-500_gdi.eot");
  src: url("fonts/MuseoSansCyrl-500_gdi.eot?#iefix") format("embedded-opentype"), url("fonts/MuseoSansCyrl-500_gdi.woff") format("woff"), url("fonts/MuseoSansCyrl-500_gdi.ttf") format("truetype"), url("fonts/MuseoSansCyrl-500_gdi.svg#MuseoSansCyrl-500") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'museosanscyr';
  src: url("fonts/MuseoSansCyrl700.eot");
  src: local("☺"), url("fonts/MuseoSansCyrl700.woff") format("woff"), url("fonts/MuseoSansCyrl700.ttf") format("truetype"), url("fonts/MuseoSansCyrl700.svg") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'museosanscyr';
  src: url("fonts/MuseoSansCyrl-900_gdi.eot");
  src: url("fonts/MuseoSansCyrl-900_gdi.eot?#iefix") format("embedded-opentype"), url("fonts/MuseoSansCyrl-900_gdi.woff") format("woff"), url("fonts/MuseoSansCyrl-900_gdi.ttf") format("truetype"), url("fonts/MuseoSansCyrl-900_gdi.svg#MuseoSansCyrl-900") format("svg");
  font-weight: 900;
  font-style: normal; }

/*----------------------------------------------------------------------------
									VARIABLES
----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------
									BASE
----------------------------------------------------------------------------*/
html {
  height: 100%; }

body {
  font: 500 14px 'museosanscyr', Arial, sans-serif;
  width: 100%;
  height: 100%;
  line-height: 1.3125;
  color: #3b4047; }

.wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  height: auto !important;
  height: 100%; }

.middle {
  width: 100%;
  padding: 0 0 308px;
  position: relative;
  clear: both; }
  .sidebar_left .middle,
  .sidebar_right .middle {
    max-width: 1170px;
    margin: 0 auto; }
  .bottom_region .middle {
    padding-bottom: 20px; }
  .middle::after {
    display: table;
    clear: both;
    content: ''; }

.bottom_region .region_bottom {
  padding-bottom: 308px; }

.middle_content {
  max-width: 1170px;
  margin: 0 auto; }
  .sidebar_right .middle_content {
    padding-right: 338px; }
  .sidebar_left .middle_content {
    padding-left: 340px; }

.container {
  width: 100%;
  float: left;
  overflow: hidden; }

.top_inner {
  padding-top: 24px; }
  .page_news_node .top_inner {
    padding-top: 0; }
  .top_inner .block_content {
    max-width: 1170px;
    margin: 0 auto;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 29px;
    padding-bottom: 17px; }
  .top_inner .inner_wrapper {
    float: left;
    max-width: 60%; }
    .page_news_node .top_inner .inner_wrapper {
      max-width: 850px; }
    .top_inner .inner_wrapper .breadcrumbs {
      color: #89919e;
      text-transform: uppercase; }
      .top_inner .inner_wrapper .breadcrumbs ul {
        font-size: 0; }
        .top_inner .inner_wrapper .breadcrumbs ul li {
          display: inline-block;
          vertical-align: top;
          font-size: 11px; }
      .top_inner .inner_wrapper .breadcrumbs a {
        color: #89919e;
        text-decoration: none; }
        .top_inner .inner_wrapper .breadcrumbs a:hover {
          text-decoration: underline; }
      .top_inner .inner_wrapper .breadcrumbs .separator {
        padding: 0 13px; }
    .top_inner .inner_wrapper .page_title {
      font-size: 36px;
      margin: 0;
      line-height: 1.2;
      padding-top: 6px;
      font-weight: 400; }
  .top_inner .link_image {
    float: right;
    margin-top: -3px; }
    .top_inner .link_image img {
      display: block; }

/*----------------------------------------------------------------------------
									ERROR PAGE
----------------------------------------------------------------------------*/
.error_wrapper .error_content {
  text-align: center;
  margin-bottom: 40px; }
  .error_wrapper .error_content .error_title {
    font-size: 36px;
    padding-top: 40px;
    padding-bottom: 250px;
    background: url(images/error_bg.png) no-repeat scroll center bottom transparent;
    margin-bottom: 38px; }
  .error_wrapper .error_content .error_text {
    font-size: 16px;
    font-weight: 300; }
    .error_wrapper .error_content .error_text a {
      color: #16a914; }

.error_wrapper .link_image img {
  display: block;
  margin: 0 auto 40px; }

.page_register .vip_notice,
.error_wrapper .vip_notice {
  padding-top: 25px;
  border-top: 1px solid #eaeaea; }
  .page_register .vip_notice .block_content,
  .error_wrapper .vip_notice .block_content {
    font-size: 0;
    margin: 0 -24px 0 0 !important; }
    .page_register .vip_notice .block_content .col,
    .error_wrapper .vip_notice .block_content .col {
      margin: 0;
      padding-right: 24px;
      width: 25%; }

/*----------------------------------------------------------------------------
									PAGER
----------------------------------------------------------------------------*/
.page_navigation {
  padding: 30px 0;
  border-top: 1px solid #eaeaea; }
  .page_navigation ul {
    display: table;
    margin: 0 auto;
    font-size: 0; }
    .page_navigation ul li {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      margin: 0 12px; }
      .page_navigation ul li a {
        text-decoration: none;
        transition: 0.3s; }
        .page_navigation ul li a:hover {
          color: #16a914; }
      .page_navigation ul li.page_arrow a {
        display: block;
        text-indent: -9999px;
        width: 7px;
        height: 29px;
        transition: 0.3s; }
      .page_navigation ul li.page_arrow.arrow_prev a {
        background: url(images/page_prev.png) no-repeat scroll 0 center transparent; }
        .page_navigation ul li.page_arrow.arrow_prev a:hover {
          background: url(images/page_prev2.png) no-repeat scroll 0 center transparent; }
      .page_navigation ul li.page_arrow.arrow_next a {
        background: url(images/page_next.png) no-repeat scroll 0 center transparent; }
        .page_navigation ul li.page_arrow.arrow_next a:hover {
          background: url(images/page_next2.png) no-repeat scroll 0 center transparent; }
      .page_navigation ul li.current_page a {
        font-size: 24px;
        color: #16a914;
        font-weight: 700; }

/*----------------------------------------------------------------------------
									LOADER
----------------------------------------------------------------------------*/
.ajax-progress {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  margin: 0;
  padding: 0;
  z-index: 1000; }

.ajax-progress .throbber {
  background: none;
  border-radius: 6px;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  margin: 0;
  padding: 0 !important;
  border: 8px solid rgba(0, 0, 0, 0.3);
  border-left: 8px solid #000;
  border-radius: 100%;
  -webkit-animation: rotate 0.7s linear infinite;
  -moz-animation: rotate 0.7s linear infinite;
  animation: rotate 0.7s linear infinite;
  margin-top: -25px;
  margin-left: -25px; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes fadeOut {
  0% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
    opacity: 0.1; }
  100% {
    -webkit-transform: scale(0.1);
    opacity: 0.8; } }

@-webkit-keyframes strechdelay {
  0%, 50%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@-moz-keyframes fadeOut {
  0% {
    -moz-transform: scale(0); }
  50% {
    -moz-transform: scale(1);
    opacity: 0.1; }
  100% {
    -moz-transform: scale(0.1);
    opacity: 0.8; } }

@-moz-keyframes strechdelay {
  0%, 50%, 100% {
    -moz-transform: scaleY(0.4); }
  20% {
    -moz-transform: scaleY(1); } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes fadeOut {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1);
    opacity: 0.1; }
  100% {
    transform: scale(0.1);
    opacity: 0.8; } }

@keyframes strechdelay {
  0%, 50%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

/*----------------------------------------------------------------------------
									BASIC STYLES
----------------------------------------------------------------------------*/
.field-type-tex-with-summary .content_left_img,
.field-type-text-long .content_left_img {
  margin: 5px 20px 5px 0; }

.field-type-tex-with-summary .content_right_img,
.field-type-text-long .content_right_img {
  margin: 5px 0 5px 20px; }

.grippie {
  display: none !important; }

.webform-confirmation + .links {
  display: none !important; }

ul.menu li, ul li {
  margin: 0;
  padding: 0;
  list-style: none; }

*, :after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input {
  vertical-align: middle;
  box-sizing: border-box; }
  input.form_submit {
    cursor: pointer;
    transition: 0.3s; }
    input.form_submit:hover {
      opacity: 0.8; }
  input.form_text {
    transition: 0.3s;
    width: 100%;
    padding: 0 10px; }
    input.form_text:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(9, 169, 39, 0.6) !important; }

.form_text {
  height: 32px;
  border-radius: 2px;
  border: 1px solid #bec7d1;
  background-color: #ffffff;
  width: 100%; }

textarea {
  box-sizing: border-box;
  transition: 0.3s; }
  textarea:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(9, 169, 39, 0.6) !important; }

select.error,
input.error,
textarea.error,
input[type="text"].error,
input[type="password"].error {
  border: 1px solid red !important; }

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }

select::-ms-expand {
  display: none; }

.form-item, .form-actions {
  margin: 0; }

a {
  color: #3b4047;
  text-decoration: underline; }
  a:hover {
    text-decoration: none; }

img {
  max-width: 100%;
  height: auto;
  border: none; }

* {
  margin: 0;
  padding: 0;
  outline: none; }

p {
  margin: 0 0 18px; }

.popup_fon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 99; }

#gl_devel {
  color: #3b4047;
  font: 12px 'cuprumregular';
  text-transform: uppercase;
  z-index: 25;
  overflow: hidden;
  float: left;
  position: relative;
  margin-top: 5px; }

#gl_devel img {
  margin-right: 2px;
  display: inline-block;
  vertical-align: top; }

#gl_devel a {
  color: #3b4047;
  text-decoration: none;
  display: inline-block; }

#gl_devel .mask_devel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  clip: rect(0px, 30px, 20px, 0px);
  -webkit-animation: glyanec 5s cubic-bezier(0, -3, 1, 1) infinite;
  animation: glyanec 5s cubic-bezier(0, -3, 1, 1) infinite; }

#gl_devel .mask_devel a,
#gl_devel .mask_devel {
  color: #fff; }

#gl_devel > span > img {
  -webkit-animation: glyanec_img 5s infinite;
  animation: glyanec_img 5s infinite; }

@-webkit-keyframes glyanec {
  0% {
    clip: rect(0px, 15px, 20px, 0px); }
  100% {
    clip: rect(0px, 200px, 20px, 200px); } }

@keyframes glyanec {
  0% {
    clip: rect(0px, 15px, 20px, 0px); }
  100% {
    clip: rect(0px, 200px, 20px, 200px); } }

@-webkit-keyframes glyanec_img {
  0% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); }
  20% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); }
  40% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); }
  60% {
    -webkit-transform: rotate3d(0, 10, 0, 180deg);
    transform: rotate3d(0, 10, 0, 180deg); }
  80% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); } }

@keyframes glyanec_img {
  0% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); }
  20% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); }
  40% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); }
  60% {
    -webkit-transform: rotate3d(0, 10, 0, 180deg);
    transform: rotate3d(0, 10, 0, 180deg); }
  80% {
    -webkit-transform: rotate3d(10, 0, 0, 0deg);
    transform: rotate3d(10, 0, 0, 0deg); } }

#gl_devel a:hover {
  text-decoration: underline; }

body.ie_browser #gl_devel a,
body.ie_browser #gl_devel span {
  font-size: 8px; }

#gl_devel a:hover {
  text-decoration: underline; }

.clearfix::after {
  content: '';
  display: table;
  clear: both; }

/*----------------------------------------------------------------------------
									HEADER
----------------------------------------------------------------------------*/
.header .link_image {
  padding-top: 12px;
  padding-bottom: 7px; }
  .header .link_image img {
    display: block;
    margin: 0 auto; }

.header .header_content {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 22px;
  margin-bottom: 18px; }

.header .header_heroes {
  float: left;
  width: 292px;
  height: 75px;
  background: url(images/heroes_bg.png) no-repeat scroll 0 0 transparent;
  color: #fff;
  padding: 10px 67px 0 64px;
  position: relative;
  margin-top: 6px; }
  .header .header_heroes::before {
    content: '';
    display: block;
    position: absolute;
    top: -27px;
    left: -59px;
    background: url(images/ukr_flag.png) no-repeat scroll 0 0 transparent;
    width: 110px;
    height: 111px; }
  .header .header_heroes:hover .block_text {
    text-decoration: underline; }
  .header .header_heroes .block_title {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 5px; }
  .header .header_heroes .block_text {
    font-size: 13px;
    text-align: center; }
  .header .header_heroes .main_link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    text-indent: -9999px;
    width: 100%;
    height: 100%; }

.header .header_user {
  float: right;
  padding: 6px 0px 0 0; }

.header .header_logo {
  display: table;
  margin: 0 auto;
  position: relative;
  left: 6px; }
  .header .header_logo img {
    display: block; }

.header .social_wrapper {
  float: left;
  margin-top: -2px; }

.header .notice_wrapper {
  float: right;
  margin-left: 13px; }

.header .social_links {
  font-size: 0;
  margin-bottom: 22px;
  padding-top: 4px; }
  .header .social_links .social {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px; }
    .header .social_links .social:last-child {
      margin-right: 0; }
    .header .social_links .social a {
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 50%; }
      .header .social_links .social a.social_fb {
        background: url(images/fb_icon.svg) no-repeat scroll 0 0 transparent;
        background-size: cover !important; }
        .header .social_links .social a.social_fb:hover {
          background: url(images/fb_icon_hover.svg) no-repeat scroll 0 0 transparent; }
      .header .social_links .social a.social_tel {
        background: url(images/tel_icon.svg) no-repeat scroll 0 0 transparent;
        background-size: cover !important; }
        .header .social_links .social a.social_tel:hover {
          background: url(images/tel_icon_hover.svg) no-repeat scroll 0 0 transparent; }
      .header .social_links .social a.social_tw {
        background: url(images/tw_icon.svg) no-repeat scroll 0 0 transparent;
        background-size: cover !important; }
        .header .social_links .social a.social_tw:hover {
          background: url(images/tw_icon_hover.svg) no-repeat scroll 0 0 transparent; }

.header .user_login {
  text-align: right; }
  .header .user_login .login_link {
    padding-left: 27px;
    background: url(images/login_icon.png) no-repeat scroll 0 0 transparent;
    line-height: 22px;
    display: block;
    text-decoration: none;
    display: inline-block; }
    .header .user_login .login_link.cabinet_login {
      background: url(images/cabinet_icon.png) no-repeat scroll 0 0 transparent;
      display: none; }
    .header .user_login .login_link:hover {
      text-decoration: underline; }

.header .header_notice {
  line-height: 32px;
  border-radius: 2px;
  padding: 0 15px 0 35px;
  background: url(images/plus.png) no-repeat scroll 13px center #09a927;
  color: #fff;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 14px; }
  .header .header_notice:hover {
    opacity: 0.8; }

.header .search_block {
  position: relative; }
  .header .search_block .form_submit {
    position: absolute;
    top: 50%;
    width: 18px;
    height: 18px;
    display: block;
    text-indent: -9999px;
    margin-top: -9px;
    background: url(images/search_icon.svg) no-repeat scroll 0 0 transparent;
    border: none;
    right: 7px; }

.header .menu_wrapper {
  background: #fbfcfe; }

.header .main_menu {
  background: #4b525b; }
  .header .main_menu .block_content {
    max-width: 830px;
    margin: 0 auto; }
  .header .main_menu ul {
    display: table;
    width: 100%; }
    .header .main_menu ul li {
      display: table-cell;
      text-align: center; }
      .header .main_menu ul li a {
        color: #fff;
        text-decoration: none;
        font-weight: 700;
        font-size: 18px;
        line-height: 48px;
        display: block;
        padding: 0 24px; }
        .header .main_menu ul li a:hover, .header .main_menu ul li a.active {
          background: url(images/menu_bg.png) repeat-x scroll 0 0 transparent; }

.header .notice_menu {
  border-bottom: 1px solid #e1e1e1; }
  .page_news_node .header .notice_menu {
    margin-bottom: 30px; }
  .header .notice_menu .block_content {
    max-width: 1170px;
    margin: 0 auto;
    position: relative; }
    .header .notice_menu .block_content::before {
      content: '';
      display: block;
      border: 4px solid transparent;
      border-bottom: 5px solid #fff;
      position: absolute;
      top: -8px;
      left: 49.7%;
      z-index: 10; }
  .header .notice_menu ul {
    display: flex;
    justify-content: space-between; }
    .header .notice_menu ul a {
      font-size: 13px;
      text-decoration: none;
      transition: .3s;
      display: block;
      line-height: 30px;
      font-weight: 300; }
      .header .notice_menu ul a.active, .header .notice_menu ul a:hover {
        color: #09a927;
        text-decoration: underline; }

@media (min-width: 1200px) and (max-width: 1400px) {
  .front .block_poster button.slick-prev {
    left: -35px; }
  .front .block_poster button.slick-next {
    right: -35px; } }

/*----------------------------------------------------------------------------
                                    NEWS
----------------------------------------------------------------------------*/
.block_views_news_1 {
  padding-bottom: 40px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaeaea; }
  .block_views_news_1 .col_group_1 {
    margin: 0 -25px 25px 0;
    font-size: 0; }
    .block_views_news_1 .col_group_1 .col {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      width: 50%;
      padding-right: 25px; }
    .block_views_news_1 .col_group_1 .image_wrapper {
      position: relative; }
    .block_views_news_1 .col_group_1 .title_wrap {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 10;
      color: #fff;
      height: 155px;
      background-image: linear-gradient(180deg, rgba(0, 37, 50, 0) 0%, rgba(1, 19, 35, 0.67) 40%, rgba(1, 18, 34, 0.75) 100%);
      padding: 65px 25px 0; }
      .block_views_news_1 .col_group_1 .title_wrap a {
        color: #fff; }
    .block_views_news_1 .col_group_1 .col_info {
      display: table;
      margin: 0 auto 12px;
      font-size: 0; }
      .block_views_news_1 .col_group_1 .col_info .category {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        padding-right: 24px;
        position: relative; }
        .block_views_news_1 .col_group_1 .col_info .category a {
          text-decoration: none; }
          .block_views_news_1 .col_group_1 .col_info .category a:hover {
            text-decoration: underline; }
        .block_views_news_1 .col_group_1 .col_info .category::after {
          content: '';
          display: block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.4);
          position: absolute;
          top: 50%;
          margin-top: -3.5px;
          right: 8px; }
      .block_views_news_1 .col_group_1 .col_info .date {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        padding-right: 24px;
        position: relative; }
        .block_views_news_1 .col_group_1 .col_info .date::after {
          content: '';
          display: block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.4);
          position: absolute;
          top: 50%;
          margin-top: -3.5px;
          right: 8px; }
      .block_views_news_1 .col_group_1 .col_info .count_view {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        padding-left: 20px;
        background: url(images/eye_icon2.svg) no-repeat scroll 0 4px transparent; }
    .block_views_news_1 .col_group_1 .title {
      text-align: center;
      height: 45px;
      overflow: hidden; }
      .block_views_news_1 .col_group_1 .title a {
        font-size: 18px;
        text-decoration: none;
        font-weight: 700; }
        .block_views_news_1 .col_group_1 .title a:hover {
          text-decoration: underline; }
  .block_views_news_1 .col_group_2 {
    margin-right: -25px;
    font-size: 0; }
    .block_views_news_1 .col_group_2 .col {
      font-size: 14px;
      width: 25%;
      display: inline-block;
      vertical-align: top;
      padding-right: 25px; }
    .block_views_news_1 .col_group_2 .image {
      margin-bottom: 8px; }
    .block_views_news_1 .col_group_2 .title {
      margin-bottom: 10px; }
      .block_views_news_1 .col_group_2 .title a {
        font-size: 16px;
        text-decoration: none;
        line-height: 1.35;
        transition: .3s; }
        .block_views_news_1 .col_group_2 .title a:hover {
          color: #16a914;
          text-decoration: underline; }
    .block_views_news_1 .col_group_2 .col_info {
      color: #89919e;
      font-size: 0; }
      .block_views_news_1 .col_group_2 .col_info .date {
        font-size: 12px;
        display: inline-block;
        vertical-align: top; }
      .block_views_news_1 .col_group_2 .col_info .count_view {
        font-size: 12px;
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
        padding-left: 20px;
        background: url(images/eye_icon.svg) no-repeat scroll 0 3px transparent; }

.view_news img {
  display: block;
  border-radius: 2px; }

.block_views_news_2 .block_title {
  font-size: 24px;
  margin-bottom: 22px; }

.block_views_news_2 .col {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eaeaea; }
  .block_views_news_2 .col:last-child {
    margin-bottom: 0;
    border-bottom: none; }
  .block_views_news_2 .col::after {
    content: '';
    display: table;
    clear: both; }

.block_views_news_2 .date {
  font-size: 12px;
  float: left;
  width: 5.5%;
  padding-right: 1%;
  color: #89919e;
  padding-top: 36px; }

.block_views_news_2 .image {
  float: left;
  margin-right: 15px; }

.block_views_news_2 .title_wrapper {
  overflow: hidden; }

.block_views_news_2 .title {
  margin-bottom: 10px; }
  .block_views_news_2 .title a {
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    transition: .3s;
    line-height: 1.45; }
    .block_views_news_2 .title a:hover {
      text-decoration: underline;
      color: #16a914; }

.top_inner .info_wrapper {
  padding-top: 10px;
  font-size: 0; }

.top_inner .node_field {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #89919e;
  margin-right: 22px; }

.top_inner .reviews_count {
  padding-left: 17px;
  background: url(images/reviews_count.svg) no-repeat scroll 0 center transparent; }

.top_inner .count_view {
  padding-left: 19px;
  background: url(images/eye_icon.svg) no-repeat scroll 0 3px transparent; }

/*----------------------------------------------------------------------------
									NODE NEWS
----------------------------------------------------------------------------*/
.node_news .node_image {
  margin-bottom: 10px; }

.node_news strong {
  font-size: 18px; }

.node_news .description {
  font-size: 16px;
  font-weight: 300;
  text-align: justify; }

.node_news .image_social {
  position: relative;
  margin-bottom: 20px; }
  .node_news .image_social:after {
    content: '';
    display: block;
    width: 103px;
    height: 17px;
    background: url(images/watermark.png) no-repeat scroll 0 0 transparent;
    position: absolute;
    bottom: 10px;
    right: 11px; }
  .node_news .image_social img {
    display: block; }
  .node_news .image_social:hover .addthis_inline_share_toolbox_aqao {
    opacity: 1;
    height: auto;
    overflow: visible; }
  .node_news .image_social .at-resp-share-element .at-share-btn {
    margin: 0 10px 0 0 !important; }
  .node_news .image_social .addthis_inline_share_toolbox_aqao {
    opacity: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    bottom: 10px;
    left: 10px;
    transition: .3s; }

.node_news .source_link {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 15px;
  text-align: right;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea; }

.node_news .facebook_comments {
  margin-bottom: 15px; }

.other_news {
  margin-bottom: 30px; }
  .other_news .block_title {
    font-size: 24px;
    margin-bottom: 20px; }
  .other_news .col {
    margin-bottom: 28px; }
    .other_news .col::after {
      content: '';
      display: table;
      clear: both; }
  .other_news .image {
    float: left;
    margin-right: 15px; }
    .other_news .image img {
      border-radius: 2px;
      display: block; }
  .other_news .description_wrap {
    overflow: hidden; }
  .other_news .info_wrap {
    font-size: 12px;
    color: #89919e;
    margin-bottom: 10px; }
    .other_news .info_wrap::after {
      content: '';
      display: table;
      clear: both; }
    .other_news .info_wrap .category {
      float: left; }
      .other_news .info_wrap .category a {
        text-decoration: none;
        color: #89919e;
        transition: .3s; }
        .other_news .info_wrap .category a:hover {
          color: #16a914;
          text-decoration: underline; }
    .other_news .info_wrap .date {
      float: left;
      margin-left: 25px;
      position: relative; }
      .other_news .info_wrap .date::before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background: #bec7d1;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        margin-top: -3.5px;
        left: -15px; }
  .other_news .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px; }
    .other_news .title a {
      text-decoration: none;
      transition: .3s; }
      .other_news .title a:hover {
        color: #16a914;
        text-decoration: underline; }
  .other_news .description {
    font-size: 14px; }
  .other_news .load_more {
    display: table;
    margin: 0 auto;
    padding-left: 29px;
    background: url(images/load_more.png) no-repeat scroll 0 center transparent;
    font-size: 16px;
    font-weight: 300;
    transition: .3s;
    cursor: pointer; }
    .other_news .load_more:hover {
      text-decoration: underline;
      color: #16a914; }

.wrapper .block_news_top .title a {
  line-height: 1.25;
  margin-bottom: 5px; }

.wrapper .block_news_top .date {
  margin-bottom: 5px; }

.wrapper .block_news_top .col {
  padding-bottom: 16px;
  margin-bottom: 15px; }

.wrapper .block_news_top .image {
  padding-top: 21px; }

.page_news .block_news_top .block_title {
  margin-bottom: 20px;
  margin-top: -4px; }

.page_journal .left_sidebar .block_news_city .col:last-child {
  padding-bottom: 0;
  border-bottom: none; }

.page_journal .page_navigation {
  padding-bottom: 0; }

.node_socials {
  width: 16.8%;
  padding-right: 2%;
  float: left;
  position: relative;
  min-height: 1px; }
  .node_socials .at-label {
    display: none !important; }

.category_link {
  margin-bottom: 27px; }
  .category_link a {
    color: #16a914; }

.addthis_inline_share_toolbox_6wf7.fixed {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -585px;
  z-index: 50; }
  .addthis_inline_share_toolbox_6wf7.fixed.bottom {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    margin-left: 0; }

.addthis_inline_share_toolbox_6wf7::after {
  content: '';
  display: table;
  clear: both; }

.addthis_inline_share_toolbox_6wf7 a {
  float: left;
  clear: both;
  margin-bottom: 30px !important; }

.node_content {
  overflow: hidden; }

.video_wrap {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 15px; }

.video_wrap iframe, .video_wrap object, .video_wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; }

/*----------------------------------------------------------------------------
									COMPANY
----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------
									VARIABLES
----------------------------------------------------------------------------*/
.view_company .view_header {
  font-size: 0;
  margin-bottom: 45px; }
  .view_company .view_header .views_field:not(.add_company) {
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    margin-right: 80px; }
    .view_company .view_header .views_field:not(.add_company) .label {
      font-size: 11px;
      text-transform: uppercase;
      margin-bottom: 5px;
      color: #89919e; }
    .view_company .view_header .views_field:not(.add_company) .value {
      font-size: 24px; }

.view_company .view_content::after {
  content: '';
  display: table;
  clear: both; }

.view_company .view_content .col_left {
  float: left; }

.view_company .view_content .col_right {
  float: right; }

.view_company .view_content .row {
  margin-bottom: 35px; }

.view_company .view_content .main_title a {
  text-decoration: none;
  font-weight: 900;
  color: #32ab00;
  font-size: 18px;
  display: block;
  padding-left: 40px;
  margin-bottom: 20px;
  line-height: 25px; }
  .view_company .view_content .main_title a:hover {
    text-decoration: underline; }

.view_company .view_content .main_title_1 a {
  background: url("images/company_icon_1.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_2 a {
  background: url("images/company_icon_2.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_3 a {
  background: url("images/company_icon_3.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_4 a {
  background: url("images/company_icon_4.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_5 a {
  background: url("images/company_icon_5.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_6 a {
  background: url("images/company_icon_6.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_7 a {
  background: url("images/company_icon_7.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_8 a {
  background: url("images/company_icon_8.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_9 a {
  background: url("images/company_icon_9.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_10 a {
  background: url("images/company_icon_10.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_11 a {
  background: url("images/company_icon_11.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_12 a {
  background: url("images/company_icon_12.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_13 a {
  background: url("images/company_icon_13.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_14 a {
  background: url("images/company_icon_14.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_15 a {
  background: url("images/company_icon_15.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_16 a {
  background: url("images/company_icon_16.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content .main_title_17 a {
  background: url("images/company_icon_17.svg") no-repeat scroll 0 center transparent; }

.view_company .view_content ul {
  padding-left: 40px; }
  .view_company .view_content ul li {
    margin-bottom: 15px; }
    .view_company .view_content ul li a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 300;
      transition: .3s; }
      .view_company .view_content ul li a:hover {
        color: #16a914;
        text-decoration: underline; }

.add_company {
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  line-height: 32px;
  background: url(images/add_company.png) no-repeat scroll 22px center #fff241;
  border-radius: 1px;
  border: 2px solid #ffdd09;
  padding: 0 25px 0 50px;
  cursor: pointer;
  transition: .3s; }
  .add_company:hover {
    opacity: 0.7; }

.view_company_category .category_menu {
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
  font-size: 0; }
  .view_company_category .category_menu .menu_group {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    padding-right: 35px;
    width: 33.3%; }
    .view_company_category .category_menu .menu_group:last-child {
      padding-right: 0; }
  .view_company_category .category_menu li {
    margin-bottom: 15px; }
    .view_company_category .category_menu li:last-child {
      margin-bottom: 0; }
    .view_company_category .category_menu li a {
      text-decoration: none;
      font-weight: 300;
      transition: .3s; }
      .view_company_category .category_menu li a:hover {
        color: #16a914;
        text-decoration: underline; }
      .view_company_category .category_menu li a span {
        margin-left: 12px;
        font-size: 12px;
        color: #89919e; }
  .view_company_category .category_menu .add_company {
    float: right;
    margin-top: 15px; }

.view_company_category .view_content .views_title {
  font-size: 24px;
  margin-bottom: 25px; }

.view_company_category .col {
  padding: 18px;
  margin-bottom: 1px; }
  .view_company_category .col::after {
    content: '';
    display: table;
    clear: both; }
  .view_company_category .col.vip {
    background: url(images/vip_bg.png) repeat scroll 0 0 transparent; }
    .view_company_category .col.vip .btns_wrapper {
      position: relative; }
      .view_company_category .col.vip .btns_wrapper .btn {
        border-color: #f5a623;
        color: #f5a623;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        white-space: nowrap;
        top: auto; }
  .view_company_category .col.buisness {
    background: url(images/buisness_bg.png) repeat scroll 0 0 transparent; }
  .view_company_category .col.base {
    background: url(images/base_bg.png) repeat scroll 0 0 transparent; }
    .view_company_category .col.base .btns_wrapper .btn {
      border-color: #87909c;
      color: #87909c; }
  .view_company_category .col .images {
    float: left;
    width: 210px;
    height: 135px;
    margin-right: 12px;
    display: table;
    background: #fff;
    border: 1px solid rgba(151, 151, 151, 0.2); }
    .view_company_category .col .images a {
      display: table-cell;
      vertical-align: middle; }
      .view_company_category .col .images a img {
        display: block;
        margin: 0 auto; }
  .view_company_category .col .info_wrapper {
    float: left; }
  .view_company_category .col .award img {
    display: block;
    margin: 0 auto; }
  .view_company_category .col .btns_wrapper {
    width: 130px;
    float: right;
    position: relative;
    min-height: 78px;
    height: 100%; }
    .view_company_category .col .btns_wrapper .btn {
      border: 1px solid #4a90e2;
      border-radius: 2px;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 11px;
      color: #4a90e2;
      padding: 7px 0 5px;
      width: 100%;
      text-align: center;
      background: #fff;
      position: absolute;
      top: 50%;
      margin-top: -14px;
      right: 0; }
  .view_company_category .col .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px; }
    .view_company_category .col .title a {
      text-decoration: none; }
      .view_company_category .col .title a:hover {
        text-decoration: underline; }
  .view_company_category .col .phone {
    font-size: 16px;
    font-weight: 300;
    padding-left: 23px;
    background: url(images/phone_icon_ad.png) no-repeat scroll 0 center transparent;
    margin-bottom: 15px;
    margin-left: 10px; }
  .view_company_category .col .address {
    font-size: 16px;
    font-weight: 300;
    padding-left: 23px;
    background: url(images/address_icon_ad.png) no-repeat scroll 0 5px transparent;
    margin-left: 10px; }

.view_company_category .col:not(.vip):not(.buisness) .info_wrapper {
  width: 76%; }
  .view_company_category .col:not(.vip):not(.buisness) .info_wrapper .title {
    float: left;
    width: 63%;
    padding-right: 2.6%; }
  .view_company_category .col:not(.vip):not(.buisness) .info_wrapper .address_wrap {
    overflow: hidden; }

.view_company_category .link_image {
  text-align: center;
  margin: 20px 0; }
  .view_company_category .link_image img {
    display: block;
    margin: 0 auto; }

.view_company_category .page_navigation {
  border: none; }

/*----------------------------------------------------------------------------
									NODE COMPANY
----------------------------------------------------------------------------*/
.map_canvas {
  height: 385px;
  margin-bottom: 27px;
  margin-top: 11px;
  clear: both; }

.node_company .image {
  float: left;
  margin-right: 22px;
  display: table;
  height: 179px;
  width: 270px;
  border: 1px solid rgba(151, 151, 151, 0.2); }
  .node_company .image .field_content {
    display: table-cell;
    vertical-align: middle; }
  .node_company .image img {
    display: block;
    margin: 0 auto; }

.node_company .description_wrapper {
  overflow: hidden; }

.node_company .company_name {
  font-size: 24px;
  float: left; }

.node_company .count_view {
  font-weight: 300;
  padding-left: 20px;
  background: url(images/eye_icon.svg) no-repeat scroll 0 11px transparent;
  float: right;
  line-height: 32px; }

.node_company .btns_wrapper {
  width: 130px;
  float: right;
  position: relative;
  min-height: 78px;
  height: 100%; }
  .node_company .btns_wrapper .btn {
    border: 1px solid #4a90e2;
    border-radius: 2px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 11px;
    color: #4a90e2;
    padding: 7px 0 5px;
    width: 100%;
    text-align: center;
    background: #fff;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    right: 0; }

.node_company .btns_wrapper.vip {
  position: relative;
  float: right;
  width: 130px;
  margin-top: 36px;
  margin-right: 9px; }
  .node_company .btns_wrapper.vip img {
    display: block;
    margin: 0 auto; }
  .node_company .btns_wrapper.vip .btn {
    border-color: #f5a623;
    color: #f5a623;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    white-space: nowrap;
    top: auto; }

.node_company .descrtiption {
  display: block;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 300;
  float: left;
  clear: both; }
  .node_company .descrtiption p {
    margin-bottom: 10px; }

.page_node_company .vip_notice {
  border-top: 1px solid #dfdfdf;
  padding-top: 25px; }

/*----------------------------------------------------------------------------
									FRONT
----------------------------------------------------------------------------*/
.front .middle {
  padding-top: 23px; }

.front .block_views_news_1 {
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
  border-bottom: none;
  padding-top: 2px; }
  .front .block_views_news_1 .col_group_1 {
    margin: 0; }
    .front .block_views_news_1 .col_group_1 .col {
      width: 100%;
      padding-right: 0; }
      .front .block_views_news_1 .col_group_1 .col img {
        display: block; }
      .front .block_views_news_1 .col_group_1 .col .title_wrap {
        background: linear-gradient(180deg, rgba(0, 37, 50, 0) 0%, rgba(1, 18, 34, 0.52) 100%);
        padding-left: 45px;
        padding-right: 45px; }
    .front .block_views_news_1 .col_group_1 .image_wrapper {
      margin-bottom: 3px; }

.front .other_news .description {
  font-weight: 300; }

.front .block_news_large {
  margin-bottom: 25px; }
  .front .block_news_large img {
    display: block; }
  .front .block_news_large .image {
    margin-bottom: 18px; }
  .front .block_news_large .info_wrap {
    font-size: 12px;
    color: #89919e;
    margin-bottom: 10px; }
    .front .block_news_large .info_wrap::after {
      content: '';
      display: table;
      clear: both; }
    .front .block_news_large .info_wrap .category {
      float: left; }
      .front .block_news_large .info_wrap .category a {
        text-decoration: none;
        color: #89919e;
        transition: .3s; }
        .front .block_news_large .info_wrap .category a:hover {
          color: #16a914;
          text-decoration: underline; }
    .front .block_news_large .info_wrap .date {
      float: left;
      margin-left: 25px;
      position: relative; }
      .front .block_news_large .info_wrap .date::before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background: #bec7d1;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        margin-top: -3.5px;
        left: -15px; }
  .front .block_news_large .title a {
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;
    transition: .3s;
    line-height: 1.2;
    display: block;
    margin-bottom: 15px; }
    .front .block_news_large .title a:hover {
      text-decoration: underline;
      color: #16a914; }
  .front .block_news_large .description {
    font-weight: 300; }

.front .vip_notice .col {
  margin-bottom: 21px; }

.front .vip_notice .image img {
  margin-bottom: 5px; }

.front .vip_notice .title a {
  margin-bottom: 0; }

.block_journal {
  padding-top: 21px;
  border-top: 1px solid #eaeaea; }
  .block_journal .block_title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center; }
  .block_journal .block_content {
    font-size: 0;
    margin-right: -25px; }
    .block_journal .block_content .col_left {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding-right: 23px; }
    .block_journal .block_content .col_right {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding-right: 23px; }
    .block_journal .block_content .row {
      margin-bottom: 24px; }
    .block_journal .block_content .title a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 300;
      transition: .3s;
      display: block;
      margin-bottom: 7px; }
      .block_journal .block_content .title a:hover {
        color: #16a914;
        text-decoration: underline; }
    .block_journal .block_content .date {
      font-size: 12px;
      color: #89919e; }

.block_title.line {
  position: relative;
  text-align: center;
  margin-bottom: 22px; }
  .block_title.line::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    background: #eaeaea;
    position: absolute; }
  .block_title.line span {
    font-size: 36px;
    padding: 0 25px;
    background: #fff;
    position: relative;
    z-index: 1; }

.block_accident {
  margin-bottom: 26px; }
  .block_accident .block_content {
    max-width: 1170px;
    margin: 0 auto; }
  .block_accident .view_content {
    margin-right: -23px;
    font-size: 0; }
    .block_accident .view_content .col {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      width: 25%;
      padding-right: 23px; }
      .block_accident .view_content .col .col_wrap {
        border: 1px solid #eaeaea;
        border-radius: 2px;
        overflow: hidden;
        padding: 0 19px 13px; }
    .block_accident .view_content .image {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 15px;
      margin-right: -19px;
      margin-left: -19px; }
      .block_accident .view_content .image img {
        display: block; }
    .block_accident .view_content .title {
      font-size: 16px;
      font-weight: 300; }
      .block_accident .view_content .title a {
        text-decoration: none;
        transition: .3s; }
        .block_accident .view_content .title a:hover {
          text-decoration: underline;
          color: #16a914; }
    .block_accident .view_content .count_view {
      font-size: 12px;
      padding-left: 20px;
      background: url(images/eye_icon.svg) no-repeat scroll 0 3px transparent;
      color: #89919e;
      margin-bottom: 9px; }

.block_photos {
  margin-bottom: 30px; }
  .block_photos .block_content {
    max-width: 1170px;
    margin: 0 auto; }
  .block_photos .view_content {
    margin-right: -30px;
    font-size: 0; }
    .block_photos .view_content .col {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      width: 33.3%;
      padding-right: 30px; }
  .block_photos .col_wrap {
    position: relative;
    transition: .3s; }
    .block_photos .col_wrap::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s;
      background: linear-gradient(180deg, rgba(0, 36, 49, 0.08) 0%, rgba(1, 18, 34, 0.4) 100%); }
    .block_photos .col_wrap:hover .count_view {
      opacity: 1; }
    .block_photos .col_wrap:hover::after {
      background: rgba(1, 18, 34, 0.57); }
  .block_photos .image img {
    display: block;
    border-radius: 2px; }
  .block_photos .title_wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 10;
    color: #fff;
    height: 155px;
    padding: 65px 25px 0;
    z-index: 1; }
    .block_photos .title_wrap a {
      color: #fff; }
  .block_photos .col_info {
    display: table;
    margin: 0 auto 12px;
    font-size: 0; }
    .block_photos .col_info .category {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      padding-right: 24px;
      position: relative; }
      .block_photos .col_info .category a {
        text-decoration: none; }
        .block_photos .col_info .category a:hover {
          text-decoration: underline; }
      .block_photos .col_info .category::after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4);
        position: absolute;
        top: 50%;
        margin-top: -3.5px;
        right: 8px; }
    .block_photos .col_info .date {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      padding-right: 24px;
      position: relative; }
      .block_photos .col_info .date::after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4);
        position: absolute;
        top: 50%;
        margin-top: -3.5px;
        right: 8px; }
  .block_photos .title {
    text-align: center;
    height: 45px;
    overflow: hidden; }
    .block_photos .title a {
      font-size: 18px;
      text-decoration: none;
      font-weight: 700; }
      .block_photos .title a:hover {
        text-decoration: underline; }
  .block_photos .count_view {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    padding-left: 20px;
    background: url(images/eye_icon2.svg) no-repeat scroll 0 4px transparent;
    position: absolute;
    top: 17px;
    right: 17px;
    z-index: 1;
    color: #fff;
    opacity: 0;
    transition: .3s; }

.block_poster {
  margin-bottom: 20px; }
  .block_poster .block_content {
    max-width: 1170px;
    margin: 0 auto; }
  .block_poster .view_content {
    margin: 0 -13px; }
  .block_poster .col {
    margin: 0 13px; }
  .block_poster button {
    width: 36px;
    height: 36px;
    text-indent: -9999px;
    border: 1px solid #89919e;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    top: 110px;
    z-index: 1;
    transition: .3s; }
    .block_poster button.slick-prev {
      left: -54px;
      background: url(images/slider_prev.png) no-repeat scroll 13px center #fff; }
      .block_poster button.slick-prev:hover {
        background: url(images/slider_prev2.png) no-repeat scroll 13px center #fff;
        border: 1px solid #09a927; }
    .block_poster button.slick-next {
      right: -54px;
      background: url(images/slider_next.png) no-repeat scroll 15px center #fff; }
      .block_poster button.slick-next:hover {
        background: url(images/slider_next2.png) no-repeat scroll 15px center #fff;
        border: 1px solid #09a927; }
  .block_poster .image {
    margin-bottom: 12px; }
  .block_poster .date {
    font-size: 11px;
    text-transform: uppercase;
    border-radius: 2px;
    border: 1px solid #bac5d6;
    padding: 0 9px;
    display: table;
    margin: 0 auto;
    padding-top: 3px;
    padding-bottom: 1px;
    margin-bottom: 13px; }
  .block_poster .place {
    color: #89919e;
    font-size: 12px;
    margin-bottom: 7px;
    text-align: center; }
  .block_poster .title {
    font-size: 18px;
    text-align: center; }
    .block_poster .title a {
      text-decoration: none;
      transition: .3s; }
      .block_poster .title a:hover {
        color: #16a914;
        text-decoration: underline; }

.block_catalog_company {
  background: #fbfcfe;
  border-top: 1px solid #e7e8e9;
  padding-top: 34px; }
  .block_catalog_company .block_content {
    max-width: 1170px;
    margin: 0 auto; }
  .block_catalog_company .block_catalog::after {
    content: '';
    display: table;
    clear: both; }
  .block_catalog_company .block_catalog .link_image {
    float: right;
    margin-left: 60px;
    margin-right: 8px; }
  .block_catalog_company .block_catalog .block_container {
    overflow: hidden; }
  .block_catalog_company .block_catalog .block_top {
    margin-bottom: 27px; }
    .block_catalog_company .block_catalog .block_top::after {
      content: '';
      display: table;
      clear: both; }
    .block_catalog_company .block_catalog .block_top .block_title {
      float: left;
      font-size: 24px;
      line-height: 32px;
      margin-right: 30px; }
    .block_catalog_company .block_catalog .block_top .add_company {
      float: left;
      border: none;
      background: url(images/plus.png) no-repeat scroll 13px center #09a927;
      color: #fff; }
  .block_catalog_company .block_catalog .block_menu {
    font-size: 0; }
  .block_catalog_company .block_catalog .menu_wrap {
    font-size: 16px;
    display: inline-block;
    margin-right: 55px;
    vertical-align: top; }
    .block_catalog_company .block_catalog .menu_wrap:last-child {
      margin-right: 0; }
    .block_catalog_company .block_catalog .menu_wrap .item {
      margin-bottom: 21px; }
    .block_catalog_company .block_catalog .menu_wrap a {
      display: block;
      padding-left: 26px;
      text-decoration: none; }
      .block_catalog_company .block_catalog .menu_wrap a span {
        font-size: 12px;
        color: #89919e;
        padding-left: 10px; }
      .block_catalog_company .block_catalog .menu_wrap a:hover {
        color: #16a914;
        text-decoration: underline; }
    .block_catalog_company .block_catalog .menu_wrap .item_1 a {
      background: url("images/category_ic_1.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_2 a {
      background: url("images/category_ic_2.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_3 a {
      background: url("images/category_ic_3.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_4 a {
      background: url("images/category_ic_4.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_5 a {
      background: url("images/category_ic_5.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_6 a {
      background: url("images/category_ic_6.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_7 a {
      background: url("images/category_ic_7.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_8 a {
      background: url("images/category_ic_8.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_9 a {
      background: url("images/category_ic_9.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_10 a {
      background: url("images/category_ic_10.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_11 a {
      background: url("images/category_ic_11.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_12 a {
      background: url("images/category_ic_12.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_13 a {
      background: url("images/category_ic_13.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_14 a {
      background: url("images/category_ic_14.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_15 a {
      background: url("images/category_ic_15.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_16 a {
      background: url("images/category_ic_16.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_17 a {
      background: url("images/category_ic_17.svg") no-repeat scroll 0 center transparent; }
    .block_catalog_company .block_catalog .menu_wrap .item_1 a:hover {
      background: url("images/company_icon_1.svg") no-repeat scroll 0 center transparent;
      background-size: 20px; }
    .block_catalog_company .block_catalog .menu_wrap .item_2 a:hover {
      background: url("images/company_icon_2.svg") no-repeat scroll 0 center transparent;
      background-size: 18px; }
    .block_catalog_company .block_catalog .menu_wrap .item_3 a:hover {
      background: url("images/company_icon_3.svg") no-repeat scroll 0 center transparent;
      background-size: 19px; }
    .block_catalog_company .block_catalog .menu_wrap .item_4 a:hover {
      background: url("images/company_icon_4.svg") no-repeat scroll 0 center transparent;
      background-size: 20px; }
    .block_catalog_company .block_catalog .menu_wrap .item_5 a:hover {
      background: url("images/company_icon_5.svg") no-repeat scroll 0 center transparent;
      background-size: 19px; }
    .block_catalog_company .block_catalog .menu_wrap .item_6 a:hover {
      background: url("images/company_icon_6.svg") no-repeat scroll 0 center transparent;
      background-size: 19px; }
    .block_catalog_company .block_catalog .menu_wrap .item_7 a:hover {
      background: url("images/company_icon_7.svg") no-repeat scroll 0 center transparent;
      background-size: 20px; }
    .block_catalog_company .block_catalog .menu_wrap .item_8 a:hover {
      background: url("images/company_icon_8.svg") no-repeat scroll 0 center transparent;
      background-size: 19px; }
    .block_catalog_company .block_catalog .menu_wrap .item_9 a:hover {
      background: url("images/company_icon_9.svg") no-repeat scroll 0 center transparent;
      background-size: 15px; }
    .block_catalog_company .block_catalog .menu_wrap .item_10 a:hover {
      background: url("images/company_icon_11.svg") no-repeat scroll 0 center transparent;
      background-size: 19px; }
    .block_catalog_company .block_catalog .menu_wrap .item_11 a:hover {
      background: url("images/company_icon_12.svg") no-repeat scroll 0 center transparent;
      background-size: 16px; }
    .block_catalog_company .block_catalog .menu_wrap .item_12 a:hover {
      background: url("images/company_icon_13.svg") no-repeat scroll 0 center transparent;
      background-size: 15px; }
    .block_catalog_company .block_catalog .menu_wrap .item_13 a:hover {
      background: url("images/company_icon_14.svg") no-repeat scroll 0 center transparent;
      background-size: 18px; }
    .block_catalog_company .block_catalog .menu_wrap .item_14 a:hover {
      background: url("images/company_icon_15.svg") no-repeat scroll 0 center transparent;
      background-size: 20px; }
    .block_catalog_company .block_catalog .menu_wrap .item_15 a:hover {
      background: url("images/company_icon_16.svg") no-repeat scroll 0 center transparent;
      background-size: 19px; }
    .block_catalog_company .block_catalog .menu_wrap .item_16 a:hover {
      background: url("images/company_icon_10.svg") no-repeat scroll 0 center transparent;
      background-size: 18px; }
    .block_catalog_company .block_catalog .menu_wrap .item_17 a:hover {
      background: url("images/company_icon_17.svg") no-repeat scroll 0 center transparent;
      background-size: 20px; }
  .block_catalog_company .block_company {
    padding-top: 12px;
    margin-bottom: 35px; }
    .block_catalog_company .block_company .block_title {
      font-size: 24px;
      margin-bottom: 24px; }
    .block_catalog_company .block_company .view_content {
      margin-right: -23px;
      font-size: 0; }
    .block_catalog_company .block_company .col {
      width: 16.6%;
      padding-right: 20px;
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      font-weight: 300; }
    .block_catalog_company .block_company .image {
      width: 100%;
      height: 83px;
      background: #fff;
      display: table;
      border: 1px solid #e6e8e9;
      border-radius: 2px;
      margin-bottom: 10px; }
      .block_catalog_company .block_company .image a {
        display: table-cell;
        vertical-align: middle; }
        .block_catalog_company .block_company .image a img {
          display: block;
          margin: 0 auto; }
    .block_catalog_company .block_company .title {
      text-align: center;
      line-height: 1.25; }
      .block_catalog_company .block_company .title a {
        text-decoration: none;
        transition: .3s; }
        .block_catalog_company .block_company .title a:hover {
          text-decoration: underline;
          color: #16a914; }

.block_catalog_company .block_catalog .menu_wrap .item_15 a {
  background-position: 3px center; }

/*----------------------------------------------------------------------------
									POSTER
----------------------------------------------------------------------------*/
.view_poster.similar_poster {
  padding-top: 20px;
  border-top: 1px solid #eaeaea; }
  .view_poster.similar_poster .block_title {
    font-size: 24px;
    margin-bottom: 22px; }
  .view_poster.similar_poster .view_content {
    margin-right: -26px; }
    .view_poster.similar_poster .view_content .col {
      width: 25%;
      padding-right: 26px; }
    .view_poster.similar_poster .view_content .date {
      border: none;
      font-size: 11px;
      color: #89919e;
      text-transform: uppercase;
      padding: 0;
      margin-bottom: 8px; }

.view_poster .view_content {
  margin-right: -22px;
  font-size: 0; }

.view_poster .col {
  width: 20%;
  display: inline-block;
  font-size: 14px;
  vertical-align: top;
  padding-right: 22px;
  margin-bottom: 38px; }

.view_poster .image {
  margin-bottom: 8px;
  height: 218px;
  overflow: hidden; }

.view_poster .date {
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px solid #bac5d6;
  padding: 0 9px;
  display: table;
  margin: 0 auto;
  padding-top: 3px;
  padding-bottom: 1px;
  margin-bottom: 5px; }

.view_poster .place {
  color: #89919e;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: center; }

.view_poster .title {
  font-size: 18px;
  text-align: center; }
  .view_poster .title a {
    text-decoration: none;
    transition: .3s; }
    .view_poster .title a:hover {
      color: #16a914;
      text-decoration: underline; }

.view_poster .link_image {
  margin-top: -17px; }
  .view_poster .link_image img {
    display: block;
    margin: 0 auto;
    margin-bottom: 27px; }

.view_poster .view_title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  margin-right: 22px; }
  .view_poster .view_title.line {
    position: relative; }
    .view_poster .view_title.line::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      background: #dfdfdf; }
    .view_poster .view_title.line span {
      display: inline-block;
      padding: 0 12px;
      background: #fff;
      position: relative;
      z-index: 2; }

/*----------------------------------------------------------------------------
									NODE POSTER
----------------------------------------------------------------------------*/
.node_poster .node_title {
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 20px;
  line-height: 1;
  padding-top: 2px; }

.node_poster .node_image {
  float: left;
  margin-right: 25px; }
  .node_poster .node_image img {
    display: block;
    margin-bottom: 20px; }

.node_poster .image_wrapper {
  margin-bottom: 5px; }

.node_poster .info_wrapper {
  overflow: hidden; }
  .node_poster .info_wrapper .poster_item {
    margin-bottom: 22px; }
    .node_poster .info_wrapper .poster_item.date {
      margin-bottom: 28px; }
    .node_poster .info_wrapper .poster_item .label {
      margin-bottom: 6px;
      color: #89919e; }
    .node_poster .info_wrapper .poster_item .value {
      font-size: 16px; }
      .node_poster .info_wrapper .poster_item .value a {
        color: #16a914; }

.node_poster .description {
  text-align: justify;
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 10px;
  margin-bottom: 50px; }

.node_poster .link_image {
  padding-top: 27px;
  border-top: 1px solid #eaeaea;
  margin-bottom: 27px; }
  .node_poster .link_image img {
    display: block;
    margin: 0 auto; }

.page_node_poster .vip_notice {
  padding-top: 25px;
  border-top: 1px solid #eaeaea; }
  .page_node_poster .vip_notice .block_content {
    font-size: 0;
    margin: 0 -24px 0 0 !important; }
    .page_node_poster .vip_notice .block_content .col {
      margin: 0;
      padding-right: 24px;
      width: 33.3%; }

.page_poster .top_inner .block_content {
  margin-bottom: 24px; }

/*----------------------------------------------------------------------------
									POSTER
----------------------------------------------------------------------------*/
.page_add_notice .top_inner .block_content {
  margin-bottom: 22px; }

.add_notice_wrapper .description {
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 26px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea; }
  .add_notice_wrapper .description span {
    color: #c30016; }

.add_notice_wrapper .group_item {
  font-size: 0;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea; }
  .add_notice_wrapper .group_item + .group_item {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0; }
  .add_notice_wrapper .group_item .group_label {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    font-weight: 700;
    width: 22.5%;
    padding-right: 1%; }
  .add_notice_wrapper .group_item .group_content {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    padding-top: 5px;
    font-weight: 300; }
  .add_notice_wrapper .group_item .form_item {
    font-size: 0;
    margin-bottom: 10px; }
    .add_notice_wrapper .group_item .form_item.title_item input {
      width: 497px; }
    .add_notice_wrapper .group_item .form_item label {
      width: 145px;
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      text-align: right;
      font-size: 16px;
      font-weight: 300; }
    .add_notice_wrapper .group_item .form_item input {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      height: 32px;
      width: 210px;
      font-weight: 300; }
    .add_notice_wrapper .group_item .form_item select {
      font-size: 16px;
      appearance: none;
      height: 32px;
      background: url(images/select.png) no-repeat scroll right 10px center, linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.06) 100%);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), inset 0 2px 1px rgba(255, 255, 255, 0.3);
      border: 1px solid #bec7d1;
      width: 210px;
      padding-left: 11px;
      padding-right: 15px;
      cursor: pointer; }
    .add_notice_wrapper .group_item .form_item textarea {
      width: 495px;
      height: 100px;
      resize: none;
      border: 1px solid #bec7d1;
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      padding: 10px;
      font-weight: 300; }

.add_notice_wrapper .price_item input {
  width: 86px !important;
  border-right: none; }

.add_notice_wrapper .price_item select {
  width: 62px !important;
  vertical-align: middle;
  display: inline-block; }

.add_notice_wrapper .jInputFile-fakeButton {
  position: relative;
  width: 302px;
  height: 145px;
  background: url(images/upload_photo.png) no-repeat scroll center 42px, url(images/upload_bg.png) repeat scroll 0 0;
  cursor: pointer;
  padding-top: 90px !important; }

.add_notice_wrapper .jInputFile-activeBrowseButton {
  display: none !important; }

.add_notice_wrapper .file_load {
  margin-left: 145px; }
  .add_notice_wrapper .file_load > div {
    height: 145px !important;
    position: relative;
    font-size: 16px;
    text-align: center; }
    .add_notice_wrapper .file_load > div:first-child {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px; }
    .add_notice_wrapper .file_load > div input {
      width: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      margin-left: 0 !important;
      cursor: pointer; }

.add_notice_wrapper .g-recaptcha {
  margin-left: 145px;
  margin-bottom: 15px; }

.add_notice_wrapper .form_actions {
  padding-left: 145px;
  margin-bottom: 28px; }
  .add_notice_wrapper .form_actions .form_submit {
    border: none;
    background: #32ab2b;
    height: 48px;
    width: 302px;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    font-size: 24px; }

.view_notice .link_image img {
  margin: 25px auto 20px;
  display: block; }

.view_notice .view_header::after {
  content: '';
  display: table;
  clear: both; }

.view_notice .view_header .block_search {
  margin-bottom: 30px; }
  .view_notice .view_header .block_search form {
    font-size: 0; }
    .view_notice .view_header .block_search form * {
      display: inline-block;
      font-size: 16px;
      font-weight: 300;
      vertical-align: top;
      height: 48px; }
    .view_notice .view_header .block_search form .form_text {
      width: 68.5%;
      border-radius: 2px 0 0 2px;
      border-right: none;
      box-shadow: none !important; }
    .view_notice .view_header .block_search form select {
      font-size: 16px;
      appearance: none;
      background: url(images/select.png) no-repeat scroll right 10px center #fff;
      border: 1px solid #bec7d1;
      width: 210px;
      padding-left: 11px;
      padding-right: 15px;
      cursor: pointer;
      border-right: none; }
    .view_notice .view_header .block_search form .form_submit {
      width: 50px;
      background: url(images/search_icon2.png) no-repeat scroll center transparent;
      border: 1px solid #bec7d1;
      text-indent: -9999px;
      transition: .3s;
      border-radius: 0 2px 2px 0; }
      .view_notice .view_header .block_search form .form_submit:hover {
        opacity: 0.6; }

.view_notice .view_header .category_wrapper {
  font-size: 0;
  margin-right: -90px;
  position: relative; }
  .view_notice .view_header .category_wrapper .item {
    display: inline-block;
    vertical-align: top;
    width: 33.3%;
    padding-right: 90px;
    font-weight: 300;
    font-size: 0;
    margin-bottom: 25px; }
    .view_notice .view_header .category_wrapper .item .image {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      width: 20px; }
      .view_notice .view_header .category_wrapper .item .image img {
        display: block;
        margin: 0 auto; }
    .view_notice .view_header .category_wrapper .item a {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      text-decoration: none;
      transition: .3s; }
      .view_notice .view_header .category_wrapper .item a:hover {
        color: #16a914;
        text-decoration: underline; }
    .view_notice .view_header .category_wrapper .item span {
      font-size: 12px;
      font-weight: 400;
      color: #89919e;
      padding-left: 11px;
      position: relative;
      top: 5px; }

.view_notice .view_header .add_notice {
  line-height: 32px;
  border-radius: 2px;
  padding: 0 15px 0 35px;
  background: url(images/plus.png) no-repeat scroll 13px center #09a927;
  color: #fff;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 14px;
  display: inline-block;
  font-size: 14px;
  float: right;
  margin-top: -50px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: .3s; }
  .view_notice .view_header .add_notice:hover {
    opacity: .8; }

.view_notice .view_content .view_title {
  font-size: 24px;
  padding: 23px 0 11px; }

.view_notice .view_content .views_row {
  padding: 20px;
  overflow: hidden;
  background: url(images/base_bg.png) repeat scroll 0 0 transparent;
  margin-bottom: 1px; }
  .view_notice .view_content .views_row.vip {
    background: #f6e8dd; }
  .view_notice .view_content .views_row.selected {
    background: #f3f2dc; }
  .view_notice .view_content .views_row .image_wrap {
    float: left;
    width: 126px;
    position: relative;
    margin-right: 15px; }
    .view_notice .view_content .views_row .image_wrap img {
      display: block;
      margin: auto; }
    .view_notice .view_content .views_row .image_wrap .vip {
      position: absolute;
      top: 0;
      font-size: 12px;
      line-height: 19px;
      height: 19px;
      padding: 0 7px;
      z-index: 1;
      left: -5px;
      text-transform: uppercase;
      color: #fff;
      background: #d42e30; }
      .view_notice .view_content .views_row .image_wrap .vip::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        border: 2.5px solid transparent;
        border-top: 2.5px solid #911b1d;
        border-right: 2.5px solid #911b1d; }
  .view_notice .view_content .views_row .center_wrap {
    overflow: hidden;
    float: left;
    width: 212px;
    margin-right: 29px; }
    .view_notice .view_content .views_row .center_wrap .date {
      font-size: 12px;
      line-height: 16px;
      color: #89919e;
      margin-bottom: 8px; }
    .view_notice .view_content .views_row .center_wrap .title a {
      display: block;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      transition: .3s; }
      .view_notice .view_content .views_row .center_wrap .title a:hover {
        text-decoration: underline; }
  .view_notice .view_content .views_row .right_wrap {
    float: right;
    margin-left: 30px;
    white-space: nowrap; }
    .view_notice .view_content .views_row .right_wrap .price {
      color: #16a914;
      font-size: 24px;
      margin-bottom: 10px; }
    .view_notice .view_content .views_row .right_wrap .stat {
      font-size: 12px;
      line-height: 16px;
      color: #89919e;
      padding-left: 20px;
      margin-left: 2px;
      background: url(images/stat.png) no-repeat scroll center left 0 transparent;
      margin-bottom: 11px; }
    .view_notice .view_content .views_row .right_wrap .favorites_btn a {
      background: url(images/star.png) no-repeat scroll center transparent;
      -webkit-background-size: contain;
      background-size: contain;
      width: 16px;
      display: block;
      height: 16px;
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer; }
    .view_notice .view_content .views_row .right_wrap .favorites_btn.active a {
      background: url(images/star_active.png) no-repeat scroll 0 0 transparent;
      -webkit-background-size: contain;
      background-size: contain; }
  .view_notice .view_content .views_row .body_wrap {
    overflow: hidden;
    font-weight: 300;
    padding-top: 5px; }

.view_notice .page_navigation {
  border-top: none;
  padding-bottom: 10px; }

.view_notice_category .view_header .category_wrapper .item {
  margin-bottom: 13px; }

.view_notice_category .view_top {
  margin-bottom: 10px; }
  .view_notice_category .view_top::after {
    content: '';
    display: table;
    clear: both; }

.view_notice_category .view_title {
  float: left;
  max-width: 70%; }

.view_notice_category .add_notice {
  line-height: 32px;
  border-radius: 2px;
  padding: 0 15px 0 35px;
  background: url(images/plus.png) no-repeat scroll 13px center #09a927;
  color: #fff;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 14px;
  display: inline-block;
  font-size: 14px;
  float: right;
  position: relative;
  float: right;
  z-index: 2;
  cursor: pointer;
  transition: .3s;
  margin-top: 10px; }
  .view_notice_category .add_notice:hover {
    opacity: .8; }

.node_notice .node_title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 20px; }

.node_notice .image_wrapper {
  margin-bottom: 20px; }
  .node_notice .image_wrapper::after {
    content: '';
    display: table;
    clear: both; }
  .node_notice .image_wrapper .image {
    float: left;
    margin-right: 25px; }
    .node_notice .image_wrapper .image img {
      display: block; }
  .node_notice .image_wrapper .info_wrapper {
    overflow: hidden; }
  .node_notice .image_wrapper .date {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px; }
  .node_notice .image_wrapper .count_view {
    padding-left: 20px;
    background: url(images/eye_icon.svg) no-repeat scroll 0 3px transparent;
    margin-bottom: 18px; }
  .node_notice .image_wrapper .add_favorites {
    margin-bottom: 20px; }
    .node_notice .image_wrapper .add_favorites a {
      display: block;
      padding-left: 35px;
      background: url(images/fav_icon.png) no-repeat scroll 0 center transparent;
      line-height: 24px;
      color: #16a914; }
    .node_notice .image_wrapper .add_favorites.active a {
      background: url(images/fav_icon2.png) no-repeat scroll 0 center transparent; }
  .node_notice .image_wrapper .price {
    color: #00b319;
    font-size: 36px;
    font-weight: 900;
    padding-bottom: 19px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eaeaea; }

.node_notice .author_wrapper::after {
  content: '';
  display: table;
  clear: both; }

.node_notice .author_wrapper .label {
  font-size: 12px;
  margin-bottom: 10px;
  color: #89919e; }

.node_notice .author_wrapper .photo {
  float: left;
  margin-right: 12px; }
  .node_notice .author_wrapper .photo img {
    border-radius: 50%; }

.node_notice .author_wrapper .user_content {
  overflow: hidden;
  padding-top: 4px; }
  .node_notice .author_wrapper .user_content .name {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 4px; }

.node_notice .author_wrapper .write_btn {
  margin-bottom: 11px; }
  .node_notice .author_wrapper .write_btn a {
    text-decoration: none;
    color: #16a914;
    border: 1px solid #00b131;
    border-radius: 2px;
    line-height: 32px;
    display: inline-block;
    width: 100%;
    text-align: center;
    transition: .3s; }
    .node_notice .author_wrapper .write_btn a:hover {
      background: #16a914;
      color: #fff; }

.node_notice .author_wrapper .all_notice {
  text-align: right; }
  .node_notice .author_wrapper .all_notice a {
    color: #16a914; }

.node_notice .description {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: justify; }

.node_notice .social_links::after {
  content: '';
  display: table;
  clear: both; }

.node_notice .social_links .at-resp-share-element .at-share-btn {
  float: left;
  margin-right: 20px;
  clear: none;
  margin-bottom: 20px !important;
  background-color: transparent !important; }
  .node_notice .social_links .at-resp-share-element .at-share-btn span {
    color: #3b4047 !important;
    font: 300 16px/2.3 'museosanscyr' !important; }
  .node_notice .social_links .at-resp-share-element .at-share-btn .at-icon-wrapper {
    width: 22px !important;
    background: url(images/social_1.svg) no-repeat scroll 0 center transparent !important; }
    .node_notice .social_links .at-resp-share-element .at-share-btn .at-icon-wrapper svg {
      display: none !important; }
  .node_notice .social_links .at-resp-share-element .at-share-btn:nth-child(2) .at-icon-wrapper {
    background: url(images/social_5.svg) no-repeat scroll 0 center transparent !important; }
  .node_notice .social_links .at-resp-share-element .at-share-btn:nth-child(3) .at-icon-wrapper {
    background: url(images/social_6.svg) no-repeat scroll 0 center transparent !important; }
  .node_notice .social_links .at-resp-share-element .at-share-btn:nth-child(4) .at-icon-wrapper {
    background: url(images/social_2.svg) no-repeat scroll 0 center transparent !important; }
  .node_notice .social_links .at-resp-share-element .at-share-btn:nth-child(5) .at-icon-wrapper {
    background: url(images/social_3.svg) no-repeat scroll 0 center transparent !important; }
  .node_notice .social_links .at-resp-share-element .at-share-btn:nth-child(6) .at-icon-wrapper {
    background: url(images/social_4.svg) no-repeat scroll 0 center transparent !important; }

.node_notice .view_notice {
  padding-top: 10px;
  border-top: 1px solid #eaeaea; }

.node_notice .link_image {
  margin: 30px 0; }
  .node_notice .link_image img {
    display: block;
    margin: 0 auto; }

/*----------------------------------------------------------------------------
									OTHER PAGES
----------------------------------------------------------------------------*/
.register_form .form_item:not(.rules) {
  font-size: 0;
  margin-bottom: 9px; }
  .register_form .form_item:not(.rules) label {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    width: 38.8%;
    margin-right: 0.8%;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px; }
  .register_form .form_item:not(.rules) input {
    font-size: 16px;
    outline: none;
    background: none;
    width: 210px;
    font-weight: 300;
    height: 32px;
    border-radius: 2px;
    padding: 0 10px;
    border: 1px solid #bec7d1; }
  .register_form .form_item:not(.rules) .description {
    display: inline-block;
    font-size: 12px;
    line-height: 32px;
    padding-left: 10px;
    color: #89919e;
    vertical-align: middle; }
  .register_form .form_item:not(.rules) .user_photo_load {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 17px; }
    .register_form .form_item:not(.rules) .user_photo_load + div {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      height: 32px !important; }
      .register_form .form_item:not(.rules) .user_photo_load + div input {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0; }
  .register_form .form_item:not(.rules) .jInputFile-fakeButton {
    font-size: 16px;
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    margin: 0 0 10px;
    outline: none;
    background: none;
    border: 1px solid #00b335;
    border-radius: 2px;
    height: 32px;
    line-height: 32px;
    padding: 0 25px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    color: #09a927;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s; }
  .register_form .form_item:not(.rules) .jInputFile-activeBrowseButton {
    display: none !important; }
  .register_form .form_item:not(.rules).phone::after {
    content: '';
    display: table;
    clear: both; }
  .register_form .form_item:not(.rules).phone label {
    float: left; }
  .register_form .form_item:not(.rules).phone .input_wrapper {
    overflow: hidden; }
  .register_form .form_item:not(.rules).phone .description {
    line-height: 16px;
    padding-left: 0;
    padding-top: 8px;
    margin-bottom: 15px; }

.register_form .rules {
  padding-top: 15px;
  padding-left: 39.6%;
  margin-bottom: 20px; }
  .register_form .rules input {
    display: none; }
    .register_form .rules input:checked + label {
      background: url(images/check2.png) no-repeat scroll 0 0 transparent; }
  .register_form .rules label {
    padding-left: 25px;
    background: url(images/check.png) no-repeat scroll 0 0px transparent;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer; }

.register_form .g-recaptcha {
  padding-left: 39.6%;
  margin-bottom: 18px; }

.register_form .form_actions {
  padding-left: 39.6%;
  margin-bottom: 40px; }
  .register_form .form_actions .form_submit {
    border: none;
    background: #32ab2b;
    height: 48px;
    width: 300px;
    color: #fff;
    border-radius: 2px;
    font-size: 24px; }

.form_description {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px; }

.commercial_wrapper .main_title {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 31px; }

.commercial_wrapper .commercial_block {
  font-size: 0;
  margin-bottom: 10px; }
  .commercial_wrapper .commercial_block .col {
    font-size: 14px;
    padding-right: 115px;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-left: 32px; }
    .commercial_wrapper .commercial_block .col_1 {
      background: url(images/commerce_icon_1.svg) no-repeat scroll 0 0 transparent; }
    .commercial_wrapper .commercial_block .col_2 {
      background: url(images/commerce_icon_2.svg) no-repeat scroll 0 0 transparent; }
  .commercial_wrapper .commercial_block .title {
    font-size: 18px;
    margin-bottom: 12px; }
  .commercial_wrapper .commercial_block a {
    font-size: 16px;
    font-weight: 300;
    color: #16a914;
    display: block;
    margin-bottom: 10px; }

.commercial_wrapper .commerce_description {
  border: 1px solid #e6e7e9;
  padding: 23px 40px 3px 75px;
  background: url(images/info_icon.svg) no-repeat scroll 20px 22px #fbfcfe;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 25px; }

.commercial_wrapper .form_title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 25px; }

.commercial_wrapper .form_item {
  font-size: 0;
  margin-bottom: 10px; }
  .commercial_wrapper .form_item label {
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    vertical-align: middle;
    width: 32.3%;
    text-align: right;
    padding-right: 1%; }
  .commercial_wrapper .form_item input {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    height: 32px;
    width: 210px;
    font-weight: 300; }
  .commercial_wrapper .form_item textarea {
    width: 500px;
    height: 100px;
    resize: none;
    border: 1px solid #bec7d1;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    padding: 10px;
    font-weight: 300; }

.commercial_wrapper .form_textarea {
  margin-bottom: 20px; }
  .commercial_wrapper .form_textarea label {
    vertical-align: top; }

.commercial_wrapper form .description {
  font-size: 12px;
  color: #89919e;
  display: inline-block;
  vertical-align: middle; }

.commercial_wrapper .jInputFile-fakeButton {
  font-size: 16px;
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  margin: 0 0 10px;
  outline: none;
  background: none;
  border: 1px solid #00b335;
  border-radius: 2px;
  height: 32px;
  line-height: 32px;
  padding: 0 25px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  color: #09a927;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s; }

.commercial_wrapper .jInputFile-activeBrowseButton {
  display: none !important; }

.commercial_wrapper .file_load {
  margin-bottom: 20px;
  margin-left: 32.3%; }
  .commercial_wrapper .file_load > div {
    position: relative;
    height: 32px !important; }
    .commercial_wrapper .file_load > div input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: 80; }
    .commercial_wrapper .file_load > div:first-child {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px; }

.commercial_wrapper .g-recaptcha {
  margin-left: 32.3%;
  margin-bottom: 18px; }

.commercial_wrapper .form_actions {
  padding-left: 32.3%;
  margin-bottom: 28px; }
  .commercial_wrapper .form_actions .form_submit {
    border: none;
    background: #32ab2b;
    height: 48px;
    padding: 0 29px;
    color: #fff;
    border-radius: 2px;
    font-size: 24px; }

.commercial_wrapper + .vip_notice {
  padding-top: 25px;
  border-top: 1px solid #eaeaea; }

.page_node_notice .counter_wrap,
.page_notice .counter_wrap,
.page_add_notice .counter_wrap,
.page_weather .counter_wrap {
  float: right;
  font-size: 0;
  padding-top: 16px; }
  .page_node_notice .counter_wrap .item,
  .page_notice .counter_wrap .item,
  .page_add_notice .counter_wrap .item,
  .page_weather .counter_wrap .item {
    font-size: 24px;
    font-weight: 900;
    display: inline-block;
    vertical-align: top;
    margin-left: 41px; }
  .page_node_notice .counter_wrap .label,
  .page_notice .counter_wrap .label,
  .page_add_notice .counter_wrap .label,
  .page_weather .counter_wrap .label {
    font-size: 11px;
    color: #89919e;
    text-transform: uppercase;
    font-weight: 400; }

.page_node_notice .right_sidebar .link_img,
.page_notice .right_sidebar .link_img,
.page_add_notice .right_sidebar .link_img,
.page_weather .right_sidebar .link_img {
  padding-top: 0; }

.page_node_notice .vip_notice,
.page_notice .vip_notice,
.page_add_notice .vip_notice,
.page_weather .vip_notice {
  padding-top: 25px;
  border-top: 1px solid #eaeaea; }

.weather_wrapper .menu {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea; }
  .weather_wrapper .menu ul {
    display: flex;
    justify-content: space-between; }
    .weather_wrapper .menu ul a {
      font-size: 16px;
      font-weight: 300;
      transition: .3s;
      text-decoration: none; }
      .weather_wrapper .menu ul a:hover {
        text-decoration: underline;
        color: #16a914; }

.weather_wrapper .day_wrapper {
  font-size: 0;
  padding-bottom: 9px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea; }
  .weather_wrapper .day_wrapper .title {
    font-size: 24px;
    margin-bottom: 20px; }
  .weather_wrapper .day_wrapper .weather_icon {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top; }
  .weather_wrapper .day_wrapper .day_content {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    font-weight: 300; }
  .weather_wrapper .day_wrapper .temperature {
    font-size: 24px;
    color: #32ab00;
    font-weight: 900;
    margin-bottom: 10px;
    padding-top: 11px; }
  .weather_wrapper .day_wrapper .description p {
    margin-bottom: 15px; }

.weather_wrapper .week_wrapper {
  font-size: 0;
  margin-right: -60px;
  margin-bottom: 20px; }
  .weather_wrapper .week_wrapper .col {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    padding-right: 85px;
    width: 16.6%; }
  .weather_wrapper .week_wrapper .day {
    font-weight: 700;
    margin-bottom: 15px; }
  .weather_wrapper .week_wrapper .date {
    font-weight: 300;
    margin-bottom: 20px; }
  .weather_wrapper .week_wrapper .weather_icon {
    margin-bottom: 10px; }
  .weather_wrapper .week_wrapper .temperature {
    font-size: 18px;
    font-weight: 700; }

.user_photo_load + div *,
.user_photo_load + div,
input[type="file"] {
  cursor: pointer; }

.page_weather .top_inner .block_content {
  margin-bottom: 22px; }

.page_commercial .top_inner .block_content {
  margin-bottom: 23px; }

.page_user .top_inner .block_content {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 14px; }
  .page_user .top_inner .block_content .inner_wrapper .page_title {
    display: inline-block;
    vertical-align: middle; }
  .page_user .top_inner .block_content .inner_wrapper .user_logout {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 14px; }
    .page_user .top_inner .block_content .inner_wrapper .user_logout a {
      font-size: 12px;
      line-height: 16px;
      color: #89919e; }

.page_user .block_user_menu {
  clear: both;
  width: 100%;
  border-bottom: 1px solid #d0d6df;
  margin: 0 0 30px; }
  .page_user .block_user_menu .block_content {
    max-width: 1170px;
    margin: auto; }
    .page_user .block_user_menu .block_content ul {
      width: 100%;
      list-style: none;
      background: white;
      background: -moz-linear-gradient(top, white 0%, #f3f3f3 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(100%, #f3f3f3));
      background: -webkit-linear-gradient(top, white 0%, #f3f3f3 100%);
      background: -o-linear-gradient(top, white 0%, #f3f3f3 100%);
      background: -ms-linear-gradient(top, white 0%, #f3f3f3 100%);
      background: linear-gradient(to bottom, white 0%, #f3f3f3 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3', GradientType=0 ); }
      .page_user .block_user_menu .block_content ul li {
        display: inline-block;
        vertical-align: bottom;
        height: 100%;
        width: 14.32%;
        font-size: 0px;
        margin-left: -4px; }
        .page_user .block_user_menu .block_content ul li:first-child {
          margin-left: 0; }
        .page_user .block_user_menu .block_content ul li a {
          display: block;
          height: 48px;
          cursor: pointer;
          line-height: 48px;
          font-size: 16px;
          text-align: center;
          text-decoration: none;
          padding: 0 17px;
          font-weight: 700;
          color: #4a515b;
          border: 1px solid #ccd2da;
          border-bottom: 0;
          transition: background .3s; }
          .page_user .block_user_menu .block_content ul li a:hover {
            background: #fff; }
          .page_user .block_user_menu .block_content ul li a.active {
            background: #fff;
            position: relative;
            height: 54px;
            line-height: 54px;
            border: 1px solid #ccd2da;
            border-bottom: 0; }
            .page_user .block_user_menu .block_content ul li a.active::after {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              height: 4px;
              background: #fff;
              width: 100%; }

.page_user .view_user .title_block {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 23px; }

.page_user .view_user_personal .top_wrap,
.page_user .view_user_personal .bottom_wrap {
  padding: 0 0 31px;
  margin: 0 0 30px;
  border-bottom: 1px solid #eaeaea;
  overflow: hidden; }

.page_user .view_user_personal .user_photo {
  float: left;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin: 0px 33px 0 0; }
  .page_user .view_user_personal .user_photo img {
    display: block;
    margin: auto; }

.page_user .view_user_personal .user_info_wrap {
  overflow: hidden;
  color: #3b4047; }
  .page_user .view_user_personal .user_info_wrap .group_wrap {
    width: 48%;
    padding: 0 30px 0 0;
    display: inline-block;
    vertical-align: top; }
    .page_user .view_user_personal .user_info_wrap .group_wrap .item {
      margin: 0px 0 23px;
      font-size: 16px;
      line-height: 22px; }
      .page_user .view_user_personal .user_info_wrap .group_wrap .item.field_phone_number {
        margin-top: 40px !important; }
      .page_user .view_user_personal .user_info_wrap .group_wrap .item:last-child {
        margin: 0 auto; }
      .page_user .view_user_personal .user_info_wrap .group_wrap .item .label {
        font-weight: 300; }
      .page_user .view_user_personal .user_info_wrap .group_wrap .item .field_item {
        font-weight: 700; }
        .page_user .view_user_personal .user_info_wrap .group_wrap .item .field_item a {
          text-decoration: none; }

.page_user .view_user_personal .bottom_wrap .group_wrap {
  display: inline-block;
  vertical-align: top;
  width: 36%;
  padding-right: 30px; }
  .page_user .view_user_personal .bottom_wrap .group_wrap:nth-child(3n+3) {
    width: 27%; }
  .page_user .view_user_personal .bottom_wrap .group_wrap .item {
    margin: 0 0 44px; }
    .page_user .view_user_personal .bottom_wrap .group_wrap .item:last-child {
      margin-bottom: 0; }
    .page_user .view_user_personal .bottom_wrap .group_wrap .item .label {
      font-size: 18px;
      line-height: 24px;
      position: relative;
      padding: 0 0 5px;
      margin: 0 0 11px; }
      .page_user .view_user_personal .bottom_wrap .group_wrap .item .label::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        width: 74px;
        background: #eaeaea; }
    .page_user .view_user_personal .bottom_wrap .group_wrap .item .field_item .count {
      font-size: 24px;
      line-height: 30px;
      font-weight: 900;
      margin: 0 0 3px; }
    .page_user .view_user_personal .bottom_wrap .group_wrap .item .field_item .link a {
      line-height: 20px;
      cursor: pointer;
      color: #00b319; }

.page_user .user_edit_webform {
  overflow: hidden;
  padding-bottom: 33px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eaeaea; }
  .page_user .user_edit_webform .form_item {
    margin-bottom: 9px; }
    .page_user .user_edit_webform .form_item.title_form input {
      width: 497px; }
    .page_user .user_edit_webform .form_item label {
      display: inline-block;
      vertical-align: middle;
      text-align: right;
      width: 44%;
      margin-right: 9px;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px; }
    .page_user .user_edit_webform .form_item input {
      outline: none;
      background: none;
      width: 210px;
      font-weight: 300;
      height: 32px;
      border-radius: 2px;
      padding: 0 10px;
      border: 1px solid #bec7d1; }
    .page_user .user_edit_webform .form_item.file_load {
      margin: 20px 0;
      position: relative; }
    .page_user .user_edit_webform .form_item .user_photo_load {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      margin-right: 17px;
      position: relative; }
      .page_user .user_edit_webform .form_item .user_photo_load + div {
        display: inline-block;
        vertical-align: middle;
        position: relative; }
      .page_user .user_edit_webform .form_item .user_photo_load .delete {
        position: absolute;
        top: 15px;
        left: 121px;
        z-index: 10;
        font-size: 12px;
        color: #89919e; }
    .page_user .user_edit_webform .form_item .jInputFile-customFile {
      margin-left: 0; }
    .page_user .user_edit_webform .form_item .jInputFile-activeBrowseButton {
      display: none !important; }
    .page_user .user_edit_webform .form_item .jInputFile-fakeButton {
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
      margin: 0 0 10px;
      outline: none;
      background: none;
      border: 1px solid #00b335;
      border-radius: 2px;
      height: 32px;
      line-height: 32px;
      padding: 0 25px;
      width: auto;
      display: inline-block;
      vertical-align: middle;
      color: #09a927;
      cursor: pointer;
      transition: .3s; }
      .page_user .user_edit_webform .form_item .jInputFile-fakeButton:hover {
        opacity: .7; }
    .page_user .user_edit_webform .form_item .jInputFile-fileName {
      position: static;
      margin: 0 auto;
      height: auto;
      font-size: 12px;
      line-height: 16px;
      text-decoration: underline;
      color: #89919e;
      margin-bottom: 10px; }
  .page_user .user_edit_webform .form_actions {
    margin-left: 9px;
    margin-top: 19px; }
    .page_user .user_edit_webform .form_actions input {
      outline: none;
      margin-left: 45%;
      border: none;
      background: #32ab2b;
      height: 48px;
      width: 210px;
      color: #fff;
      border-radius: 2px;
      font-size: 24px; }

.page_user .view_company_category {
  margin-bottom: 30px; }
  .page_user .view_company_category .views_title {
    display: inline-block;
    vertical-align: middle;
    width: 60%; }
  .page_user .view_company_category .category_menu {
    border-bottom: 0;
    padding-bottom: 0; }
    .page_user .view_company_category .category_menu .add_company {
      margin-top: 0; }
  .page_user .view_company_category .col .title {
    margin-bottom: 11px; }
  .page_user .view_company_category .col .phone {
    margin-bottom: 6px; }
  .page_user .view_company_category .col .address {
    background-position: 2px 5px; }
  .page_user .view_company_category .col.col_image .btns_wrapper {
    min-height: 135px; }
  .page_user .view_company_category .col.vip .btns_wrapper .btn {
    bottom: 14px; }
  .page_user .view_company_category .col.vip .btns_wrapper .edit_btn {
    top: auto;
    margin-top: 0;
    bottom: -9px; }
  .page_user .view_company_category .edit_btn {
    float: right;
    width: 130px;
    text-align: left;
    clear: both;
    position: absolute;
    top: 50%;
    margin-top: 20px; }
    .page_user .view_company_category .edit_btn a {
      padding-left: 37px;
      background: url(images/edit_icon.png) no-repeat scroll center left 18px transparent;
      font-weight: 300;
      display: block;
      text-decoration: none; }
      .page_user .view_company_category .edit_btn a:hover {
        text-decoration: underline; }

.page_user .view_user_score .bal_title {
  font-size: 24px;
  margin-bottom: 6px; }

.page_user .view_user_score .top_wrap {
  padding-bottom: 29px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea; }
  .page_user .view_user_score .top_wrap::after {
    content: '';
    clear: both;
    display: table; }
  .page_user .view_user_score .top_wrap .balance {
    width: 185px;
    float: left;
    padding-right: 30px; }
  .page_user .view_user_score .top_wrap .count {
    color: #32ab00;
    font-size: 24px;
    line-height: 30px;
    font-weight: 900; }
  .page_user .view_user_score .top_wrap .rigth_wrap {
    overflow: hidden;
    float: right; }
    .page_user .view_user_score .top_wrap .rigth_wrap .form_item {
      display: inline-block;
      vertical-align: top;
      margin-right: 21px;
      font-size: 16px; }
      .page_user .view_user_score .top_wrap .rigth_wrap .form_item label {
        font-weight: 300;
        display: block;
        margin-bottom: 6px; }
      .page_user .view_user_score .top_wrap .rigth_wrap .form_item input {
        font-weight: 300;
        border: none;
        outline: none;
        height: 32px;
        font-size: 14px;
        background: none;
        border: 1px solid #bdc6cf;
        border-radius: 2px;
        width: 118px;
        padding: 0 10px; }
      .page_user .view_user_score .top_wrap .rigth_wrap .form_item select {
        width: 240px;
        height: 32px;
        outline: none;
        border: none;
        font-weight: 300;
        border-radius: 0px;
        padding: 0 30px 0 10px;
        border: 1px solid #bdc6cf;
        -webkit-appearance: none;
        background: url(images/select.png) no-repeat center right 9px, url(images/select_bg.png) repeat-x center;
        line-height: 1em;
        /* for FF */
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
        /* for IE */
        -ms-appearance: none;
        appearance: none !important; }
        .page_user .view_user_score .top_wrap .rigth_wrap .form_item select::-ms-expand {
          display: none; }
    .page_user .view_user_score .top_wrap .rigth_wrap .form_actions {
      display: inline-block;
      vertical-align: bottom; }
      .page_user .view_user_score .top_wrap .rigth_wrap .form_actions input {
        border: none;
        outline: none;
        background: none;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #00b335;
        padding: 0 27px;
        font-size: 15px;
        color: #00b335;
        line-height: 30px;
        transition: .3s; }
        .page_user .view_user_score .top_wrap .rigth_wrap .form_actions input:hover {
          opacity: .7; }

.page_user .view_user_score .bottom_wrap .bal_title {
  margin-bottom: 10px; }

.page_user .view_user_score table {
  width: 100%;
  border: none;
  text-align: left;
  font-weight: 300;
  margin-bottom: 25px; }
  .page_user .view_user_score table th {
    color: #89919e;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 300;
    height: 38px; }
  .page_user .view_user_score table tbody tr {
    height: 48px;
    border-bottom: 1px solid #e5e6e8;
    transition: .15s; }
    .page_user .view_user_score table tbody tr:nth-child(odd) {
      background: #fbfcfe; }
    .page_user .view_user_score table tbody tr:first-child {
      border-top: 1px solid #e5e6e8; }
    .page_user .view_user_score table tbody tr:not(:first-child):hover {
      background: #f1f4f9; }
    .page_user .view_user_score table tbody tr td {
      font-size: 16px;
      line-height: 20px;
      padding-right: 15px; }
      .page_user .view_user_score table tbody tr td:first-child {
        padding-left: 20px; }

.page_user .view_user_messages .top_wrap {
  overflow: hidden;
  margin: 0 0 25px; }

.page_user .view_user_messages .bal_title {
  font-size: 24px;
  margin: 0 20px 0px 0;
  float: left; }

.page_user .view_user_messages .view_header_btn {
  float: right;
  height: 32px;
  line-height: 30px;
  border-radius: 2px;
  padding: 0 29px;
  cursor: pointer;
  color: #00b335;
  border: 1px solid #00b335;
  transition: .3s; }
  .page_user .view_user_messages .view_header_btn:hover {
    opacity: .7; }

.page_user .view_user_messages .messages_wrap {
  position: relative;
  border-bottom: 1px solid #e6e7e9;
  padding-bottom: 30px;
  margin-bottom: 30px; }
  .page_user .view_user_messages .messages_wrap::after {
    content: '';
    clear: both;
    display: table; }
  .page_user .view_user_messages .messages_wrap .left_wrap {
    float: left;
    width: 270px;
    margin-right: -1px; }
    .page_user .view_user_messages .messages_wrap .left_wrap .item_row {
      border: 1px solid #e6e7e9;
      border-bottom: 0;
      padding: 12px 14px 14px 14px;
      background: #fbfcfe;
      cursor: pointer;
      transition: .3s; }
      .page_user .view_user_messages .messages_wrap .left_wrap .item_row:hover {
        background: #fff; }
      .page_user .view_user_messages .messages_wrap .left_wrap .item_row:last-child {
        border-bottom: 1px solid #e6e7e9; }
      .page_user .view_user_messages .messages_wrap .left_wrap .item_row.new:not(.active) {
        position: relative; }
        .page_user .view_user_messages .messages_wrap .left_wrap .item_row.new:not(.active)::after {
          content: '';
          position: absolute;
          top: 50%;
          left: -5.5px;
          width: 11px;
          height: 11px;
          background: #00b319;
          border-radius: 100%;
          margin-top: -5.5px; }
      .page_user .view_user_messages .messages_wrap .left_wrap .item_row.active {
        background: #fff;
        border: none;
        border-top: 1px solid #e6e7e9; }
      .page_user .view_user_messages .messages_wrap .left_wrap .item_row .user_photo {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        overflow: hidden;
        float: left;
        margin-right: 13px; }
      .page_user .view_user_messages .messages_wrap .left_wrap .item_row .user_info_wrap {
        overflow: hidden;
        padding: 4px 0 0 0; }
        .page_user .view_user_messages .messages_wrap .left_wrap .item_row .user_info_wrap .user_name {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 3px; }
        .page_user .view_user_messages .messages_wrap .left_wrap .item_row .user_info_wrap .date {
          color: #89919e;
          font-size: 12px;
          line-height: 16px; }
  .page_user .view_user_messages .messages_wrap .messages_block {
    overflow: hidden; }
    .page_user .view_user_messages .messages_wrap .messages_block .messages_block_inner {
      padding: 0 36px 0 19px;
      margin-bottom: 19px;
      height: 365px; }
      .page_user .view_user_messages .messages_wrap .messages_block .messages_block_inner .messages_row {
        max-width: 90.15%;
        float: left;
        font-weight: 300;
        border: 1px solid #e6e7e9;
        padding: 14px 17px 21px 19px;
        margin-bottom: 11px; }
        .page_user .view_user_messages .messages_wrap .messages_block .messages_block_inner .messages_row:nth-child(even) {
          float: right; }
        .page_user .view_user_messages .messages_wrap .messages_block .messages_block_inner .messages_row .name {
          font-size: 16px;
          line-height: 20px;
          float: left;
          margin-right: 20px;
          margin-bottom: 15px; }
        .page_user .view_user_messages .messages_wrap .messages_block .messages_block_inner .messages_row .date {
          float: right;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 15px;
          font-weight: 400;
          padding-right: 8px;
          color: #89919e; }
        .page_user .view_user_messages .messages_wrap .messages_block .messages_block_inner .messages_row .messages_text {
          clear: both;
          line-height: 20px; }
    .page_user .view_user_messages .messages_wrap .messages_block .messages_block_bottom {
      border: 1px solid #e6e7e9;
      background: #fbfcfe;
      padding: 15px; }
      .page_user .view_user_messages .messages_wrap .messages_block .messages_block_bottom form {
        position: relative; }
        .page_user .view_user_messages .messages_wrap .messages_block .messages_block_bottom form textarea {
          width: 100%;
          height: 83px;
          resize: none;
          font-size: 16px;
          font-weight: 300;
          padding: 7px 45px 7px 10px;
          border: 1px solid #e3e8ed; }
        .page_user .view_user_messages .messages_wrap .messages_block .messages_block_bottom form .form_actions input {
          border: none;
          outline: none;
          background: url(images/send.png) no-repeat scroll 0 0 transparent;
          width: 20px;
          height: 18px;
          text-indent: -9999px;
          overflow: hidden;
          position: absolute;
          bottom: 20px;
          right: 11px;
          transition: .3s; }

.page_user .view_user_announcements .title_block,
.page_user .view_user_favorites .title_block {
  margin-bottom: 5px; }

.page_user .view_user_announcements .add_announcements,
.page_user .view_user_favorites .add_announcements {
  float: right; }
  .page_user .view_user_announcements .add_announcements a,
  .page_user .view_user_favorites .add_announcements a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    background: #32ab2b;
    color: #fff;
    padding: 0 19px;
    border-radius: 2px;
    height: 32px;
    line-height: 32px;
    transition: .3s; }
    .page_user .view_user_announcements .add_announcements a:hover,
    .page_user .view_user_favorites .add_announcements a:hover {
      opacity: .7; }

.page_user .view_user_announcements .top_wrap {
  overflow: hidden;
  margin-bottom: 8px; }
  .page_user .view_user_announcements .top_wrap .title_block {
    float: left;
    width: 70%; }

.page_user .views_favorites,
.page_user .views_announcements {
  margin-bottom: 20px;
  clear: both; }
  .page_user .views_favorites .top_wrap,
  .page_user .views_announcements .top_wrap {
    position: relative;
    overflow: visible;
    margin-bottom: 23px; }
    .page_user .views_favorites .top_wrap::after,
    .page_user .views_announcements .top_wrap::after {
      content: '';
      clear: both;
      display: table; }
  .page_user .views_favorites .views_row,
  .page_user .views_announcements .views_row {
    padding: 20px;
    overflow: hidden;
    margin-bottom: 3px;
    background: url(images/base_bg.png) repeat scroll 0 0 transparent; }
    .page_user .views_favorites .views_row .image_wrap,
    .page_user .views_announcements .views_row .image_wrap {
      float: left;
      width: 126px;
      position: relative;
      margin-right: 15px; }
      .page_user .views_favorites .views_row .image_wrap img,
      .page_user .views_announcements .views_row .image_wrap img {
        display: block;
        margin: auto; }
      .page_user .views_favorites .views_row .image_wrap .vip,
      .page_user .views_announcements .views_row .image_wrap .vip {
        position: absolute;
        top: 0;
        font-size: 12px;
        line-height: 19px;
        height: 19px;
        padding: 0 7px;
        z-index: 1;
        left: -5px;
        text-transform: uppercase;
        color: #fff;
        background: #d42e30; }
        .page_user .views_favorites .views_row .image_wrap .vip::after,
        .page_user .views_announcements .views_row .image_wrap .vip::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          border: 2.5px solid transparent;
          border-top: 2.5px solid #911b1d;
          border-right: 2.5px solid #911b1d; }
    .page_user .views_favorites .views_row .center_wrap,
    .page_user .views_announcements .views_row .center_wrap {
      overflow: hidden;
      float: left;
      width: 212px;
      margin-right: 29px; }
      .page_user .views_favorites .views_row .center_wrap .date,
      .page_user .views_announcements .views_row .center_wrap .date {
        font-size: 12px;
        line-height: 16px;
        color: #89919e;
        margin-bottom: 8px; }
      .page_user .views_favorites .views_row .center_wrap .title a,
      .page_user .views_announcements .views_row .center_wrap .title a {
        display: block;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        transition: .3s; }
        .page_user .views_favorites .views_row .center_wrap .title a:hover,
        .page_user .views_announcements .views_row .center_wrap .title a:hover {
          text-decoration: underline; }
    .page_user .views_favorites .views_row .right_wrap,
    .page_user .views_announcements .views_row .right_wrap {
      float: right;
      margin-left: 30px;
      white-space: nowrap; }
      .page_user .views_favorites .views_row .right_wrap .price,
      .page_user .views_announcements .views_row .right_wrap .price {
        color: #00b319;
        font-size: 24px;
        margin-bottom: 10px; }
      .page_user .views_favorites .views_row .right_wrap .stat,
      .page_user .views_announcements .views_row .right_wrap .stat {
        font-size: 12px;
        line-height: 16px;
        color: #89919e;
        padding-left: 20px;
        margin-left: 2px;
        background: url(images/stat.png) no-repeat scroll center left 0 transparent;
        margin-bottom: 11px; }
      .page_user .views_favorites .views_row .right_wrap .favorites_btn a,
      .page_user .views_announcements .views_row .right_wrap .favorites_btn a {
        background: url(images/star.png) no-repeat scroll center transparent;
        -webkit-background-size: contain;
        background-size: contain;
        width: 16px;
        display: block;
        height: 16px;
        text-indent: -9999px;
        overflow: hidden;
        cursor: pointer; }
      .page_user .views_favorites .views_row .right_wrap .favorites_btn.active a,
      .page_user .views_announcements .views_row .right_wrap .favorites_btn.active a {
        background: url(images/star_active.png) no-repeat scroll 0 0 transparent;
        -webkit-background-size: contain;
        background-size: contain; }
    .page_user .views_favorites .views_row .body_wrap,
    .page_user .views_announcements .views_row .body_wrap {
      overflow: hidden;
      font-weight: 300;
      padding-top: 5px; }
  .page_user .views_favorites .bottom_wrap,
  .page_user .views_announcements .bottom_wrap {
    clear: both;
    margin: 0 -20px;
    overflow: hidden;
    border-top: 1px solid #e5e6e8;
    border-left: 1px solid #e5e6e8;
    border-right: 1px solid #e5e6e8;
    background: url(images/bg_bottom.png) repeat-x scroll 0 0 transparent;
    background-size: contain; }
    .page_user .views_favorites .bottom_wrap .field_item,
    .page_user .views_announcements .bottom_wrap .field_item {
      float: left; }
      .page_user .views_favorites .bottom_wrap .field_item.edit,
      .page_user .views_announcements .bottom_wrap .field_item.edit {
        float: right; }
        .page_user .views_favorites .bottom_wrap .field_item.edit a,
        .page_user .views_announcements .bottom_wrap .field_item.edit a {
          color: #00b319;
          border-left: 1px solid #e5e6e8;
          border-right: 0; }
      .page_user .views_favorites .bottom_wrap .field_item a,
      .page_user .views_announcements .bottom_wrap .field_item a {
        font-size: 12px;
        height: 35px;
        line-height: 35px;
        padding: 0 14px;
        text-decoration: none;
        display: block;
        border-right: 1px solid #e5e6e8;
        transition: .3s; }
        .page_user .views_favorites .bottom_wrap .field_item a:hover,
        .page_user .views_announcements .bottom_wrap .field_item a:hover {
          color: #fff;
          border-color: #32ab2b;
          background: #32ab2b; }

.page_user .views_announcements .views_row {
  padding-bottom: 0;
  border-bottom: 1px solid #e6e7e9; }

.page_user.page_user_announcements .block_user_menu .block_content ul li:first-child a {
  border-right: 0; }

.page_user_messages .container {
  overflow: visible; }

.block_support {
  border-radius: 2px;
  border: 1px solid #e6e7e9;
  background: #fbfcfe;
  padding: 12px 20px 19px;
  margin: 0 0 28px; }
  .block_support .block_title {
    font-size: 24px;
    margin-bottom: 15px; }
  .block_support .view_content {
    padding: 0 0 0 80px;
    background: url(images/support.png) no-repeat scroll 0 0 transparent; }
    .block_support .view_content .user_name {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 300;
      line-height: 1; }
    .block_support .view_content a {
      color: #89919e;
      text-decoration: none;
      font-size: 12px;
      line-height: 16px; }

.block_weather {
  margin-bottom: 20px;
  overflow: hidden; }
  .block_weather .block_title {
    font-size: 24px;
    margin: 0 0 20px; }
  .block_weather .view_content .field_item {
    width: 25%;
    float: left;
    text-align: center;
    padding: 0 15px 13px 0; }
    .block_weather .view_content .field_item:last-child {
      float: right;
      padding-right: 0; }
    .block_weather .view_content .field_item .label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 5px; }
    .block_weather .view_content .field_item .icon {
      height: 32px;
      line-height: 32px;
      text-align: center;
      margin: 0 0 5px; }
      .block_weather .view_content .field_item .icon img {
        display: inline;
        vertical-align: middle; }
    .block_weather .view_content .field_item .temperature {
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 13px;
      text-align: center; }
    .block_weather .view_content .field_item .wind {
      font-size: 12px;
      color: #89919e;
      text-align: center; }

.middle_content .vip_notice .block_content {
  margin: 0 -12px; }

.middle_content .vip_notice .col {
  display: inline-block;
  vertical-align: top;
  width: 260px;
  margin: 0 11px; }
  .middle_content .vip_notice .col .image {
    height: 145px;
    overflow: hidden;
    border-radius: 2px;
    margin-bottom: 5px; }
    .middle_content .vip_notice .col .image img {
      max-width: none;
      height: 100%;
      display: block;
      margin: auto; }

.page_user_score .block_user_menu {
  margin-bottom: 20px; }

/*----------------------------------------------------------------------------
									LEFT SIDEBAR
----------------------------------------------------------------------------*/
.left_sidebar {
  float: left;
  width: 320px;
  margin-left: -100%;
  position: relative;
  padding-right: 25px;
  border-right: 1px solid #eaeaea; }
  .left_sidebar .vip_notice .col {
    margin-bottom: 22px; }

.block_news_city {
  margin-bottom: 30px; }
  .block_news_city .block_title {
    font-size: 24px;
    margin-bottom: 18px; }
  .block_news_city .col {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eaeaea; }
    .block_news_city .col.hot .title a {
      font-weight: 700; }
    .block_news_city .col.last {
      margin-bottom: 0;
      border-bottom: none; }
    .block_news_city .col::after {
      content: '';
      display: table;
      clear: both; }
  .block_news_city .date {
    width: 12.3%;
    padding-right: 1%;
    float: left;
    font-size: 12px;
    color: #89919e;
    padding-top: 2px; }
    .page_journal .block_news_city .date {
      width: 100%;
      padding-top: 5px; }
  .block_news_city .title {
    overflow: hidden;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25; }
    .block_news_city .title a {
      text-decoration: none;
      transition: 0.3s; }
      .block_news_city .title a:hover {
        text-decoration: underline;
        color: #16a914; }
    .block_news_city .title span {
      font-size: 12px;
      font-weight: 400;
      color: #89929e;
      display: inline-block;
      margin-left: 8px;
      padding: 1px 4px 0 4px;
      border-radius: 2px;
      border: 1px solid #89929e; }
      .block_news_city .title span.selected {
        color: #16a914;
        border-color: #16a914;
        padding: 1px 2px 0 1px;
        line-height: 0.9; }
      .block_news_city .title span.important {
        color: #fa8a80;
        border-color: #fa8a80; }
  .block_news_city .old_date {
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
    padding-top: 7px; }
  .block_news_city .view_more {
    text-align: right;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px; }
    .block_news_city .view_more a {
      display: inline-block;
      padding-right: 17px;
      color: #16a914;
      padding-right: 17px;
      background: url(images/all_news.png) no-repeat scroll right center transparent;
      font-size: 16px;
      font-weight: 300; }

.block_horoscope {
  margin-bottom: 30px;
  border-bottom: 1px solid #eaeaea; }
  .block_horoscope .block_title {
    font-size: 24px;
    margin: 0 0 20px; }
  .block_horoscope .block_content {
    font-size: 0; }
    .block_horoscope .block_content .col {
      display: inline-block;
      vertical-align: top;
      width: 25%;
      margin-bottom: 20px; }
      .block_horoscope .block_content .col a {
        display: block;
        text-indent: -9999px;
        height: 36px;
        transition: .3s; }
    .block_horoscope .block_content .col_1 a {
      background: url("images/horoscope_1.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_1 a:hover {
        background: url("images/horoscope_1_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_2 a {
      background: url("images/horoscope_2.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_2 a:hover {
        background: url("images/horoscope_2_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_3 a {
      background: url("images/horoscope_3.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_3 a:hover {
        background: url("images/horoscope_3_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_4 a {
      background: url("images/horoscope_4.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_4 a:hover {
        background: url("images/horoscope_4_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_5 a {
      background: url("images/horoscope_5.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_5 a:hover {
        background: url("images/horoscope_5_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_6 a {
      background: url("images/horoscope_6.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_6 a:hover {
        background: url("images/horoscope_6_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_7 a {
      background: url("images/horoscope_7.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_7 a:hover {
        background: url("images/horoscope_7_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_8 a {
      background: url("images/horoscope_8.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_8 a:hover {
        background: url("images/horoscope_8_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_9 a {
      background: url("images/horoscope_9.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_9 a:hover {
        background: url("images/horoscope_9_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_10 a {
      background: url("images/horoscope_10.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_10 a:hover {
        background: url("images/horoscope_10_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_11 a {
      background: url("images/horoscope_11.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_11 a:hover {
        background: url("images/horoscope_11_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_12 a {
      background: url("images/horoscope_12.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_12 a:hover {
        background: url("images/horoscope_12_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_13 a {
      background: url("images/horoscope_13.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_13 a:hover {
        background: url("images/horoscope_13_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_14 a {
      background: url("images/horoscope_14.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_14 a:hover {
        background: url("images/horoscope_14_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_15 a {
      background: url("images/horoscope_15.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_15 a:hover {
        background: url("images/horoscope_15_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_16 a {
      background: url("images/horoscope_16.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_16 a:hover {
        background: url("images/horoscope_16_2.svg") no-repeat scroll 0 center transparent; }
    .block_horoscope .block_content .col_17 a {
      background: url("images/horoscope_17.svg") no-repeat scroll 0 center transparent; }
      .block_horoscope .block_content .col_17 a:hover {
        background: url("images/horoscope_17_2.svg") no-repeat scroll 0 center transparent; }

.block_horoscope .block_content .col_8 a:hover {
  background: url(images/horoscope_8_2.png) no-repeat scroll 0 center transparent !important; }

/*----------------------------------------------------------------------------
									RIGHT SIDEBAR
----------------------------------------------------------------------------*/
.right_sidebar {
  float: left;
  width: 315px;
  margin-left: -315px;
  position: relative;
  padding-left: 25px;
  border-left: 1px solid #eaeaea; }

.block_news_top .block_title {
  font-size: 24px;
  margin-bottom: 19px; }

.block_news_top .col {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea; }
  .block_news_top .col:last-child {
    padding-bottom: 0;
    border-bottom: none; }

.block_news_top .image {
  float: left;
  padding-top: 15px; }
  .block_news_top .image img {
    border-radius: 50%;
    margin-right: 10px; }

.block_news_top .title_wrapper {
  overflow: hidden; }

.block_news_top .date {
  font-size: 12px;
  margin-bottom: 10px;
  color: #89919e; }

.block_news_top .title a {
  text-decoration: none;
  line-height: 1.45;
  display: block;
  font-size: 16px;
  transition: .3s;
  font-weight: 300; }
  .block_news_top .title a:hover {
    text-decoration: underline;
    color: #16a914; }

.block_news_top .count_view {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  color: #89919e;
  padding-left: 20px;
  background: url(images/eye_icon.svg) no-repeat scroll 0 3px transparent;
  float: right; }

.right_sidebar .link_img {
  padding-top: 15px;
  margin-bottom: 30px; }

.link_img img {
  display: block; }

.fb-page {
  margin-bottom: 30px; }

.vip_notice {
  margin-bottom: 30px; }
  .vip_notice .block_title {
    font-size: 24px;
    margin-bottom: 20px; }
  .page_company .vip_notice .col {
    margin-bottom: 25px; }
  .vip_notice .image img {
    border-radius: 2px;
    display: block;
    margin-bottom: 8px; }
  .vip_notice .title a {
    text-decoration: none;
    transition: .3s;
    font-size: 16px;
    font-weight: 300;
    display: block;
    margin-bottom: 3px; }
    .vip_notice .title a:hover {
      color: #16a914;
      text-decoration: underline; }
  .vip_notice .price {
    font-size: 16px;
    font-weight: 300;
    color: #16a914; }

.page_company .block_weather {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaeaea; }

/*----------------------------------------------------------------------------
									FOOTER
----------------------------------------------------------------------------*/
.footer {
  width: 100%;
  margin: -308px auto 0;
  height: 308px;
  position: relative;
  clear: both;
  border-top: 1px solid #eaeaea; }
  .footer > .footer_content:first-child {
    padding-top: 13px; }
  .footer .footer_content {
    max-width: 1170px;
    margin: 0 auto; }
  .footer .footer_menu {
    margin-bottom: 13px; }
    .footer .footer_menu ul {
      font-size: 0; }
      .footer .footer_menu ul li {
        display: inline-block;
        width: 10.6%;
        vertical-align: bottom;
        text-align: center; }
        .footer .footer_menu ul li.logo {
          width: 15%; }
    .footer .footer_menu a {
      text-decoration: none;
      font-size: 18px;
      transition: .3s; }
      .footer .footer_menu a.active, .footer .footer_menu a:hover {
        color: #09a927;
        text-decoration: underline; }
      .footer .footer_menu a.footer_logo {
        width: 120px;
        height: 42px;
        text-indent: -9999px;
        display: block;
        background: url(images/footer_logo.svg) no-repeat scroll 0 0 transparent;
        margin: 0 auto; }
  .footer .social_container {
    position: relative;
    margin-bottom: 16px; }
    .footer .social_container::after {
      content: '';
      display: block;
      width: 100%;
      background: #eaeaea;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0; }
    .footer .social_container .social_links {
      font-size: 0;
      display: table;
      margin: 0 auto;
      position: relative;
      z-index: 1; }
      .footer .social_container .social_links .social {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px; }
        .footer .social_container .social_links .social:last-child {
          margin-right: 0; }
        .footer .social_container .social_links .social a {
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 50%; }
          .footer .social_container .social_links .social a.social_fb {
            background: url(images/fb_icon.svg) no-repeat scroll 0 0 transparent;
            background-size: cover !important; }
            .footer .social_container .social_links .social a.social_fb:hover {
              background: url(images/fb_icon_hover.svg) no-repeat scroll 0 0 transparent; }
          .footer .social_container .social_links .social a.social_tel {
            background: url(images/tel_icon.svg) no-repeat scroll 0 0 transparent;
            background-size: cover !important; }
            .footer .social_container .social_links .social a.social_tel:hover {
              background: url(images/tel_icon_hover.svg) no-repeat scroll 0 0 transparent; }
          .footer .social_container .social_links .social a.social_tw {
            background: url(images/tw_icon.svg) no-repeat scroll 0 0 transparent;
            background-size: cover !important; }
            .footer .social_container .social_links .social a.social_tw:hover {
              background: url(images/tw_icon_hover.svg) no-repeat scroll 0 0 transparent; }
  .footer .footer_text {
    text-align: center;
    font-weight: 300;
    line-height: 1.45; }
    .footer .footer_text p {
      margin-bottom: 19px; }
  .footer .footer_info {
    border-top: 1px solid #eaeaea;
    background: #fbfcfe;
    padding: 15px 0; }
    .footer .footer_info .copyright {
      float: left;
      padding-top: 3px;
      font-weight: 300; }
    .footer .footer_info #gl_devel {
      float: right; }
    .footer .footer_info .info_text {
      display: table;
      margin: 0 auto;
      padding-top: 3px;
      font-weight: 300; }
      .footer .footer_info .info_text a {
        color: #16a914; }
  .footer .login_form {
    position: fixed;
    width: 320px;
    margin: -222px 0 0 -160px;
    left: 50%;
    top: 50%;
    z-index: 100;
    background: #fff;
    padding: 22px 30px 25px;
    display: none; }
    .footer .login_form .block_title {
      font-size: 24px;
      margin-bottom: 18px; }
    .footer .login_form .close_icon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      background: url(images/close_icon.png) no-repeat scroll 0 0 transparent;
      text-indent: -9999px;
      cursor: pointer; }
      .footer .login_form .close_icon:hover {
        opacity: 0.7; }
    .footer .login_form .form_text {
      height: 48px;
      margin-bottom: 19px; }
    .footer .login_form .form_actions {
      margin-bottom: 20px; }
      .footer .login_form .form_actions::after {
        content: '';
        display: table;
        clear: both; }
      .footer .login_form .form_actions .forgot_pass {
        float: left;
        text-decoration: none;
        color: #89919e; }
        .footer .login_form .form_actions .forgot_pass:hover {
          text-decoration: underline; }
      .footer .login_form .form_actions .form_submit {
        float: right;
        height: 48px;
        color: #fff;
        border-radius: 2px;
        color: #fff;
        background: #09a927;
        padding: 0 25px;
        border: none;
        font-size: 24px; }
    .footer .login_form .bot_wrap {
      text-align: center;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e0e0e0; }
      .footer .login_form .bot_wrap .title {
        font-size: 18px;
        margin-bottom: 6px; }
      .footer .login_form .bot_wrap .register_link {
        color: #16a914; }
    .footer .login_form .socials_title {
      font-size: 18px;
      margin-bottom: 10px;
      text-align: center; }
    .footer .login_form .social_links {
      font-size: 0;
      display: table;
      margin: 0 auto;
      position: relative;
      z-index: 1; }
      .footer .login_form .social_links .social {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px; }
        .footer .login_form .social_links .social:last-child {
          margin-right: 0; }
        .footer .login_form .social_links .social a {
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 50%; }
          .footer .login_form .social_links .social a.social_fb {
            background: url(images/fb_icon_hover.svg) no-repeat scroll 0 0 transparent;
            background-size: cover !important; }
            .footer .login_form .social_links .social a.social_fb:hover {
              background: url(images/fb_icon.svg) no-repeat scroll 0 0 transparent; }
          .footer .login_form .social_links .social a.social_tel {
            background: url(images/tel_icon_hover.svg) no-repeat scroll 0 0 transparent;
            background-size: cover !important; }
            .footer .login_form .social_links .social a.social_tel:hover {
              background: url(images/tel_icon.svg) no-repeat scroll 0 0 transparent; }
          .footer .login_form .social_links .social a.social_tw {
            background: url(images/tw_icon_hover.svg) no-repeat scroll 0 0 transparent;
            background-size: cover !important; }
            .footer .login_form .social_links .social a.social_tw:hover {
              background: url(images/tw_icon.svg) no-repeat scroll 0 0 transparent; }
