@font-face{
    font-family: 'cuprumregular';
    src: url('fonts/cuprum-regular-webfont-webfont.eot');
    src: url('fonts/cuprum-regular-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/cuprum-regular-webfont-webfont.woff') format('woff'),
         url('fonts/cuprum-regular-webfont-webfont.ttf') format('truetype'),
         url('fonts/cuprum-regular-webfont-webfont.svg#_.regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'museosanscyr';
  src: url("fonts/MuseoSansCyrl300.eot");
  src: local("☺"), url("fonts/MuseoSansCyrl300.woff") format("woff"), url("fonts/MuseoSansCyrl300.ttf") format("truetype"), url("fonts/MuseoSansCyrl300.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
    font-family: 'museosanscyr';
  src: url("fonts/MuseoSansCyrl-500_gdi.eot");
  src: url("fonts/MuseoSansCyrl-500_gdi.eot?#iefix") format("embedded-opentype"), url("fonts/MuseoSansCyrl-500_gdi.woff") format("woff"), url("fonts/MuseoSansCyrl-500_gdi.ttf") format("truetype"), url("fonts/MuseoSansCyrl-500_gdi.svg#MuseoSansCyrl-500") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
    font-family: 'museosanscyr';
  src: url("fonts/MuseoSansCyrl700.eot");
  src: local("☺"), url("fonts/MuseoSansCyrl700.woff") format("woff"), url("fonts/MuseoSansCyrl700.ttf") format("truetype"), url("fonts/MuseoSansCyrl700.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family:'museosanscyr';
  src: url('fonts/MuseoSansCyrl-900_gdi.eot');
  src: url('fonts/MuseoSansCyrl-900_gdi.eot?#iefix') format('embedded-opentype'),
    url('fonts/MuseoSansCyrl-900_gdi.woff') format('woff'),
    url('fonts/MuseoSansCyrl-900_gdi.ttf') format('truetype'),
    url('fonts/MuseoSansCyrl-900_gdi.svg#MuseoSansCyrl-900') format('svg');
    font-weight: 900;
    font-style: normal;
}