.header{
	.link_image{
		padding-top: 12px;
		padding-bottom: 7px;
		img{
			display: block;
			margin: 0 auto;
		}
	}
	.header_content{
		max-width: 1170px;
		margin: 0 auto;
		padding-top: 22px;
		margin-bottom: 18px;
	}
	.header_heroes{
		float: left;
		width: 292px;
		height: 75px;
		background: url(images/heroes_bg.png) no-repeat  scroll 0 0 transparent;
		color: #fff;
		padding: 10px 67px 0 64px;
		position: relative;
		margin-top: 6px;
		&::before{
			content: '';
			display: block;
			position: absolute;
			top: -27px;
			left: -59px;
			background: url(images/ukr_flag.png) no-repeat  scroll 0 0 transparent;
			width: 110px;
			height: 111px;
		}
		&:hover{
			.block_text{
				text-decoration: underline;
			}
		}
		.block_title{
			font-size: 18px;
			line-height: 1;
			margin-bottom: 5px;
		}
		.block_text{
			font-size: 13px;
			text-align: center;
		}
		.main_link{
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			text-indent: -9999px;
			width: 100%;
			height: 100%;
		}
	}
	.header_user{
		float: right;
		padding: 6px 0px 0 0;
	}
	.header_logo{
		display: table;
		margin: 0 auto;
		position: relative;
		left: 6px;
		img{
			display: block;
		}
	}
	.social_wrapper{
		float: left;
		margin-top: -2px;
	}
	.notice_wrapper{
		float: right;
		margin-left: 13px;
	}
	.social_links{
		font-size: 0;
		margin-bottom: 22px;
		padding-top: 4px;	
		.social{
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
			&:last-child{
				margin-right: 0;
			}
			a{
				display: block;
				width: 26px;
				height: 26px;
				border-radius: 50%;
				&.social_fb{
					background: url(images/fb_icon.svg) no-repeat  scroll 0 0 transparent;
					background-size: cover !important;
					&:hover{
						background: url(images/fb_icon_hover.svg) no-repeat  scroll 0 0 transparent;
					}
				}
				&.social_tel{
					background: url(images/tel_icon.svg) no-repeat  scroll 0 0 transparent;
					background-size: cover !important;
					&:hover{
						background: url(images/tel_icon_hover.svg) no-repeat  scroll 0 0 transparent;
					}
				}
				&.social_tw{
					background: url(images/tw_icon.svg) no-repeat  scroll 0 0 transparent;
					background-size: cover !important;
					&:hover{
						background: url(images/tw_icon_hover.svg) no-repeat  scroll 0 0 transparent;
					}
				}
			}
		}
	}
	.user_login{
		text-align: right;
		.login_link{
			padding-left: 27px;
			background: url(images/login_icon.png) no-repeat  scroll 0 0 transparent;
			line-height: 22px;
			display: block;
			text-decoration: none;
			display: inline-block;
			&.cabinet_login{
				background: url(images/cabinet_icon.png) no-repeat  scroll 0 0 transparent;
				display: none;
			}
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.header_notice{
		line-height: 32px;
		border-radius: 2px;
		padding: 0 15px 0 35px;
		background: url(images/plus.png) no-repeat  scroll 13px center $color_green;
		color: #fff;
		cursor: pointer;
		transition: .3s;
		margin-bottom: 14px;
		&:hover{
			opacity: 0.8;
		}
	}
	.search_block{
		position: relative;
		.form_submit{
			position: absolute;
			top: 50%;
			width: 18px;
			height: 18px;
			display: block;
			text-indent: -9999px;
			margin-top: -9px;
			background: url(images/search_icon.svg) no-repeat  scroll 0 0 transparent;
			border: none;
			right: 7px;
		}
	}
	.menu_wrapper{
		background:#fbfcfe;
	}
	.main_menu{
		background: #4b525b;
		.block_content{
			max-width: 830px;
			margin: 0 auto;
		}
		ul{
			display: table;
			width: 100%;
			li{
				display: table-cell;
				text-align: center;
				a{
					color: #fff;
					text-decoration: none;
					font-weight: 700;
					font-size: 18px;
					line-height: 48px;
					display: block;
					padding: 0 24px;
					&:hover,
					&.active{
						background: url(images/menu_bg.png) repeat-x  scroll 0 0 transparent;
					}
				}
			}
		}
	}
	.notice_menu{
		border-bottom: 1px solid #e1e1e1;
		.page_news_node &{
			margin-bottom: 30px;
		}
		.block_content{
			max-width: 1170px;
			margin: 0 auto;
			position: relative;
			&::before{
				content: '';
				display: block;
				border: 4px solid transparent;	
				border-bottom: 5px solid #fff;
				position: absolute;
				top: -8px;
				left: 49.7%;
				z-index: 10;
			}
		}
		ul{
			display: flex;
		  	justify-content: space-between;
			a{
				font-size: 13px;
				text-decoration: none;
				transition: .3s;
				display: block;
				line-height: 30px;
				font-weight: 300;
				&.active,
				&:hover{
					color: $color_green;
					text-decoration: underline;
				}
			}
		}
	}
}
@media (min-width: 1200px) and (max-width: 1400px){
	.front .block_poster button.slick-prev{
		left: -35px;
	}
	.front .block_poster button.slick-next{
		right: -35px;
	}
}